import {ApplicationRef, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {filter, Observable, Subject, takeUntil, tap} from 'rxjs';
import {Datacenter} from "../../core/model/datacenter";
import {Nestable} from "../../core/model/summary";
import {MapConfigSelector} from "../../core/ngrx/selectors/map-config.selector";
import {Store} from "@ngrx/store";
import {mapConfigActions} from "../../core/ngrx/actions";
import * as _ from "lodash";
import {Actions, ofType} from "@ngrx/effects";

@Component({
    selector: 'app-providers-table',
    templateUrl: './providers-table.component.html',
    styleUrls: ['./providers-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ProvidersTableComponent implements OnInit, OnDestroy {
  @Input()
  onlyVisible: boolean = true;
  public providers: Nestable[] = [];
  public providersOrig: Nestable[] = [];
  public providersInView: Nestable[] = [];
  public currentFilter: string = "";
  public changingColorProvider: string = null;
  public changingColor: string = null;

  public selectedProviders: Nestable[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  public isSearching: boolean = false;

  constructor(private mapConfigSelector: MapConfigSelector, private readonly store: Store, private actions$: Actions,
              private appRef: ApplicationRef, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.mapConfigSelector.getProviders().pipe(
      tap(providers => this.handleProvidersChange(providers))
    ).subscribe();
    this.actions$.pipe(
      ofType(mapConfigActions.ZOOM_END),
      takeUntil(this.destroy$),
      tap(event => this.filterProvidersOnZoomEnd(event))
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  filterProvidersOnZoomEnd(event) {
      const datacentersInView: Datacenter[] = (event as any).datacenters;
      this.providersInView = [];
      if (datacentersInView.length) {
        for (const dc of datacentersInView) {
          if (this.providersInView.filter(p => p.label == dc.provider).length == 0) {
            this.providersInView.push(...this.providersOrig.filter(p => p.label.toUpperCase() == dc.provider.toUpperCase()).map(p => {
              p.isProviderHidden = dc.isProviderHidden;
              return p;
            }));
          }
        }
      }
      this.providersInView = this.providersInView.sort((a, b) => a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1);
      if (this.currentFilter) {
        this.providers = this.providersInView.filter(p => p.label.toUpperCase().indexOf(this.currentFilter) != -1);
      } else {
        this.providers = this.providersInView;
      }
      this.handleSelectedAfterFilter();
      this.appRef.tick();
  }

  filter(dt, event) {
    this.currentFilter = event.target.value.toUpperCase();
    this.isSearching = this.currentFilter.length > 0;
    if (this.currentFilter) {
      this.providers = this.providersInView.filter(p => p.label.toUpperCase().indexOf(this.currentFilter) != -1);
    } else {
      this.providers = this.providersInView;
    }
    this.providers = this.providers.filter(p => p.label.toUpperCase().indexOf(event.target.value.toUpperCase()) != -1);
    this.handleSelectedAfterFilter();
  }

  handleSelectedAfterFilter() {
    const selectedAfterFilter: Nestable[] = [];
    for (const currentSelected of this.selectedProviders) {
      if (this.providers.find(p => p.label == currentSelected.label)) {
        selectedAfterFilter.push(currentSelected);
      }
    }
  }

  handleProvidersChange(providers: Nestable[]) {
    // Create deep copies to avoid unwanted mutations
    const selectedProviders = _.cloneDeep(this.selectedProviders);
    const newProviders = _.cloneDeep(providers);

    // Update existing selected providers with new information
    newProviders.forEach(newProvider => {
      const existingIndex = selectedProviders.findIndex(
        selectedProvider => selectedProvider.label === newProvider.label
      );

      if (existingIndex !== -1) {
        selectedProviders[existingIndex] = newProvider;
      }
    });

    // Filter out selected providers that no longer exist in the new list
    const updatedSelectedProviders = selectedProviders.filter(selectedProvider =>
      newProviders.some(newProvider => newProvider.label === selectedProvider.label)
    );

    // Update state
    this.selectedProviders = updatedSelectedProviders;

    // Sort providers alphabetically
    this.providers = newProviders.sort((a, b) =>
      a.label.toUpperCase().localeCompare(b.label.toUpperCase())
    );

    // Keep original copy of providers
    this.providersOrig = [...this.providers];
  }

  overProvider(provider: Nestable) {
    this.handledHighlited(provider, true);
  }

  leaveProvider(provider: Nestable) {
    this.handledHighlited(provider, false);
  }

  selectedProvidersChanged() {
    this.selectedProviders = _.cloneDeep(this.selectedProviders);
    if (this.onlyVisible) {
      this.store.dispatch(mapConfigActions.selectedProvidersChanged({
        selectedProviders: this.selectedProviders
      }));
    }
  }

  hideProvider(providers: Nestable[], visible: boolean) {
      this.store.dispatch(mapConfigActions.toggleProviderVisibility({
        provider: providers,
        visible: !visible
      }));
  }

  private handledHighlited(provider: Nestable, isHighlight: boolean) {
    this.store.dispatch(mapConfigActions.datacenterHoverChange({
      hover: isHighlight,
      value: provider.label,
      isProvider: true
    }));
  }

  colorChanged(event, provider) {
    this.changingColorProvider = provider.label;
    this.changingColor = event.value;
  }

  onShow() {
    this.appRef.tick();
  }

  onHide() {
    if (this.changingColorProvider && this.changingColor) {
      this.store.dispatch(mapConfigActions.changedDatacenterColor({
        provider: this.changingColorProvider,
        color: this.changingColor
      }));
      this.changingColorProvider = null;
      this.changingColor = null;
    }

  }

  get allProvidersHidden(): boolean {
    return this.providers.every(p => p.isProviderHidden);
  }

  toggleAll() {
    const newVisibility = this.allProvidersHidden;
    const updatedProviders = _.cloneDeep(this.providersOrig);
    this.hideProvider(updatedProviders, newVisibility);
  }

}
