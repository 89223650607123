import * as i0 from '@angular/core';
import { InjectionToken, NgZone, Injectable, Optional, Inject, Component, ChangeDetectionStrategy, Input, ViewChild, Directive, Host, HostListener, EventEmitter, Output, forwardRef, ViewEncapsulation, TemplateRef, ContentChild, NgModule } from '@angular/core';
import MapboxGl, { AttributionControl, FullscreenControl, GeolocateControl, NavigationControl, ScaleControl } from 'mapbox-gl';
import { AsyncSubject, Subscription, fromEvent, Subject, lastValueFrom, merge } from 'rxjs';
import { first, switchMap, mapTo, filter, startWith, debounceTime, tap, takeUntil, take } from 'rxjs/operators';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = ["content"];
const _c1 = ["*"];
const _c2 = ["container"];
const _c3 = () => ({
  "circle-radius": 0
});
const _c4 = a0 => ({
  $implicit: a0
});
function MarkersForClustersComponent_ng_container_1_ng_container_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function MarkersForClustersComponent_ng_container_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "mgl-marker", 3);
    i0.ɵɵtemplate(2, MarkersForClustersComponent_ng_container_1_ng_container_1_ng_container_2_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const feature_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("feature", feature_r1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.clusterPointTpl)("ngTemplateOutletContext", i0.ɵɵpureFunction1(3, _c4, feature_r1));
  }
}
function MarkersForClustersComponent_ng_container_1_ng_container_2_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function MarkersForClustersComponent_ng_container_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "mgl-marker", 3);
    i0.ɵɵtemplate(2, MarkersForClustersComponent_ng_container_1_ng_container_2_ng_container_2_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const feature_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("feature", feature_r1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.pointTpl)("ngTemplateOutletContext", i0.ɵɵpureFunction1(3, _c4, feature_r1));
  }
}
function MarkersForClustersComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, MarkersForClustersComponent_ng_container_1_ng_container_1_Template, 3, 5, "ng-container", 2)(2, MarkersForClustersComponent_ng_container_1_ng_container_2_Template, 3, 5, "ng-container", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const feature_r1 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", feature_r1.properties["cluster"]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !feature_r1.properties["cluster"]);
  }
}
const MAPBOX_API_KEY = new InjectionToken('MapboxApiKey');
class MapService {
  constructor(zone, MAPBOX_API_KEY) {
    this.zone = zone;
    this.MAPBOX_API_KEY = MAPBOX_API_KEY;
    this.mapCreated = new AsyncSubject();
    this.mapLoaded = new AsyncSubject();
    this.markersToRemove = [];
    this.popupsToRemove = [];
    this.imageIdsToRemove = [];
    this.subscription = new Subscription();
    this.mapCreated$ = this.mapCreated.asObservable();
    this.mapLoaded$ = this.mapLoaded.asObservable();
  }
  setup(options) {
    // Need onStable to wait for a potential @angular/route transition to end
    this.zone.onStable.pipe(first()).subscribe(() => {
      // Workaround rollup issue
      // this.assign(
      //   MapboxGl,
      //   'accessToken',
      //   options.accessToken || this.MAPBOX_API_KEY
      // );
      if (options.customMapboxApiUrl) {
        MapboxGl.baseApiUrl = options.customMapboxApiUrl;
      }
      this.createMap({
        ...options.mapOptions,
        accessToken: options.accessToken || this.MAPBOX_API_KEY || ''
      });
      this.hookEvents(options.mapEvents);
      this.mapEvents = options.mapEvents;
      this.mapCreated.next(undefined);
      this.mapCreated.complete();
      // Intentionnaly emit mapCreate after internal mapCreated event
      if (options.mapEvents.mapCreate.observed) {
        this.zone.run(() => {
          options.mapEvents.mapCreate.emit(this.mapInstance);
        });
      }
    });
  }
  destroyMap() {
    if (this.mapInstance) {
      this.subscription.unsubscribe();
      this.mapInstance.remove();
    }
  }
  updateProjection(projection) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.setProjection(projection);
    });
  }
  updateMinZoom(minZoom) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.setMinZoom(minZoom);
    });
  }
  updateMaxZoom(maxZoom) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.setMaxZoom(maxZoom);
    });
  }
  updateMinPitch(minPitch) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.setMinPitch(minPitch);
    });
  }
  updateMaxPitch(maxPitch) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.setMaxPitch(maxPitch);
    });
  }
  updateRenderWorldCopies(status) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.setRenderWorldCopies(status);
    });
  }
  updateScrollZoom(status) {
    return this.zone.runOutsideAngular(() => {
      status ? this.mapInstance.scrollZoom.enable() : this.mapInstance.scrollZoom.disable();
    });
  }
  updateDragRotate(status) {
    return this.zone.runOutsideAngular(() => {
      status ? this.mapInstance.dragRotate.enable() : this.mapInstance.dragRotate.disable();
    });
  }
  updateTouchPitch(status) {
    return this.zone.runOutsideAngular(() => {
      status ? this.mapInstance.touchPitch.enable() : this.mapInstance.touchPitch.disable();
    });
  }
  updateTouchZoomRotate(status) {
    return this.zone.runOutsideAngular(() => {
      status ? this.mapInstance.touchZoomRotate.enable() : this.mapInstance.touchZoomRotate.disable();
    });
  }
  updateDoubleClickZoom(status) {
    return this.zone.runOutsideAngular(() => {
      status ? this.mapInstance.doubleClickZoom.enable() : this.mapInstance.doubleClickZoom.disable();
    });
  }
  updateKeyboard(status) {
    return this.zone.runOutsideAngular(() => {
      status ? this.mapInstance.keyboard.enable() : this.mapInstance.keyboard.disable();
    });
  }
  updateDragPan(status) {
    return this.zone.runOutsideAngular(() => {
      status ? this.mapInstance.dragPan.enable() : this.mapInstance.dragPan.disable();
    });
  }
  updateBoxZoom(status) {
    return this.zone.runOutsideAngular(() => {
      status ? this.mapInstance.boxZoom.enable() : this.mapInstance.boxZoom.disable();
    });
  }
  updateStyle(style) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.setStyle(style);
    });
  }
  updateMaxBounds(maxBounds) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.setMaxBounds(maxBounds);
    });
  }
  changeCanvasCursor(cursor) {
    const canvas = this.mapInstance.getCanvasContainer();
    canvas.style.cursor = cursor;
  }
  queryRenderedFeatures(pointOrBox, parameters) {
    return this.mapInstance.queryRenderedFeatures(pointOrBox, parameters);
  }
  panTo(center, options) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.panTo(center, options);
    });
  }
  move(movingMethod, movingOptions, zoom, center, bearing, pitch) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance[movingMethod]({
        ...movingOptions,
        zoom: zoom != null ? zoom : this.mapInstance.getZoom(),
        center: center != null ? center : this.mapInstance.getCenter(),
        bearing: bearing != null ? bearing : this.mapInstance.getBearing(),
        pitch: pitch != null ? pitch : this.mapInstance.getPitch()
      });
    });
  }
  addLayer(layer, bindEvents, before) {
    this.zone.runOutsideAngular(() => {
      Object.keys(layer.layerOptions).forEach(key => {
        const tkey = key;
        if (layer.layerOptions[tkey] === undefined) {
          delete layer.layerOptions[tkey];
        }
      });
      this.mapInstance.addLayer(layer.layerOptions, before);
      if (bindEvents) {
        if (layer.layerEvents.layerClick.observed || layer.layerEvents.click.observed) {
          this.mapInstance.on('click', layer.layerOptions.id, evt => {
            this.zone.run(() => {
              layer.layerEvents.layerClick.emit(evt);
              layer.layerEvents.click.emit(evt);
            });
          });
        }
        if (layer.layerEvents.layerDblClick.observed || layer.layerEvents.dblClick.observed) {
          this.mapInstance.on('dblclick', layer.layerOptions.id, evt => {
            this.zone.run(() => {
              layer.layerEvents.layerDblClick.emit(evt);
              layer.layerEvents.dblClick.emit(evt);
            });
          });
        }
        if (layer.layerEvents.layerMouseDown.observed || layer.layerEvents.mouseDown.observed) {
          this.mapInstance.on('mousedown', layer.layerOptions.id, evt => {
            this.zone.run(() => {
              layer.layerEvents.layerMouseDown.emit(evt);
              layer.layerEvents.mouseDown.emit(evt);
            });
          });
        }
        if (layer.layerEvents.layerMouseUp.observed || layer.layerEvents.mouseUp.observed) {
          this.mapInstance.on('mouseup', layer.layerOptions.id, evt => {
            this.zone.run(() => {
              layer.layerEvents.layerMouseUp.emit(evt);
              layer.layerEvents.mouseUp.emit(evt);
            });
          });
        }
        if (layer.layerEvents.layerMouseEnter.observed || layer.layerEvents.mouseEnter.observed) {
          this.mapInstance.on('mouseenter', layer.layerOptions.id, evt => {
            this.zone.run(() => {
              layer.layerEvents.layerMouseEnter.emit(evt);
              layer.layerEvents.mouseEnter.emit(evt);
            });
          });
        }
        if (layer.layerEvents.layerMouseLeave.observed || layer.layerEvents.mouseLeave.observed) {
          this.mapInstance.on('mouseleave', layer.layerOptions.id, evt => {
            this.zone.run(() => {
              layer.layerEvents.layerMouseLeave.emit(evt);
              layer.layerEvents.mouseLeave.emit(evt);
            });
          });
        }
        if (layer.layerEvents.layerMouseMove.observed || layer.layerEvents.mouseMove.observed) {
          this.mapInstance.on('mousemove', layer.layerOptions.id, evt => {
            this.zone.run(() => {
              layer.layerEvents.layerMouseMove.emit(evt);
              layer.layerEvents.mouseMove.emit(evt);
            });
          });
        }
        if (layer.layerEvents.layerMouseOver.observed || layer.layerEvents.mouseOver.observed) {
          this.mapInstance.on('mouseover', layer.layerOptions.id, evt => {
            this.zone.run(() => {
              layer.layerEvents.layerMouseOver.emit(evt);
              layer.layerEvents.mouseOver.emit(evt);
            });
          });
        }
        if (layer.layerEvents.layerMouseOut.observed || layer.layerEvents.mouseOut.observed) {
          this.mapInstance.on('mouseout', layer.layerOptions.id, evt => {
            this.zone.run(() => {
              layer.layerEvents.layerMouseOut.emit(evt);
              layer.layerEvents.mouseOut.emit(evt);
            });
          });
        }
        if (layer.layerEvents.layerContextMenu.observed || layer.layerEvents.contextMenu.observed) {
          this.mapInstance.on('contextmenu', layer.layerOptions.id, evt => {
            this.zone.run(() => {
              layer.layerEvents.layerContextMenu.emit(evt);
              layer.layerEvents.contextMenu.emit(evt);
            });
          });
        }
        if (layer.layerEvents.layerTouchStart.observed || layer.layerEvents.touchStart.observed) {
          this.mapInstance.on('touchstart', layer.layerOptions.id, evt => {
            this.zone.run(() => {
              layer.layerEvents.layerTouchStart.emit(evt);
              layer.layerEvents.touchStart.emit(evt);
            });
          });
        }
        if (layer.layerEvents.layerTouchEnd.observed || layer.layerEvents.touchEnd.observed) {
          this.mapInstance.on('touchend', layer.layerOptions.id, evt => {
            this.zone.run(() => {
              layer.layerEvents.layerTouchEnd.emit(evt);
              layer.layerEvents.touchEnd.emit(evt);
            });
          });
        }
        if (layer.layerEvents.layerTouchCancel.observed || layer.layerEvents.touchCancel.observed) {
          this.mapInstance.on('touchcancel', layer.layerOptions.id, evt => {
            this.zone.run(() => {
              layer.layerEvents.layerTouchCancel.emit(evt);
              layer.layerEvents.touchCancel.emit(evt);
            });
          });
        }
      }
    });
  }
  removeLayer(layerId) {
    this.zone.runOutsideAngular(() => {
      if (this.mapInstance.getLayer(layerId) != null) {
        this.mapInstance.removeLayer(layerId);
      }
    });
  }
  addMarker(marker) {
    const options = {
      offset: marker.markersOptions.offset,
      anchor: marker.markersOptions.anchor,
      draggable: !!marker.markersOptions.draggable,
      rotationAlignment: marker.markersOptions.rotationAlignment,
      pitchAlignment: marker.markersOptions.pitchAlignment,
      clickTolerance: marker.markersOptions.clickTolerance
    };
    if (marker.markersOptions.element.childNodes.length > 0) {
      options.element = marker.markersOptions.element;
    }
    const markerInstance = new MapboxGl.Marker(options);
    if (marker.markersEvents.markerDragStart.observed || marker.markersEvents.dragStart.observed) {
      markerInstance.on('dragstart', event => {
        if (event) {
          const {
            target
          } = event;
          this.zone.run(() => {
            marker.markersEvents.markerDragStart.emit(target);
            marker.markersEvents.dragStart.emit(target);
          });
        }
      });
    }
    /*
          */
    if (marker.markersEvents.markerDrag.observed || marker.markersEvents.drag.observed) {
      markerInstance.on('drag', event => {
        if (event) {
          const {
            target
          } = event;
          this.zone.run(() => {
            marker.markersEvents.markerDrag.emit(target);
            marker.markersEvents.drag.emit(target);
          });
        }
      });
    }
    if (marker.markersEvents.markerDragEnd.observed || marker.markersEvents.dragEnd.observed) {
      markerInstance.on('dragend', event => {
        if (event) {
          const {
            target
          } = event;
          this.zone.run(() => {
            marker.markersEvents.markerDragEnd.emit(target);
            marker.markersEvents.dragEnd.emit(target);
          });
        }
      });
    }
    const lngLat = marker.markersOptions.feature ? marker.markersOptions.feature.geometry.coordinates : marker.markersOptions.lngLat;
    markerInstance.setLngLat(lngLat);
    return this.zone.runOutsideAngular(() => {
      markerInstance.addTo(this.mapInstance);
      return markerInstance;
    });
  }
  removeMarker(marker) {
    this.markersToRemove.push(marker);
  }
  createPopup(popup, element) {
    return this.zone.runOutsideAngular(() => {
      Object.keys(popup.popupOptions).forEach(key => popup.popupOptions[key] === undefined && delete popup.popupOptions[key]);
      const popupInstance = new MapboxGl.Popup(popup.popupOptions);
      popupInstance.setDOMContent(element);
      if (popup.popupEvents.popupClose.observed || popup.popupEvents.close.observed) {
        popupInstance.on('close', () => {
          this.zone.run(() => {
            popup.popupEvents.popupClose.emit();
            popup.popupEvents.close.emit();
          });
        });
      }
      if (popup.popupEvents.popupOpen.observed || popup.popupEvents.open.observed) {
        popupInstance.on('open', () => {
          this.zone.run(() => {
            popup.popupEvents.popupOpen.emit();
            popup.popupEvents.open.emit();
          });
        });
      }
      return popupInstance;
    });
  }
  addPopupToMap(popup, lngLat, skipOpenEvent = false) {
    return this.zone.runOutsideAngular(() => {
      if (skipOpenEvent && popup._listeners) {
        delete popup._listeners['open'];
      }
      popup.setLngLat(lngLat);
      popup.addTo(this.mapInstance);
    });
  }
  addPopupToMarker(marker, popup) {
    return this.zone.runOutsideAngular(() => {
      marker.setPopup(popup);
    });
  }
  removePopupFromMap(popup, skipCloseEvent = false) {
    if (skipCloseEvent && popup._listeners) {
      delete popup._listeners['close'];
    }
    this.popupsToRemove.push(popup);
  }
  removePopupFromMarker(marker) {
    return this.zone.runOutsideAngular(() => {
      marker.setPopup(undefined);
    });
  }
  addControl(control, position) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.addControl(control, position);
    });
  }
  removeControl(control) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.removeControl(control);
    });
  }
  async loadAndAddImage(imageId, url, options) {
    return this.zone.runOutsideAngular(() => new Promise((resolve, reject) => {
      this.mapInstance.loadImage(url, (error, image) => {
        if (error) {
          reject(error);
          return;
        }
        this.addImage(imageId, image, options);
        resolve();
      });
    }));
  }
  addImage(imageId, data, options) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.addImage(imageId, data, options);
    });
  }
  removeImage(imageId) {
    this.imageIdsToRemove.push(imageId);
  }
  addSource(sourceId, source) {
    return this.zone.runOutsideAngular(() => {
      Object.keys(source).forEach(key => source[key] === undefined && delete source[key]);
      this.mapInstance.addSource(sourceId, source);
    });
  }
  getSource(sourceId) {
    return this.mapInstance.getSource(sourceId);
  }
  removeSource(sourceId) {
    this.zone.runOutsideAngular(() => {
      this.findLayersBySourceId(sourceId).forEach(layer => this.mapInstance.removeLayer(layer.id));
      this.mapInstance.removeSource(sourceId);
    });
  }
  setAllLayerPaintProperty(layerId, paint) {
    return this.zone.runOutsideAngular(() => {
      Object.keys(paint).forEach(key => {
        // TODO Check for perf, setPaintProperty only on changed paint props maybe
        this.mapInstance.setPaintProperty(layerId, key, paint[key]);
      });
    });
  }
  setAllLayerLayoutProperty(layerId, layout) {
    return this.zone.runOutsideAngular(() => {
      Object.keys(layout).forEach(key => {
        // TODO Check for perf, setPaintProperty only on changed paint props maybe
        this.mapInstance.setLayoutProperty(layerId, key, layout[key]);
      });
    });
  }
  setLayerFilter(layerId, filter) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.setFilter(layerId, filter);
    });
  }
  setLayerBefore(layerId, beforeId) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.moveLayer(layerId, beforeId);
    });
  }
  setLayerZoomRange(layerId, minZoom, maxZoom) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.setLayerZoomRange(layerId, minZoom ? minZoom : 0, maxZoom ? maxZoom : 20);
    });
  }
  fitBounds(bounds, options) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.fitBounds(bounds, options);
    });
  }
  fitScreenCoordinates(points, bearing, options) {
    return this.zone.runOutsideAngular(() => {
      this.mapInstance.fitScreenCoordinates(points[0], points[1], bearing, options);
    });
  }
  applyChanges() {
    this.zone.runOutsideAngular(() => {
      this.removeMarkers();
      this.removePopups();
      this.removeImages();
    });
  }
  createMap(optionsWithAccessToken) {
    NgZone.assertNotInAngularZone();
    Object.keys(optionsWithAccessToken).forEach(key => {
      const tkey = key;
      if (optionsWithAccessToken[tkey] === undefined) {
        delete optionsWithAccessToken[tkey];
      }
    });
    this.mapInstance = new MapboxGl.Map(optionsWithAccessToken);
    const isIEorEdge = window && /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEorEdge) {
      this.mapInstance.setStyle(optionsWithAccessToken.style);
    }
    this.subscription.add(this.zone.onMicrotaskEmpty.subscribe(() => this.applyChanges()));
  }
  removeMarkers() {
    for (const marker of this.markersToRemove) {
      marker.remove();
    }
    this.markersToRemove = [];
  }
  removePopups() {
    for (const popup of this.popupsToRemove) {
      popup.remove();
    }
    this.popupsToRemove = [];
  }
  removeImages() {
    for (const imageId of this.imageIdsToRemove) {
      this.mapInstance.removeImage(imageId);
    }
    this.imageIdsToRemove = [];
  }
  findLayersBySourceId(sourceId) {
    const layers = this.mapInstance.getStyle().layers;
    if (layers == null) {
      return [];
    }
    return layers.filter(l => 'source' in l ? l.source === sourceId : false);
  }
  hookEvents(events) {
    this.mapInstance.on('load', evt => {
      this.mapLoaded.next(undefined);
      this.mapLoaded.complete();
      this.zone.run(() => {
        events.mapLoad.emit(evt);
        events.load.emit(evt.target);
      });
    });
    if (events.mapResize.observed || events.resize.observed) {
      this.mapInstance.on('resize', evt => this.zone.run(() => {
        events.mapResize.emit(evt);
        events.resize.emit(evt);
      }));
    }
    if (events.mapRemove.observed || events.remove.observed) {
      this.mapInstance.on('remove', evt => this.zone.run(() => {
        events.mapRemove.emit(evt);
        events.remove.emit(evt);
      }));
    }
    if (events.mapMouseDown.observed || events.mouseDown.observed) {
      this.mapInstance.on('mousedown', evt => this.zone.run(() => {
        events.mapMouseDown.emit(evt);
        events.mouseDown.emit(evt);
      }));
    }
    if (events.mapMouseUp.observed || events.mouseUp.observed) {
      this.mapInstance.on('mouseup', evt => this.zone.run(() => {
        events.mapMouseUp.emit(evt);
        events.mouseUp.emit(evt);
      }));
    }
    if (events.mapMouseMove.observed || events.mouseMove.observed) {
      this.mapInstance.on('mousemove', evt => this.zone.run(() => {
        events.mapMouseMove.emit(evt);
        events.mouseMove.emit(evt);
      }));
    }
    if (events.mapClick.observed || events.click.observed) {
      this.mapInstance.on('click', evt => this.zone.run(() => {
        events.mapClick.emit(evt);
        events.click.emit(evt);
      }));
    }
    if (events.mapDblClick.observed || events.dblClick.observed) {
      this.mapInstance.on('dblclick', evt => this.zone.run(() => {
        events.mapDblClick.emit(evt);
        events.dblClick.emit(evt);
      }));
    }
    if (events.mapMouseOver.observed || events.mouseOver.observed) {
      this.mapInstance.on('mouseover', evt => this.zone.run(() => {
        events.mapMouseOver.emit(evt);
        events.mouseOver.emit(evt);
      }));
    }
    if (events.mapMouseOut.observed || events.mouseOut.observed) {
      this.mapInstance.on('mouseout', evt => this.zone.run(() => {
        events.mapMouseOut.emit(evt);
        events.mouseOut.emit(evt);
      }));
    }
    if (events.mapContextMenu.observed || events.contextMenu.observed) {
      this.mapInstance.on('contextmenu', evt => this.zone.run(() => {
        events.mapContextMenu.emit(evt);
        events.contextMenu.emit(evt);
      }));
    }
    if (events.mapTouchStart.observed || events.touchStart.observed) {
      this.mapInstance.on('touchstart', evt => this.zone.run(() => {
        events.mapTouchStart.emit(evt);
        events.touchStart.emit(evt);
      }));
    }
    if (events.mapTouchEnd.observed || events.touchEnd.observed) {
      this.mapInstance.on('touchend', evt => this.zone.run(() => {
        events.mapTouchEnd.emit(evt);
        events.touchEnd.emit(evt);
      }));
    }
    if (events.mapTouchMove.observed || events.touchMove.observed) {
      this.mapInstance.on('touchmove', evt => this.zone.run(() => {
        events.mapTouchMove.emit(evt);
        events.touchMove.emit(evt);
      }));
    }
    if (events.mapTouchCancel.observed || events.touchCancel.observed) {
      this.mapInstance.on('touchcancel', evt => this.zone.run(() => {
        events.mapTouchCancel.emit(evt);
        events.touchCancel.emit(evt);
      }));
    }
    if (events.mapWheel.observed || events.wheel.observed) {
      this.mapInstance.on('wheel', evt => this.zone.run(() => {
        events.mapWheel.emit(evt);
        events.wheel.emit(evt);
      }));
    }
    if (events.moveStart.observed) {
      this.mapInstance.on('movestart', evt => this.zone.run(() => events.moveStart.emit(evt)));
    }
    if (events.move.observed) {
      this.mapInstance.on('move', evt => this.zone.run(() => events.move.emit(evt)));
    }
    if (events.moveEnd.observed) {
      this.mapInstance.on('moveend', evt => this.zone.run(() => events.moveEnd.emit(evt)));
    }
    if (events.mapDragStart.observed || events.dragStart.observed) {
      this.mapInstance.on('dragstart', evt => this.zone.run(() => {
        events.mapDragStart.emit(evt);
        events.dragStart.emit(evt);
      }));
    }
    if (events.mapDrag.observed || events.drag.observed) {
      this.mapInstance.on('drag', evt => this.zone.run(() => {
        events.mapDrag.emit(evt);
        events.drag.emit(evt);
      }));
    }
    if (events.mapDragEnd.observed || events.dragEnd.observed) {
      this.mapInstance.on('dragend', evt => this.zone.run(() => {
        events.mapDragEnd.emit(evt);
        events.dragEnd.emit(evt);
      }));
    }
    if (events.zoomStart.observed) {
      this.mapInstance.on('zoomstart', evt => this.zone.run(() => events.zoomStart.emit(evt)));
    }
    if (events.zoomEvt.observed) {
      this.mapInstance.on('zoom', evt => this.zone.run(() => events.zoomEvt.emit(evt)));
    }
    if (events.zoomEnd.observed) {
      this.mapInstance.on('zoomend', evt => this.zone.run(() => events.zoomEnd.emit(evt)));
    }
    if (events.rotateStart.observed) {
      this.mapInstance.on('rotatestart', evt => this.zone.run(() => events.rotateStart.emit(evt)));
    }
    if (events.rotate.observed) {
      this.mapInstance.on('rotate', evt => this.zone.run(() => events.rotate.emit(evt)));
    }
    if (events.rotateEnd.observed) {
      this.mapInstance.on('rotateend', evt => this.zone.run(() => events.rotateEnd.emit(evt)));
    }
    if (events.pitchStart.observed) {
      this.mapInstance.on('pitchstart', evt => this.zone.run(() => events.pitchStart.emit(evt)));
    }
    if (events.pitchEvt.observed) {
      this.mapInstance.on('pitch', evt => this.zone.run(() => events.pitchEvt.emit(evt)));
    }
    if (events.pitchEnd.observed) {
      this.mapInstance.on('pitchend', evt => this.zone.run(() => events.pitchEnd.emit(evt)));
    }
    if (events.boxZoomStart.observed) {
      this.mapInstance.on('boxzoomstart', evt => this.zone.run(() => events.boxZoomStart.emit(evt)));
    }
    if (events.boxZoomEnd.observed) {
      this.mapInstance.on('boxzoomend', evt => this.zone.run(() => events.boxZoomEnd.emit(evt)));
    }
    if (events.boxZoomCancel.observed) {
      this.mapInstance.on('boxzoomcancel', evt => this.zone.run(() => events.boxZoomCancel.emit(evt)));
    }
    if (events.webGlContextLost.observed) {
      this.mapInstance.on('webglcontextlost', evt => this.zone.run(() => events.webGlContextLost.emit(evt)));
    }
    if (events.webGlContextRestored.observed) {
      this.mapInstance.on('webglcontextrestored', evt => this.zone.run(() => events.webGlContextRestored.emit(evt)));
    }
    if (events.render.observed) {
      this.mapInstance.on('render', evt => this.zone.run(() => events.render.emit(evt)));
    }
    if (events.mapError.observed || events.error.observed) {
      this.mapInstance.on('error', evt => this.zone.run(() => {
        events.mapError.emit(evt);
        events.error.emit(evt);
      }));
    }
    if (events.data.observed) {
      this.mapInstance.on('data', evt => this.zone.run(() => events.data.emit(evt)));
    }
    if (events.styleData.observed) {
      this.mapInstance.on('styledata', evt => this.zone.run(() => events.styleData.emit(evt)));
    }
    if (events.sourceData.observed) {
      this.mapInstance.on('sourcedata', evt => this.zone.run(() => events.sourceData.emit(evt)));
    }
    if (events.dataLoading.observed) {
      this.mapInstance.on('dataloading', evt => this.zone.run(() => events.dataLoading.emit(evt)));
    }
    if (events.styleDataLoading.observed) {
      this.mapInstance.on('styledataloading', evt => this.zone.run(() => events.styleDataLoading.emit(evt)));
    }
    if (events.sourceDataLoading.observed) {
      this.mapInstance.on('sourcedataloading', evt => this.zone.run(() => events.sourceDataLoading.emit(evt)));
    }
    if (events.styleImageMissing.observed) {
      this.mapInstance.on('styleimagemissing', evt => this.zone.run(() => events.styleImageMissing.emit(evt)));
    }
    if (events.idle.observed) {
      this.mapInstance.on('idle', evt => this.zone.run(() => events.idle.emit(evt)));
    }
  }
  // TODO move this elsewhere
  assign(obj, prop, value) {
    if (typeof prop === 'string') {
      // eslint-disable-next-line no-param-reassign
      prop = prop.split('.');
    }
    if (prop.length > 1) {
      const e = prop.shift();
      this.assign(obj[e] = Object.prototype.toString.call(obj[e]) === '[object Object]' ? obj[e] : {}, prop, value);
    } else {
      obj[prop[0]] = value;
    }
  }
  static {
    this.ɵfac = function MapService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MapService)(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(MAPBOX_API_KEY, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MapService,
      factory: MapService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MapService, [{
    type: Injectable
  }], () => [{
    type: i0.NgZone
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [MAPBOX_API_KEY]
    }]
  }], null);
})();
class CustomControl {
  constructor(container) {
    this.container = container;
  }
  onAdd() {
    return this.container;
  }
  onRemove() {
    return this.container.parentNode.removeChild(this.container);
  }
  getDefaultPosition() {
    return 'top-right';
  }
}
class ControlComponent {
  constructor(mapService) {
    this.mapService = mapService;
    this.controlAdded = false;
  }
  ngAfterContentInit() {
    if (this.content.nativeElement.childNodes.length) {
      this.control = new CustomControl(this.content.nativeElement);
      this.mapService.mapCreated$.subscribe(() => {
        this.mapService.addControl(this.control, this.position);
        this.controlAdded = true;
      });
    }
  }
  ngOnDestroy() {
    if (this.controlAdded) {
      this.mapService.removeControl(this.control);
    }
  }
  static {
    this.ɵfac = function ControlComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ControlComponent)(i0.ɵɵdirectiveInject(MapService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ControlComponent,
      selectors: [["mgl-control"]],
      viewQuery: function ControlComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
        }
      },
      inputs: {
        position: "position"
      },
      standalone: false,
      ngContentSelectors: _c1,
      decls: 3,
      vars: 0,
      consts: [["content", ""], [1, "mapboxgl-ctrl"]],
      template: function ControlComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 1, 0);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ControlComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-control',
      template: '<div class="mapboxgl-ctrl" #content><ng-content></ng-content></div>',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: MapService
  }], {
    position: [{
      type: Input
    }],
    content: [{
      type: ViewChild,
      args: ['content', {
        static: true
      }]
    }]
  });
})();
class AttributionControlDirective {
  constructor(mapService, controlComponent) {
    this.mapService = mapService;
    this.controlComponent = controlComponent;
  }
  ngAfterContentInit() {
    this.mapService.mapCreated$.subscribe(() => {
      if (this.controlComponent.control) {
        throw new Error('Another control is already set for this control');
      }
      const options = {};
      if (this.compact !== undefined) {
        options.compact = this.compact;
      }
      if (this.customAttribution !== undefined) {
        options.customAttribution = this.customAttribution;
      }
      this.controlComponent.control = new AttributionControl(options);
      this.mapService.addControl(this.controlComponent.control, this.controlComponent.position);
    });
  }
  static {
    this.ɵfac = function AttributionControlDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AttributionControlDirective)(i0.ɵɵdirectiveInject(MapService), i0.ɵɵdirectiveInject(ControlComponent, 1));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AttributionControlDirective,
      selectors: [["", "mglAttribution", ""]],
      inputs: {
        compact: "compact",
        customAttribution: "customAttribution"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AttributionControlDirective, [{
    type: Directive,
    args: [{
      selector: '[mglAttribution]'
    }]
  }], () => [{
    type: MapService
  }, {
    type: ControlComponent,
    decorators: [{
      type: Host
    }]
  }], {
    compact: [{
      type: Input
    }],
    customAttribution: [{
      type: Input
    }]
  });
})();
class FullscreenControlDirective {
  constructor(mapService, controlComponent) {
    this.mapService = mapService;
    this.controlComponent = controlComponent;
  }
  onFullscreen() {
    this.mapService.mapInstance.resize();
  }
  ngAfterContentInit() {
    this.mapService.mapCreated$.subscribe(() => {
      if (this.controlComponent.control) {
        throw new Error('Another control is already set for this control');
      }
      this.controlComponent.control = new FullscreenControl({
        container: this.container
      });
      this.mapService.addControl(this.controlComponent.control, this.controlComponent.position);
    });
  }
  static {
    this.ɵfac = function FullscreenControlDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FullscreenControlDirective)(i0.ɵɵdirectiveInject(MapService), i0.ɵɵdirectiveInject(ControlComponent, 1));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: FullscreenControlDirective,
      selectors: [["", "mglFullscreen", ""]],
      hostBindings: function FullscreenControlDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("webkitfullscreenchange", function FullscreenControlDirective_webkitfullscreenchange_HostBindingHandler($event) {
            return ctx.onFullscreen($event.target);
          }, false, i0.ɵɵresolveWindow);
        }
      },
      inputs: {
        container: "container"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FullscreenControlDirective, [{
    type: Directive,
    args: [{
      selector: '[mglFullscreen]'
    }]
  }], () => [{
    type: MapService
  }, {
    type: ControlComponent,
    decorators: [{
      type: Host
    }]
  }], {
    container: [{
      type: Input
    }],
    onFullscreen: [{
      type: HostListener,
      args: ['window:webkitfullscreenchange', ['$event.target']]
    }]
  });
})();
class GeolocateControlDirective {
  constructor(mapService, controlComponent) {
    this.mapService = mapService;
    this.controlComponent = controlComponent;
    this.geolocate = new EventEmitter();
  }
  ngAfterContentInit() {
    this.mapService.mapCreated$.subscribe(() => {
      if (this.controlComponent.control) {
        throw new Error('Another control is already set for this control');
      }
      const options = {
        positionOptions: this.positionOptions,
        fitBoundsOptions: this.fitBoundsOptions,
        trackUserLocation: this.trackUserLocation,
        showUserLocation: this.showUserLocation,
        showUserHeading: this.showUserHeading
      };
      Object.keys(options).forEach(key => {
        const tkey = key;
        if (options[tkey] === undefined) {
          delete options[tkey];
        }
      });
      this.controlComponent.control = new GeolocateControl(options);
      this.controlComponent.control.on('geolocate', data => {
        this.geolocate.emit(data);
      });
      this.mapService.addControl(this.controlComponent.control, this.controlComponent.position);
    });
  }
  static {
    this.ɵfac = function GeolocateControlDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GeolocateControlDirective)(i0.ɵɵdirectiveInject(MapService), i0.ɵɵdirectiveInject(ControlComponent, 1));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: GeolocateControlDirective,
      selectors: [["", "mglGeolocate", ""]],
      inputs: {
        positionOptions: "positionOptions",
        fitBoundsOptions: "fitBoundsOptions",
        trackUserLocation: "trackUserLocation",
        showUserLocation: "showUserLocation",
        showUserHeading: "showUserHeading"
      },
      outputs: {
        geolocate: "geolocate"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GeolocateControlDirective, [{
    type: Directive,
    args: [{
      selector: '[mglGeolocate]'
    }]
  }], () => [{
    type: MapService
  }, {
    type: ControlComponent,
    decorators: [{
      type: Host
    }]
  }], {
    positionOptions: [{
      type: Input
    }],
    fitBoundsOptions: [{
      type: Input
    }],
    trackUserLocation: [{
      type: Input
    }],
    showUserLocation: [{
      type: Input
    }],
    showUserHeading: [{
      type: Input
    }],
    geolocate: [{
      type: Output
    }]
  });
})();
class NavigationControlDirective {
  constructor(mapService, controlComponent) {
    this.mapService = mapService;
    this.controlComponent = controlComponent;
  }
  ngAfterContentInit() {
    this.mapService.mapCreated$.subscribe(() => {
      if (this.controlComponent.control) {
        throw new Error('Another control is already set for this control');
      }
      const options = {};
      if (this.showCompass !== undefined) {
        options.showCompass = this.showCompass;
      }
      if (this.showZoom !== undefined) {
        options.showZoom = this.showZoom;
      }
      this.controlComponent.control = new NavigationControl(options);
      this.mapService.addControl(this.controlComponent.control, this.controlComponent.position);
    });
  }
  static {
    this.ɵfac = function NavigationControlDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NavigationControlDirective)(i0.ɵɵdirectiveInject(MapService), i0.ɵɵdirectiveInject(ControlComponent, 1));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NavigationControlDirective,
      selectors: [["", "mglNavigation", ""]],
      inputs: {
        showCompass: "showCompass",
        showZoom: "showZoom"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NavigationControlDirective, [{
    type: Directive,
    args: [{
      selector: '[mglNavigation]'
    }]
  }], () => [{
    type: MapService
  }, {
    type: ControlComponent,
    decorators: [{
      type: Host
    }]
  }], {
    showCompass: [{
      type: Input
    }],
    showZoom: [{
      type: Input
    }]
  });
})();
class ScaleControlDirective {
  constructor(mapService, controlComponent) {
    this.mapService = mapService;
    this.controlComponent = controlComponent;
  }
  ngOnChanges(changes) {
    if (changes['unit'] && !changes['unit'].isFirstChange()) {
      this.controlComponent.control.setUnit(changes['unit'].currentValue);
    }
  }
  ngAfterContentInit() {
    this.mapService.mapCreated$.subscribe(() => {
      if (this.controlComponent.control) {
        throw new Error('Another control is already set for this control');
      }
      const options = {};
      if (this.maxWidth !== undefined) {
        options.maxWidth = this.maxWidth;
      }
      if (this.unit !== undefined) {
        options.unit = this.unit;
      }
      this.controlComponent.control = new ScaleControl(options);
      this.mapService.addControl(this.controlComponent.control, this.controlComponent.position);
    });
  }
  static {
    this.ɵfac = function ScaleControlDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ScaleControlDirective)(i0.ɵɵdirectiveInject(MapService), i0.ɵɵdirectiveInject(ControlComponent, 1));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ScaleControlDirective,
      selectors: [["", "mglScale", ""]],
      inputs: {
        maxWidth: "maxWidth",
        unit: "unit"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScaleControlDirective, [{
    type: Directive,
    args: [{
      selector: '[mglScale]'
    }]
  }], () => [{
    type: MapService
  }, {
    type: ControlComponent,
    decorators: [{
      type: Host
    }]
  }], {
    maxWidth: [{
      type: Input
    }],
    unit: [{
      type: Input
    }]
  });
})();
const deprecationWarning = (context, oldApi, newApi) => {
  console.warn(`[ngx-mapbox-gl]: ${context}: ${oldApi} is deprecated, please use ${newApi} instead.`);
};
class LayerComponent {
  constructor(mapService) {
    this.mapService = mapService;
    this.layerClick = new EventEmitter();
    this.layerDblClick = new EventEmitter();
    this.layerMouseDown = new EventEmitter();
    this.layerMouseUp = new EventEmitter();
    this.layerMouseEnter = new EventEmitter();
    this.layerMouseLeave = new EventEmitter();
    this.layerMouseMove = new EventEmitter();
    this.layerMouseOver = new EventEmitter();
    this.layerMouseOut = new EventEmitter();
    this.layerContextMenu = new EventEmitter();
    this.layerTouchStart = new EventEmitter();
    this.layerTouchEnd = new EventEmitter();
    this.layerTouchCancel = new EventEmitter();
    /**
     * @deprecated Use layerClick instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.click = new EventEmitter();
    /**
     * @deprecated Use layerDblClick instead
     */
    this.dblClick = new EventEmitter();
    /**
     * @deprecated Use layerMouseDown instead
     */
    this.mouseDown = new EventEmitter();
    /**
     * @deprecated Use layerMouseUp instead
     */
    this.mouseUp = new EventEmitter();
    /**
     * @deprecated Use layerMouseEnter instead
     */
    this.mouseEnter = new EventEmitter();
    /**
     * @deprecated Use layerMouseLeave instead
     */
    this.mouseLeave = new EventEmitter();
    /**
     * @deprecated Use layerMouseMove instead
     */
    this.mouseMove = new EventEmitter();
    /**
     * @deprecated Use layerMouseOver instead
     */
    this.mouseOver = new EventEmitter();
    /**
     * @deprecated Use layerMouseOut instead
     */
    this.mouseOut = new EventEmitter();
    /**
     * @deprecated Use layerContextMenu instead
     */
    this.contextMenu = new EventEmitter();
    /**
     * @deprecated Use layerTouchStart instead
     */
    this.touchStart = new EventEmitter();
    /**
     * @deprecated Use layerTouchEnd instead
     */
    this.touchEnd = new EventEmitter();
    /**
     * @deprecated Use layerTouchCancel instead
     */
    this.touchCancel = new EventEmitter();
    this.layerAdded = false;
  }
  ngOnInit() {
    this.warnDeprecatedOutputs();
    this.sub = this.mapService.mapLoaded$.pipe(switchMap(() => fromEvent(this.mapService.mapInstance, 'styledata').pipe(mapTo(false), filter(() => !this.mapService.mapInstance.getLayer(this.id)), startWith(true)))).subscribe(bindEvents => this.init(bindEvents));
  }
  ngOnChanges(changes) {
    if (!this.layerAdded) {
      return;
    }
    if (changes['paint'] && !changes['paint'].isFirstChange()) {
      this.mapService.setAllLayerPaintProperty(this.id, changes['paint'].currentValue);
    }
    if (changes['layout'] && !changes['layout'].isFirstChange()) {
      this.mapService.setAllLayerLayoutProperty(this.id, changes['layout'].currentValue);
    }
    if (changes['filter'] && !changes['filter'].isFirstChange()) {
      this.mapService.setLayerFilter(this.id, changes['filter'].currentValue);
    }
    if (changes['before'] && !changes['before'].isFirstChange()) {
      this.mapService.setLayerBefore(this.id, changes['before'].currentValue);
    }
    if (changes['minzoom'] && !changes['minzoom'].isFirstChange() || changes['maxzoom'] && !changes['maxzoom'].isFirstChange()) {
      this.mapService.setLayerZoomRange(this.id, this.minzoom, this.maxzoom);
    }
  }
  ngOnDestroy() {
    if (this.layerAdded) {
      this.mapService.removeLayer(this.id);
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
  init(bindEvents) {
    const layer = {
      layerOptions: {
        id: this.id,
        type: this.type,
        source: this.source,
        metadata: this.metadata,
        'source-layer': this.sourceLayer,
        minzoom: this.minzoom,
        maxzoom: this.maxzoom,
        filter: this.filter,
        layout: this.layout,
        paint: this.paint
      },
      layerEvents: {
        layerClick: this.layerClick,
        layerDblClick: this.layerDblClick,
        layerMouseDown: this.layerMouseDown,
        layerMouseUp: this.layerMouseUp,
        layerMouseEnter: this.layerMouseEnter,
        layerMouseLeave: this.layerMouseLeave,
        layerMouseMove: this.layerMouseMove,
        layerMouseOver: this.layerMouseOver,
        layerMouseOut: this.layerMouseOut,
        layerContextMenu: this.layerContextMenu,
        layerTouchStart: this.layerTouchStart,
        layerTouchEnd: this.layerTouchEnd,
        layerTouchCancel: this.layerTouchCancel,
        click: this.click,
        dblClick: this.dblClick,
        mouseDown: this.mouseDown,
        mouseUp: this.mouseUp,
        mouseEnter: this.mouseEnter,
        mouseLeave: this.mouseLeave,
        mouseMove: this.mouseMove,
        mouseOver: this.mouseOver,
        mouseOut: this.mouseOut,
        contextMenu: this.contextMenu,
        touchStart: this.touchStart,
        touchEnd: this.touchEnd,
        touchCancel: this.touchCancel
      }
    };
    this.mapService.addLayer(layer, bindEvents, this.before);
    this.layerAdded = true;
  }
  warnDeprecatedOutputs() {
    const dw = deprecationWarning.bind(undefined, LayerComponent.name);
    if (this.click.observed) {
      dw('click', 'layerClick');
    }
    if (this.dblClick.observed) {
      dw('dblClick', 'layerDblClick');
    }
    if (this.mouseDown.observed) {
      dw('mouseDown', 'layerMouseDown');
    }
    if (this.mouseUp.observed) {
      dw('mouseUp', 'layerMouseUp');
    }
    if (this.mouseEnter.observed) {
      dw('mouseEnter', 'layerMouseEnter');
    }
    if (this.mouseLeave.observed) {
      dw('mouseLeave', 'layerMouseLeave');
    }
    if (this.mouseMove.observed) {
      dw('mouseMove', 'layerMouseMove');
    }
    if (this.mouseOver.observed) {
      dw('mouseOver', 'layerMouseOver');
    }
    if (this.mouseOut.observed) {
      dw('mouseOut', 'layerMouseOut');
    }
    if (this.contextMenu.observed) {
      dw('contextMenu', 'layerContextMenu');
    }
    if (this.touchStart.observed) {
      dw('touchStart', 'layerTouchStart');
    }
    if (this.touchEnd.observed) {
      dw('touchEnd', 'layerTouchEnd');
    }
    if (this.touchCancel.observed) {
      dw('touchCancel', 'layerTouchCancel');
    }
  }
  static {
    this.ɵfac = function LayerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LayerComponent)(i0.ɵɵdirectiveInject(MapService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LayerComponent,
      selectors: [["mgl-layer"]],
      inputs: {
        id: "id",
        source: "source",
        type: "type",
        metadata: "metadata",
        sourceLayer: "sourceLayer",
        filter: "filter",
        layout: "layout",
        paint: "paint",
        before: "before",
        minzoom: "minzoom",
        maxzoom: "maxzoom"
      },
      outputs: {
        layerClick: "layerClick",
        layerDblClick: "layerDblClick",
        layerMouseDown: "layerMouseDown",
        layerMouseUp: "layerMouseUp",
        layerMouseEnter: "layerMouseEnter",
        layerMouseLeave: "layerMouseLeave",
        layerMouseMove: "layerMouseMove",
        layerMouseOver: "layerMouseOver",
        layerMouseOut: "layerMouseOut",
        layerContextMenu: "layerContextMenu",
        layerTouchStart: "layerTouchStart",
        layerTouchEnd: "layerTouchEnd",
        layerTouchCancel: "layerTouchCancel",
        click: "click",
        dblClick: "dblClick",
        mouseDown: "mouseDown",
        mouseUp: "mouseUp",
        mouseEnter: "mouseEnter",
        mouseLeave: "mouseLeave",
        mouseMove: "mouseMove",
        mouseOver: "mouseOver",
        mouseOut: "mouseOut",
        contextMenu: "contextMenu",
        touchStart: "touchStart",
        touchEnd: "touchEnd",
        touchCancel: "touchCancel"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function LayerComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayerComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-layer',
      template: ''
    }]
  }], () => [{
    type: MapService
  }], {
    id: [{
      type: Input
    }],
    source: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    metadata: [{
      type: Input
    }],
    sourceLayer: [{
      type: Input
    }],
    filter: [{
      type: Input
    }],
    layout: [{
      type: Input
    }],
    paint: [{
      type: Input
    }],
    before: [{
      type: Input
    }],
    minzoom: [{
      type: Input
    }],
    maxzoom: [{
      type: Input
    }],
    layerClick: [{
      type: Output
    }],
    layerDblClick: [{
      type: Output
    }],
    layerMouseDown: [{
      type: Output
    }],
    layerMouseUp: [{
      type: Output
    }],
    layerMouseEnter: [{
      type: Output
    }],
    layerMouseLeave: [{
      type: Output
    }],
    layerMouseMove: [{
      type: Output
    }],
    layerMouseOver: [{
      type: Output
    }],
    layerMouseOut: [{
      type: Output
    }],
    layerContextMenu: [{
      type: Output
    }],
    layerTouchStart: [{
      type: Output
    }],
    layerTouchEnd: [{
      type: Output
    }],
    layerTouchCancel: [{
      type: Output
    }],
    click: [{
      type: Output
    }],
    dblClick: [{
      type: Output
    }],
    mouseDown: [{
      type: Output
    }],
    mouseUp: [{
      type: Output
    }],
    mouseEnter: [{
      type: Output
    }],
    mouseLeave: [{
      type: Output
    }],
    mouseMove: [{
      type: Output
    }],
    mouseOver: [{
      type: Output
    }],
    mouseOut: [{
      type: Output
    }],
    contextMenu: [{
      type: Output
    }],
    touchStart: [{
      type: Output
    }],
    touchEnd: [{
      type: Output
    }],
    touchCancel: [{
      type: Output
    }]
  });
})();
class GeoJSONSourceComponent {
  constructor(mapService, zone) {
    this.mapService = mapService;
    this.zone = zone;
    this.updateFeatureData = new Subject();
    this.sub = new Subscription();
    this.sourceAdded = false;
    this.featureIdCounter = 0;
  }
  ngOnInit() {
    if (!this.data) {
      this.data = {
        type: 'FeatureCollection',
        features: []
      };
    }
    const sub1 = this.mapService.mapLoaded$.subscribe(() => {
      this.init();
      const sub = fromEvent(this.mapService.mapInstance, 'styledata').pipe(filter(() => !this.mapService.mapInstance.getSource(this.id))).subscribe(() => {
        this.init();
      });
      this.sub.add(sub);
    });
    this.sub.add(sub1);
  }
  ngOnChanges(changes) {
    if (!this.sourceAdded) {
      return;
    }
    if (changes['maxzoom'] && !changes['maxzoom'].isFirstChange() || changes['attribution'] && !changes['attribution'].isFirstChange() || changes['buffer'] && !changes['buffer'].isFirstChange() || changes['tolerance'] && !changes['tolerance'].isFirstChange() || changes['cluster'] && !changes['cluster'].isFirstChange() || changes['clusterRadius'] && !changes['clusterRadius'].isFirstChange() || changes['clusterMaxZoom'] && !changes['clusterMaxZoom'].isFirstChange() || changes['clusterMinPoints'] && !changes['clusterMinPoints'].isFirstChange() || changes['clusterProperties'] && !changes['clusterProperties'].isFirstChange() || changes['lineMetrics'] && !changes['lineMetrics'].isFirstChange() || changes['generateId'] && !changes['generateId'].isFirstChange() || changes['promoteId'] && !changes['promoteId'].isFirstChange() || changes['filter'] && !changes['filter'].isFirstChange()) {
      this.ngOnDestroy();
      this.ngOnInit();
    }
    if (changes['data'] && !changes['data'].isFirstChange()) {
      const source = this.mapService.getSource(this.id);
      if (source === undefined) {
        return;
      }
      source.setData(this.data);
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.sourceAdded) {
      this.mapService.removeSource(this.id);
      this.sourceAdded = false;
    }
  }
  /**
   * For clustered sources, fetches the zoom at which the given cluster expands.
   *
   * @param clusterId The value of the cluster's cluster_id property.
   */
  async getClusterExpansionZoom(clusterId) {
    const source = this.mapService.getSource(this.id);
    return this.zone.run(async () => new Promise((resolve, reject) => {
      source.getClusterExpansionZoom(clusterId, (error, zoom) => {
        if (error) {
          reject(error);
        } else {
          resolve(zoom);
        }
      });
    }));
  }
  /**
   * For clustered sources, fetches the children of the given cluster on the next zoom level (as an array of GeoJSON features).
   *
   * @param clusterId The value of the cluster's cluster_id property.
   */
  async getClusterChildren(clusterId) {
    const source = this.mapService.getSource(this.id);
    return this.zone.run(async () => new Promise((resolve, reject) => {
      source.getClusterChildren(clusterId, (error, features) => {
        if (error) {
          reject(error);
        } else {
          resolve(features);
        }
      });
    }));
  }
  /**
   * For clustered sources, fetches the original points that belong to the cluster (as an array of GeoJSON features).
   *
   * @param clusterId The value of the cluster's cluster_id property.
   * @param limit The maximum number of features to return.
   * @param offset The number of features to skip (e.g. for pagination).
   */
  async getClusterLeaves(clusterId, limit, offset) {
    const source = this.mapService.getSource(this.id);
    return this.zone.run(async () => new Promise((resolve, reject) => {
      source.getClusterLeaves(clusterId, limit, offset, (error, features) => {
        if (error) {
          reject(error);
        } else {
          resolve(features);
        }
      });
    }));
  }
  _addFeature(feature) {
    const collection = this.data;
    collection.features.push(feature);
    this.updateFeatureData.next(null);
  }
  _removeFeature(feature) {
    const collection = this.data;
    const index = collection.features.indexOf(feature);
    if (index > -1) {
      collection.features.splice(index, 1);
    }
    this.updateFeatureData.next(null);
  }
  _getNewFeatureId() {
    return ++this.featureIdCounter;
  }
  init() {
    const source = {
      type: 'geojson',
      data: this.data,
      maxzoom: this.maxzoom,
      attribution: this.attribution,
      buffer: this.buffer,
      tolerance: this.tolerance,
      cluster: this.cluster,
      clusterRadius: this.clusterRadius,
      clusterMaxZoom: this.clusterMaxZoom,
      clusterMinPoints: this.clusterMinPoints,
      clusterProperties: this.clusterProperties,
      lineMetrics: this.lineMetrics,
      generateId: this.generateId,
      promoteId: this.promoteId,
      filter: this.filter
    };
    this.mapService.addSource(this.id, source);
    const sub = this.updateFeatureData.pipe(debounceTime(0)).subscribe(() => {
      const source = this.mapService.getSource(this.id);
      if (source === undefined) {
        return;
      }
      source.setData(this.data);
    });
    this.sub.add(sub);
    this.sourceAdded = true;
  }
  static {
    this.ɵfac = function GeoJSONSourceComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GeoJSONSourceComponent)(i0.ɵɵdirectiveInject(MapService), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: GeoJSONSourceComponent,
      selectors: [["mgl-geojson-source"]],
      inputs: {
        id: "id",
        data: "data",
        maxzoom: "maxzoom",
        attribution: "attribution",
        buffer: "buffer",
        tolerance: "tolerance",
        cluster: "cluster",
        clusterRadius: "clusterRadius",
        clusterMaxZoom: "clusterMaxZoom",
        clusterMinPoints: "clusterMinPoints",
        clusterProperties: "clusterProperties",
        lineMetrics: "lineMetrics",
        generateId: "generateId",
        promoteId: "promoteId",
        filter: "filter"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function GeoJSONSourceComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GeoJSONSourceComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-geojson-source',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: MapService
  }, {
    type: i0.NgZone
  }], {
    id: [{
      type: Input
    }],
    data: [{
      type: Input
    }],
    maxzoom: [{
      type: Input
    }],
    attribution: [{
      type: Input
    }],
    buffer: [{
      type: Input
    }],
    tolerance: [{
      type: Input
    }],
    cluster: [{
      type: Input
    }],
    clusterRadius: [{
      type: Input
    }],
    clusterMaxZoom: [{
      type: Input
    }],
    clusterMinPoints: [{
      type: Input
    }],
    clusterProperties: [{
      type: Input
    }],
    lineMetrics: [{
      type: Input
    }],
    generateId: [{
      type: Input
    }],
    promoteId: [{
      type: Input
    }],
    filter: [{
      type: Input
    }]
  });
})();
class FeatureComponent {
  constructor(GeoJSONSourceComponent) {
    this.GeoJSONSourceComponent = GeoJSONSourceComponent;
    this.type = 'Feature';
  }
  ngOnInit() {
    if (!this.id) {
      this.id = this.GeoJSONSourceComponent._getNewFeatureId();
    }
    this.feature = {
      type: this.type,
      geometry: this.geometry,
      properties: this.properties ? this.properties : {}
    };
    this.feature.id = this.id;
    this.GeoJSONSourceComponent._addFeature(this.feature);
  }
  ngOnDestroy() {
    this.GeoJSONSourceComponent._removeFeature(this.feature);
  }
  updateCoordinates(coordinates) {
    this.feature.geometry.coordinates = coordinates;
    this.GeoJSONSourceComponent.updateFeatureData.next(null);
  }
  static {
    this.ɵfac = function FeatureComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FeatureComponent)(i0.ɵɵdirectiveInject(forwardRef(() => GeoJSONSourceComponent)));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FeatureComponent,
      selectors: [["mgl-feature"]],
      inputs: {
        id: "id",
        geometry: "geometry",
        properties: "properties"
      },
      standalone: false,
      decls: 0,
      vars: 0,
      template: function FeatureComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FeatureComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-feature',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: GeoJSONSourceComponent,
    decorators: [{
      type: Inject,
      args: [forwardRef(() => GeoJSONSourceComponent)]
    }]
  }], {
    id: [{
      type: Input
    }],
    geometry: [{
      type: Input
    }],
    properties: [{
      type: Input
    }]
  });
})();
class DraggableDirective {
  constructor(mapService, ngZone, featureComponent) {
    this.mapService = mapService;
    this.ngZone = ngZone;
    this.featureComponent = featureComponent;
    this.featureDragStart = new EventEmitter();
    this.featureDragEnd = new EventEmitter();
    this.featureDrag = new EventEmitter();
    /**
     * @deprecated Use featureDragStart instead
     */
    this.dragStart = new EventEmitter();
    /**
     * @deprecated Use featureDragEnd instead
     */
    this.dragEnd = new EventEmitter();
    /**
     * @deprecated Use featureDrag instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.drag = new EventEmitter();
    this.sub = new Subscription();
  }
  ngOnInit() {
    this.warnDeprecatedOutputs();
    let enter$;
    let leave$;
    let updateCoords;
    if (this.featureComponent && this.layer) {
      enter$ = this.layer.layerMouseEnter;
      leave$ = this.layer.layerMouseLeave;
      updateCoords = this.featureComponent.updateCoordinates.bind(this.featureComponent);
      if (this.featureComponent.geometry.type !== 'Point') {
        throw new Error('mglDraggable only support point feature');
      }
    } else {
      throw new Error('mglDraggable can only be used on Feature (with a layer as input) or Marker');
    }
    this.handleDraggable(enter$, leave$, updateCoords);
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  handleDraggable(enter$, leave$, updateCoords) {
    let moving = false;
    let inside = false;
    this.mapService.mapCreated$.subscribe(() => {
      const mouseUp$ = fromEvent(this.mapService.mapInstance, 'mouseup');
      const dragStart$ = enter$.pipe(filter(() => !moving), filter(evt => this.filterFeature(evt)), tap(() => {
        inside = true;
        this.mapService.changeCanvasCursor('move');
        this.mapService.updateDragPan(false);
      }), switchMap(() => fromEvent(this.mapService.mapInstance, 'mousedown').pipe(takeUntil(leave$))));
      const dragging$ = dragStart$.pipe(switchMap(() => fromEvent(this.mapService.mapInstance, 'mousemove').pipe(takeUntil(mouseUp$))));
      const dragEnd$ = dragStart$.pipe(switchMap(() => mouseUp$.pipe(take(1))));
      this.sub.add(dragStart$.subscribe(evt => {
        moving = true;
        if (this.featureDragStart.observed || this.dragStart.observed) {
          this.ngZone.run(() => {
            this.featureDragStart.emit(evt);
            this.dragStart.emit(evt);
          });
        }
      }));
      this.sub.add(dragging$.subscribe(evt => {
        updateCoords([evt.lngLat.lng, evt.lngLat.lat]);
        if (this.featureDrag.observed || this.drag.observed) {
          this.ngZone.run(() => {
            this.featureDrag.emit(evt);
            this.drag.emit(evt);
          });
        }
      }));
      this.sub.add(dragEnd$.subscribe(evt => {
        moving = false;
        if (this.featureDragEnd.observed || this.dragEnd.observed) {
          this.ngZone.run(() => {
            this.featureDragEnd.emit(evt);
            this.dragEnd.emit(evt);
          });
        }
        if (!inside) {
          // It's possible to dragEnd outside the target (small input lag)
          this.mapService.changeCanvasCursor('');
          this.mapService.updateDragPan(true);
        }
      }));
      this.sub.add(leave$.pipe(tap(() => inside = false), filter(() => !moving)).subscribe(() => {
        this.mapService.changeCanvasCursor('');
        this.mapService.updateDragPan(true);
      }));
    });
  }
  filterFeature(evt) {
    if (this.featureComponent && this.layer) {
      const feature = this.mapService.queryRenderedFeatures(evt.point, {
        layers: [this.layer.id],
        filter: ['all', ['==', '$type', 'Point'], ['==', '$id', this.featureComponent.id]]
      })[0];
      if (!feature) {
        return false;
      }
    }
    return true;
  }
  warnDeprecatedOutputs() {
    const dw = deprecationWarning.bind(undefined, DraggableDirective.name);
    if (this.dragStart.observed) {
      dw('dragStart', 'featureDragStart');
    }
    if (this.dragEnd.observed) {
      dw('dragEnd', 'featureDragEnd');
    }
    if (this.drag.observed) {
      dw('drag', 'featureDrag');
    }
  }
  static {
    this.ɵfac = function DraggableDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DraggableDirective)(i0.ɵɵdirectiveInject(MapService), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(FeatureComponent, 9));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DraggableDirective,
      selectors: [["", "mglDraggable", ""]],
      inputs: {
        layer: [0, "mglDraggable", "layer"]
      },
      outputs: {
        featureDragStart: "featureDragStart",
        featureDragEnd: "featureDragEnd",
        featureDrag: "featureDrag",
        dragStart: "dragStart",
        dragEnd: "dragEnd",
        drag: "drag"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DraggableDirective, [{
    type: Directive,
    args: [{
      selector: '[mglDraggable]'
    }]
  }], () => [{
    type: MapService
  }, {
    type: i0.NgZone
  }, {
    type: FeatureComponent,
    decorators: [{
      type: Optional
    }, {
      type: Host
    }]
  }], {
    layer: [{
      type: Input,
      args: ['mglDraggable']
    }],
    featureDragStart: [{
      type: Output
    }],
    featureDragEnd: [{
      type: Output
    }],
    featureDrag: [{
      type: Output
    }],
    dragStart: [{
      type: Output
    }],
    dragEnd: [{
      type: Output
    }],
    drag: [{
      type: Output
    }]
  });
})();
class ImageComponent {
  constructor(mapService, zone) {
    this.mapService = mapService;
    this.zone = zone;
    this.imageError = new EventEmitter();
    this.imageLoaded = new EventEmitter();
    /**
     * @deprecated Use imageError instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.error = new EventEmitter();
    /**
     * @deprecated Use imageLoaded instead
     */
    this.loaded = new EventEmitter();
    this.isAdded = false;
    this.isAdding = false;
  }
  ngOnInit() {
    this.warnDeprecatedOutputs();
    this.sub = this.mapService.mapLoaded$.pipe(switchMap(() => fromEvent(this.mapService.mapInstance, 'styledata').pipe(startWith(undefined), filter(() => !this.isAdding && !this.mapService.mapInstance.hasImage(this.id))))).subscribe(() => this.init());
  }
  ngOnChanges(changes) {
    if (changes['data'] && !changes['data'].isFirstChange() || changes['options'] && !changes['options'].isFirstChange() || changes['url'] && !changes['url'].isFirstChange()) {
      this.ngOnDestroy();
      this.ngOnInit();
    }
  }
  ngOnDestroy() {
    if (this.isAdded) {
      this.mapService.removeImage(this.id);
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
  async init() {
    this.isAdding = true;
    if (this.data) {
      this.mapService.addImage(this.id, this.data, this.options);
      this.isAdded = true;
      this.isAdding = false;
    } else if (this.url) {
      try {
        await this.mapService.loadAndAddImage(this.id, this.url, this.options);
        this.isAdded = true;
        this.isAdding = false;
        this.zone.run(() => {
          this.imageLoaded.emit();
          this.loaded.emit();
        });
      } catch (error) {
        this.zone.run(() => {
          this.imageError.emit(error);
          this.error.emit(error);
        });
      }
    }
  }
  warnDeprecatedOutputs() {
    const dw = deprecationWarning.bind(undefined, ImageComponent.name);
    if (this.error.observed) {
      dw('error', 'imageError');
    }
    if (this.loaded.observed) {
      dw('loaded', 'imageLoaded');
    }
  }
  static {
    this.ɵfac = function ImageComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ImageComponent)(i0.ɵɵdirectiveInject(MapService), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ImageComponent,
      selectors: [["mgl-image"]],
      inputs: {
        id: "id",
        data: "data",
        options: "options",
        url: "url"
      },
      outputs: {
        imageError: "imageError",
        imageLoaded: "imageLoaded",
        error: "error",
        loaded: "loaded"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function ImageComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ImageComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-image',
      template: ''
    }]
  }], () => [{
    type: MapService
  }, {
    type: i0.NgZone
  }], {
    id: [{
      type: Input
    }],
    data: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    url: [{
      type: Input
    }],
    imageError: [{
      type: Output
    }],
    imageLoaded: [{
      type: Output
    }],
    error: [{
      type: Output
    }],
    loaded: [{
      type: Output
    }]
  });
})();
class MapComponent {
  get mapInstance() {
    return this.mapService.mapInstance;
  }
  constructor(mapService) {
    this.mapService = mapService;
    /* Added by ngx-mapbox-gl */
    this.movingMethod = 'flyTo';
    this.mapResize = new EventEmitter();
    this.mapRemove = new EventEmitter();
    this.mapMouseDown = new EventEmitter();
    this.mapMouseUp = new EventEmitter();
    this.mapMouseMove = new EventEmitter();
    this.mapClick = new EventEmitter();
    this.mapDblClick = new EventEmitter();
    this.mapMouseOver = new EventEmitter();
    this.mapMouseOut = new EventEmitter();
    this.mapContextMenu = new EventEmitter();
    this.mapTouchStart = new EventEmitter();
    this.mapTouchEnd = new EventEmitter();
    this.mapTouchMove = new EventEmitter();
    this.mapTouchCancel = new EventEmitter();
    this.mapWheel = new EventEmitter();
    this.moveStart = new EventEmitter();
    this.move = new EventEmitter();
    this.moveEnd = new EventEmitter();
    this.mapDragStart = new EventEmitter();
    this.mapDrag = new EventEmitter();
    this.mapDragEnd = new EventEmitter();
    this.zoomStart = new EventEmitter();
    this.zoomEvt = new EventEmitter();
    this.zoomEnd = new EventEmitter();
    this.rotateStart = new EventEmitter();
    this.rotate = new EventEmitter();
    this.rotateEnd = new EventEmitter();
    this.pitchStart = new EventEmitter();
    this.pitchEvt = new EventEmitter();
    this.pitchEnd = new EventEmitter();
    this.boxZoomStart = new EventEmitter();
    this.boxZoomEnd = new EventEmitter();
    this.boxZoomCancel = new EventEmitter();
    this.webGlContextLost = new EventEmitter();
    this.webGlContextRestored = new EventEmitter();
    this.mapLoad = new EventEmitter();
    this.mapCreate = new EventEmitter();
    this.idle = new EventEmitter();
    this.render = new EventEmitter();
    this.mapError = new EventEmitter();
    this.data = new EventEmitter();
    this.styleData = new EventEmitter();
    this.sourceData = new EventEmitter();
    this.dataLoading = new EventEmitter();
    this.styleDataLoading = new EventEmitter();
    this.sourceDataLoading = new EventEmitter();
    this.styleImageMissing = new EventEmitter();
    /**
     * @deprecated Use mapResize instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.resize = new EventEmitter();
    /**
     * @deprecated Use mapRemove instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.remove = new EventEmitter();
    /**
     * @deprecated Use mapMouseDown instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.mouseDown = new EventEmitter();
    /**
     * @deprecated Use mapMouseUp instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.mouseUp = new EventEmitter();
    /**
     * @deprecated Use mapMouseMove instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.mouseMove = new EventEmitter();
    /**
     * @deprecated Use mapClick instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.click = new EventEmitter();
    /**
     * @deprecated Use mapDblClick instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.dblClick = new EventEmitter();
    /**
     * @deprecated Use mapMouseOver instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.mouseOver = new EventEmitter();
    /**
     * @deprecated Use mapMouseOut instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.mouseOut = new EventEmitter();
    /**
     * @deprecated Use mapContextMenu instead
     */
    this.contextMenu = new EventEmitter();
    /**
     * @deprecated Use mapTouchStart instead
     */
    this.touchStart = new EventEmitter();
    /**
     * @deprecated Use mapTouchEnd instead
     */
    this.touchEnd = new EventEmitter();
    /**
     * @deprecated Use mapTouchMove instead
     */
    this.touchMove = new EventEmitter();
    /**
     * @deprecated Use mapTouchCancel instead
     */
    this.touchCancel = new EventEmitter();
    /**
     * @deprecated Use mapWheel instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.wheel = new EventEmitter();
    /**
     * @deprecated Use mapDragStart instead
     */
    this.dragStart = new EventEmitter();
    /**
     * @deprecated Use mapDrag instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.drag = new EventEmitter();
    /**
     * @deprecated Use mapDragEnd instead
     */
    this.dragEnd = new EventEmitter();
    /**
     * @deprecated Use mapLoad instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.load = new EventEmitter();
    /**
     * @deprecated Use mapError instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.error = new EventEmitter();
  }
  ngAfterViewInit() {
    this.warnDeprecatedOutputs();
    this.mapService.setup({
      accessToken: this.accessToken,
      customMapboxApiUrl: this.customMapboxApiUrl,
      mapOptions: {
        collectResourceTiming: this.collectResourceTiming,
        container: this.mapContainer.nativeElement,
        crossSourceCollisions: this.crossSourceCollisions,
        fadeDuration: this.fadeDuration,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
        minPitch: this.minPitch,
        maxPitch: this.maxPitch,
        style: this.style,
        hash: this.hash,
        interactive: this.interactive,
        bearingSnap: this.bearingSnap,
        pitchWithRotate: this.pitchWithRotate,
        clickTolerance: this.clickTolerance,
        attributionControl: this.attributionControl,
        logoPosition: this.logoPosition,
        failIfMajorPerformanceCaveat: this.failIfMajorPerformanceCaveat,
        preserveDrawingBuffer: this.preserveDrawingBuffer,
        refreshExpiredTiles: this.refreshExpiredTiles,
        maxBounds: this.maxBounds,
        scrollZoom: this.scrollZoom,
        boxZoom: this.boxZoom,
        dragRotate: this.dragRotate,
        dragPan: this.dragPan,
        keyboard: this.keyboard,
        doubleClickZoom: this.doubleClickZoom,
        touchPitch: this.touchPitch,
        touchZoomRotate: this.touchZoomRotate,
        trackResize: this.trackResize,
        center: this.center,
        zoom: this.zoom,
        bearing: this.bearing,
        pitch: this.pitch,
        renderWorldCopies: this.renderWorldCopies,
        maxTileCacheSize: this.maxTileCacheSize,
        localIdeographFontFamily: this.localIdeographFontFamily,
        transformRequest: this.transformRequest,
        bounds: this.bounds ? this.bounds : this.fitBounds,
        fitBoundsOptions: this.fitBoundsOptions,
        antialias: this.antialias,
        locale: this.locale,
        cooperativeGestures: this.cooperativeGestures,
        projection: this.projection
      },
      mapEvents: this
    });
    if (this.cursorStyle) {
      this.mapService.changeCanvasCursor(this.cursorStyle);
    }
  }
  ngOnDestroy() {
    this.mapService.destroyMap();
  }
  async ngOnChanges(changes) {
    await lastValueFrom(this.mapService.mapCreated$);
    if (changes['cursorStyle'] && !changes['cursorStyle'].isFirstChange()) {
      this.mapService.changeCanvasCursor(changes['cursorStyle'].currentValue);
    }
    if (changes['projection'] && !changes['projection'].isFirstChange()) {
      this.mapService.updateProjection(changes['projection'].currentValue);
    }
    if (changes['minZoom'] && !changes['minZoom'].isFirstChange()) {
      this.mapService.updateMinZoom(changes['minZoom'].currentValue);
    }
    if (changes['maxZoom'] && !changes['maxZoom'].isFirstChange()) {
      this.mapService.updateMaxZoom(changes['maxZoom'].currentValue);
    }
    if (changes['minPitch'] && !changes['minPitch'].isFirstChange()) {
      this.mapService.updateMinPitch(changes['minPitch'].currentValue);
    }
    if (changes['maxPitch'] && !changes['maxPitch'].isFirstChange()) {
      this.mapService.updateMaxPitch(changes['maxPitch'].currentValue);
    }
    if (changes['renderWorldCopies'] && !changes['renderWorldCopies'].isFirstChange()) {
      this.mapService.updateRenderWorldCopies(changes['renderWorldCopies'].currentValue);
    }
    if (changes['scrollZoom'] && !changes['scrollZoom'].isFirstChange()) {
      this.mapService.updateScrollZoom(changes['scrollZoom'].currentValue);
    }
    if (changes['dragRotate'] && !changes['dragRotate'].isFirstChange()) {
      this.mapService.updateDragRotate(changes['dragRotate'].currentValue);
    }
    if (changes['touchPitch'] && !changes['touchPitch'].isFirstChange()) {
      this.mapService.updateTouchPitch(changes['touchPitch'].currentValue);
    }
    if (changes['touchZoomRotate'] && !changes['touchZoomRotate'].isFirstChange()) {
      this.mapService.updateTouchZoomRotate(changes['touchZoomRotate'].currentValue);
    }
    if (changes['doubleClickZoom'] && !changes['doubleClickZoom'].isFirstChange()) {
      this.mapService.updateDoubleClickZoom(changes['doubleClickZoom'].currentValue);
    }
    if (changes['keyboard'] && !changes['keyboard'].isFirstChange()) {
      this.mapService.updateKeyboard(changes['keyboard'].currentValue);
    }
    if (changes['dragPan'] && !changes['dragPan'].isFirstChange()) {
      this.mapService.updateDragPan(changes['dragPan'].currentValue);
    }
    if (changes['boxZoom'] && !changes['boxZoom'].isFirstChange()) {
      this.mapService.updateBoxZoom(changes['boxZoom'].currentValue);
    }
    if (changes['style'] && !changes['style'].isFirstChange()) {
      this.mapService.updateStyle(changes['style'].currentValue);
    }
    if (changes['maxBounds'] && !changes['maxBounds'].isFirstChange()) {
      this.mapService.updateMaxBounds(changes['maxBounds'].currentValue);
    }
    if (changes['fitBounds'] && changes['fitBounds'].currentValue && !changes['fitBounds'].isFirstChange()) {
      this.mapService.fitBounds(changes['fitBounds'].currentValue, this.fitBoundsOptions);
    }
    if (changes['fitScreenCoordinates'] && changes['fitScreenCoordinates'].currentValue) {
      if ((this.center || this.zoom || this.pitch || this.fitBounds) && changes['fitScreenCoordinates'].isFirstChange()) {
        console.warn('[ngx-mapbox-gl] center / zoom / pitch / fitBounds inputs are being overridden by fitScreenCoordinates input');
      }
      this.mapService.fitScreenCoordinates(changes['fitScreenCoordinates'].currentValue, this.bearing ? this.bearing[0] : 0, this.movingOptions);
    }
    if (this.centerWithPanTo && changes['center'] && !changes['center'].isFirstChange() && !changes['zoom'] && !changes['bearing'] && !changes['pitch']) {
      this.mapService.panTo(this.center, this.panToOptions);
    } else if (changes['center'] && !changes['center'].isFirstChange() || changes['zoom'] && !changes['zoom'].isFirstChange() || changes['bearing'] && !changes['bearing'].isFirstChange() && !changes['fitScreenCoordinates'] || changes['pitch'] && !changes['pitch'].isFirstChange()) {
      this.mapService.move(this.movingMethod, this.movingOptions, changes['zoom'] && this.zoom ? this.zoom[0] : undefined, changes['center'] ? this.center : undefined, changes['bearing'] && this.bearing ? this.bearing[0] : undefined, changes['pitch'] && this.pitch ? this.pitch[0] : undefined);
    }
  }
  warnDeprecatedOutputs() {
    const dw = deprecationWarning.bind(undefined, MapComponent.name);
    if (this.resize.observed) {
      dw('resize', 'mapResize');
    }
    if (this.remove.observed) {
      dw('remove', 'mapRemove');
    }
    if (this.mouseDown.observed) {
      dw('mouseDown', 'mapMouseDown');
    }
    if (this.mouseUp.observed) {
      dw('mouseUp', 'mapMouseUp');
    }
    if (this.mouseMove.observed) {
      dw('mouseMove', 'mapMouseMove');
    }
    if (this.click.observed) {
      dw('click', 'mapClick');
    }
    if (this.dblClick.observed) {
      dw('dblClick', 'mapDblClick');
    }
    if (this.mouseOver.observed) {
      dw('mouseOver', 'mapMouseOver');
    }
    if (this.mouseOut.observed) {
      dw('mouseOut', 'mapMouseOut');
    }
    if (this.contextMenu.observed) {
      dw('contextMenu', 'mapContextMenu');
    }
    if (this.touchStart.observed) {
      dw('touchStart', 'mapTouchStart');
    }
    if (this.touchEnd.observed) {
      dw('touchEnd', 'mapTouchEnd');
    }
    if (this.touchMove.observed) {
      dw('touchMove', 'mapTouchMove');
    }
    if (this.touchCancel.observed) {
      dw('touchCancel', 'mapTouchCancel');
    }
    if (this.wheel.observed) {
      dw('wheel', 'mapWheel');
    }
    if (this.dragStart.observed) {
      dw('dragStart', 'mapDragStart');
    }
    if (this.drag.observed) {
      dw('drag', 'mapDrag');
    }
    if (this.dragEnd.observed) {
      dw('dragEnd', 'mapDragEnd');
    }
    if (this.load.observed) {
      dw('load', 'mapLoad');
    }
    if (this.error.observed) {
      dw('error', 'mapError');
    }
  }
  static {
    this.ɵfac = function MapComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MapComponent)(i0.ɵɵdirectiveInject(MapService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MapComponent,
      selectors: [["mgl-map"]],
      viewQuery: function MapComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c2, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.mapContainer = _t.first);
        }
      },
      inputs: {
        accessToken: "accessToken",
        collectResourceTiming: "collectResourceTiming",
        crossSourceCollisions: "crossSourceCollisions",
        customMapboxApiUrl: "customMapboxApiUrl",
        fadeDuration: "fadeDuration",
        hash: "hash",
        refreshExpiredTiles: "refreshExpiredTiles",
        failIfMajorPerformanceCaveat: "failIfMajorPerformanceCaveat",
        bearingSnap: "bearingSnap",
        interactive: "interactive",
        pitchWithRotate: "pitchWithRotate",
        clickTolerance: "clickTolerance",
        attributionControl: "attributionControl",
        logoPosition: "logoPosition",
        maxTileCacheSize: "maxTileCacheSize",
        localIdeographFontFamily: "localIdeographFontFamily",
        preserveDrawingBuffer: "preserveDrawingBuffer",
        trackResize: "trackResize",
        transformRequest: "transformRequest",
        bounds: "bounds",
        antialias: "antialias",
        locale: "locale",
        cooperativeGestures: "cooperativeGestures",
        minZoom: "minZoom",
        maxZoom: "maxZoom",
        minPitch: "minPitch",
        maxPitch: "maxPitch",
        scrollZoom: "scrollZoom",
        dragRotate: "dragRotate",
        touchPitch: "touchPitch",
        touchZoomRotate: "touchZoomRotate",
        doubleClickZoom: "doubleClickZoom",
        keyboard: "keyboard",
        dragPan: "dragPan",
        boxZoom: "boxZoom",
        style: "style",
        center: "center",
        maxBounds: "maxBounds",
        zoom: "zoom",
        bearing: "bearing",
        pitch: "pitch",
        fitBoundsOptions: "fitBoundsOptions",
        renderWorldCopies: "renderWorldCopies",
        projection: "projection",
        movingMethod: "movingMethod",
        movingOptions: "movingOptions",
        fitBounds: "fitBounds",
        fitScreenCoordinates: "fitScreenCoordinates",
        centerWithPanTo: "centerWithPanTo",
        panToOptions: "panToOptions",
        cursorStyle: "cursorStyle"
      },
      outputs: {
        mapResize: "mapResize",
        mapRemove: "mapRemove",
        mapMouseDown: "mapMouseDown",
        mapMouseUp: "mapMouseUp",
        mapMouseMove: "mapMouseMove",
        mapClick: "mapClick",
        mapDblClick: "mapDblClick",
        mapMouseOver: "mapMouseOver",
        mapMouseOut: "mapMouseOut",
        mapContextMenu: "mapContextMenu",
        mapTouchStart: "mapTouchStart",
        mapTouchEnd: "mapTouchEnd",
        mapTouchMove: "mapTouchMove",
        mapTouchCancel: "mapTouchCancel",
        mapWheel: "mapWheel",
        moveStart: "moveStart",
        move: "move",
        moveEnd: "moveEnd",
        mapDragStart: "mapDragStart",
        mapDrag: "mapDrag",
        mapDragEnd: "mapDragEnd",
        zoomStart: "zoomStart",
        zoomEvt: "zoomEvt",
        zoomEnd: "zoomEnd",
        rotateStart: "rotateStart",
        rotate: "rotate",
        rotateEnd: "rotateEnd",
        pitchStart: "pitchStart",
        pitchEvt: "pitchEvt",
        pitchEnd: "pitchEnd",
        boxZoomStart: "boxZoomStart",
        boxZoomEnd: "boxZoomEnd",
        boxZoomCancel: "boxZoomCancel",
        webGlContextLost: "webGlContextLost",
        webGlContextRestored: "webGlContextRestored",
        mapLoad: "mapLoad",
        mapCreate: "mapCreate",
        idle: "idle",
        render: "render",
        mapError: "mapError",
        data: "data",
        styleData: "styleData",
        sourceData: "sourceData",
        dataLoading: "dataLoading",
        styleDataLoading: "styleDataLoading",
        sourceDataLoading: "sourceDataLoading",
        styleImageMissing: "styleImageMissing",
        resize: "resize",
        remove: "remove",
        mouseDown: "mouseDown",
        mouseUp: "mouseUp",
        mouseMove: "mouseMove",
        click: "click",
        dblClick: "dblClick",
        mouseOver: "mouseOver",
        mouseOut: "mouseOut",
        contextMenu: "contextMenu",
        touchStart: "touchStart",
        touchEnd: "touchEnd",
        touchMove: "touchMove",
        touchCancel: "touchCancel",
        wheel: "wheel",
        dragStart: "dragStart",
        drag: "drag",
        dragEnd: "dragEnd",
        load: "load",
        error: "error"
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([MapService]), i0.ɵɵNgOnChangesFeature],
      decls: 2,
      vars: 0,
      consts: [["container", ""]],
      template: function MapComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", null, 0);
        }
      },
      styles: ["[_nghost-%COMP%]{display:block}div[_ngcontent-%COMP%]{height:100%;width:100%}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MapComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-map',
      template: '<div #container></div>',
      providers: [MapService],
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [":host{display:block}div{height:100%;width:100%}\n"]
    }]
  }], () => [{
    type: MapService
  }], {
    accessToken: [{
      type: Input
    }],
    collectResourceTiming: [{
      type: Input
    }],
    crossSourceCollisions: [{
      type: Input
    }],
    customMapboxApiUrl: [{
      type: Input
    }],
    fadeDuration: [{
      type: Input
    }],
    hash: [{
      type: Input
    }],
    refreshExpiredTiles: [{
      type: Input
    }],
    failIfMajorPerformanceCaveat: [{
      type: Input
    }],
    bearingSnap: [{
      type: Input
    }],
    interactive: [{
      type: Input
    }],
    pitchWithRotate: [{
      type: Input
    }],
    clickTolerance: [{
      type: Input
    }],
    attributionControl: [{
      type: Input
    }],
    logoPosition: [{
      type: Input
    }],
    maxTileCacheSize: [{
      type: Input
    }],
    localIdeographFontFamily: [{
      type: Input
    }],
    preserveDrawingBuffer: [{
      type: Input
    }],
    trackResize: [{
      type: Input
    }],
    transformRequest: [{
      type: Input
    }],
    bounds: [{
      type: Input
    }],
    antialias: [{
      type: Input
    }],
    locale: [{
      type: Input
    }],
    cooperativeGestures: [{
      type: Input
    }],
    minZoom: [{
      type: Input
    }],
    maxZoom: [{
      type: Input
    }],
    minPitch: [{
      type: Input
    }],
    maxPitch: [{
      type: Input
    }],
    scrollZoom: [{
      type: Input
    }],
    dragRotate: [{
      type: Input
    }],
    touchPitch: [{
      type: Input
    }],
    touchZoomRotate: [{
      type: Input
    }],
    doubleClickZoom: [{
      type: Input
    }],
    keyboard: [{
      type: Input
    }],
    dragPan: [{
      type: Input
    }],
    boxZoom: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    center: [{
      type: Input
    }],
    maxBounds: [{
      type: Input
    }],
    zoom: [{
      type: Input
    }],
    bearing: [{
      type: Input
    }],
    pitch: [{
      type: Input
    }],
    fitBoundsOptions: [{
      type: Input
    }],
    renderWorldCopies: [{
      type: Input
    }],
    projection: [{
      type: Input
    }],
    movingMethod: [{
      type: Input
    }],
    movingOptions: [{
      type: Input
    }],
    fitBounds: [{
      type: Input
    }],
    fitScreenCoordinates: [{
      type: Input
    }],
    centerWithPanTo: [{
      type: Input
    }],
    panToOptions: [{
      type: Input
    }],
    cursorStyle: [{
      type: Input
    }],
    mapResize: [{
      type: Output
    }],
    mapRemove: [{
      type: Output
    }],
    mapMouseDown: [{
      type: Output
    }],
    mapMouseUp: [{
      type: Output
    }],
    mapMouseMove: [{
      type: Output
    }],
    mapClick: [{
      type: Output
    }],
    mapDblClick: [{
      type: Output
    }],
    mapMouseOver: [{
      type: Output
    }],
    mapMouseOut: [{
      type: Output
    }],
    mapContextMenu: [{
      type: Output
    }],
    mapTouchStart: [{
      type: Output
    }],
    mapTouchEnd: [{
      type: Output
    }],
    mapTouchMove: [{
      type: Output
    }],
    mapTouchCancel: [{
      type: Output
    }],
    mapWheel: [{
      type: Output
    }],
    moveStart: [{
      type: Output
    }],
    move: [{
      type: Output
    }],
    moveEnd: [{
      type: Output
    }],
    mapDragStart: [{
      type: Output
    }],
    mapDrag: [{
      type: Output
    }],
    mapDragEnd: [{
      type: Output
    }],
    zoomStart: [{
      type: Output
    }],
    zoomEvt: [{
      type: Output
    }],
    zoomEnd: [{
      type: Output
    }],
    rotateStart: [{
      type: Output
    }],
    rotate: [{
      type: Output
    }],
    rotateEnd: [{
      type: Output
    }],
    pitchStart: [{
      type: Output
    }],
    pitchEvt: [{
      type: Output
    }],
    pitchEnd: [{
      type: Output
    }],
    boxZoomStart: [{
      type: Output
    }],
    boxZoomEnd: [{
      type: Output
    }],
    boxZoomCancel: [{
      type: Output
    }],
    webGlContextLost: [{
      type: Output
    }],
    webGlContextRestored: [{
      type: Output
    }],
    mapLoad: [{
      type: Output
    }],
    mapCreate: [{
      type: Output
    }],
    idle: [{
      type: Output
    }],
    render: [{
      type: Output
    }],
    mapError: [{
      type: Output
    }],
    data: [{
      type: Output
    }],
    styleData: [{
      type: Output
    }],
    sourceData: [{
      type: Output
    }],
    dataLoading: [{
      type: Output
    }],
    styleDataLoading: [{
      type: Output
    }],
    sourceDataLoading: [{
      type: Output
    }],
    styleImageMissing: [{
      type: Output
    }],
    resize: [{
      type: Output
    }],
    remove: [{
      type: Output
    }],
    mouseDown: [{
      type: Output
    }],
    mouseUp: [{
      type: Output
    }],
    mouseMove: [{
      type: Output
    }],
    click: [{
      type: Output
    }],
    dblClick: [{
      type: Output
    }],
    mouseOver: [{
      type: Output
    }],
    mouseOut: [{
      type: Output
    }],
    contextMenu: [{
      type: Output
    }],
    touchStart: [{
      type: Output
    }],
    touchEnd: [{
      type: Output
    }],
    touchMove: [{
      type: Output
    }],
    touchCancel: [{
      type: Output
    }],
    wheel: [{
      type: Output
    }],
    dragStart: [{
      type: Output
    }],
    drag: [{
      type: Output
    }],
    dragEnd: [{
      type: Output
    }],
    load: [{
      type: Output
    }],
    error: [{
      type: Output
    }],
    mapContainer: [{
      type: ViewChild,
      args: ['container', {
        static: true
      }]
    }]
  });
})();
class MarkerComponent {
  constructor(mapService) {
    this.mapService = mapService;
    this.markerDragStart = new EventEmitter();
    this.markerDragEnd = new EventEmitter();
    this.markerDrag = new EventEmitter();
    /**
     * @deprecated Use markerDragStart instead
     */
    this.dragStart = new EventEmitter();
    /**
     * @deprecated Use markerDragEnd instead
     */
    this.dragEnd = new EventEmitter();
    /**
     * @deprecated Use markerDrag instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.drag = new EventEmitter();
  }
  ngOnInit() {
    this.warnDeprecatedOutputs();
    if (this.feature && this.lngLat) {
      throw new Error('feature and lngLat input are mutually exclusive');
    }
  }
  ngOnChanges(changes) {
    if (changes['lngLat'] && !changes['lngLat'].isFirstChange()) {
      this.markerInstance.setLngLat(this.lngLat);
    }
    if (changes['feature'] && !changes['feature'].isFirstChange()) {
      this.markerInstance.setLngLat(this.feature.geometry.coordinates);
    }
    if (changes['draggable'] && !changes['draggable'].isFirstChange()) {
      this.markerInstance.setDraggable(!!this.draggable);
    }
    if (changes['popupShown'] && !changes['popupShown'].isFirstChange()) {
      changes['popupShown'].currentValue ? this.markerInstance.getPopup().addTo(this.mapService.mapInstance) : this.markerInstance.getPopup().remove();
    }
    if (changes['pitchAlignment'] && !changes['pitchAlignment'].isFirstChange()) {
      this.markerInstance.setPitchAlignment(changes['pitchAlignment'].currentValue);
    }
    if (changes['rotationAlignment'] && !changes['rotationAlignment'].isFirstChange()) {
      this.markerInstance.setRotationAlignment(changes['rotationAlignment'].currentValue);
    }
  }
  ngAfterViewInit() {
    this.mapService.mapCreated$.subscribe(() => {
      this.markerInstance = this.mapService.addMarker({
        markersOptions: {
          offset: this.offset,
          anchor: this.anchor,
          pitchAlignment: this.pitchAlignment,
          rotationAlignment: this.rotationAlignment,
          draggable: !!this.draggable,
          element: this.content.nativeElement,
          feature: this.feature,
          lngLat: this.lngLat,
          clickTolerance: this.clickTolerance
        },
        markersEvents: {
          markerDragStart: this.markerDragStart,
          markerDrag: this.markerDrag,
          markerDragEnd: this.markerDragEnd,
          dragStart: this.markerDragStart,
          drag: this.markerDrag,
          dragEnd: this.markerDragEnd
        }
      });
    });
  }
  ngOnDestroy() {
    this.mapService.removeMarker(this.markerInstance);
    this.markerInstance = undefined;
  }
  togglePopup() {
    this.markerInstance.togglePopup();
  }
  updateCoordinates(coordinates) {
    this.markerInstance.setLngLat(coordinates);
  }
  warnDeprecatedOutputs() {
    const dw = deprecationWarning.bind(undefined, MarkerComponent.name);
    if (this.markerDragStart.observed) {
      dw('dragStart', 'markerDragStart');
    }
    if (this.markerDragEnd.observed) {
      dw('dragEnd', 'markerDragEnd');
    }
    if (this.markerDrag.observed) {
      dw('drag', 'markerDrag');
    }
  }
  static {
    this.ɵfac = function MarkerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MarkerComponent)(i0.ɵɵdirectiveInject(MapService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MarkerComponent,
      selectors: [["mgl-marker"]],
      viewQuery: function MarkerComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
        }
      },
      inputs: {
        offset: "offset",
        anchor: "anchor",
        clickTolerance: "clickTolerance",
        feature: "feature",
        lngLat: "lngLat",
        draggable: "draggable",
        popupShown: "popupShown",
        className: "className",
        pitchAlignment: "pitchAlignment",
        rotationAlignment: "rotationAlignment"
      },
      outputs: {
        markerDragStart: "markerDragStart",
        markerDragEnd: "markerDragEnd",
        markerDrag: "markerDrag",
        dragStart: "dragStart",
        dragEnd: "dragEnd",
        drag: "drag"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature],
      ngContentSelectors: _c1,
      decls: 3,
      vars: 2,
      consts: [["content", ""]],
      template: function MarkerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", null, 0);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.className);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkerComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-marker',
      template: '<div [class]="className" #content><ng-content></ng-content></div>',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: MapService
  }], {
    offset: [{
      type: Input
    }],
    anchor: [{
      type: Input
    }],
    clickTolerance: [{
      type: Input
    }],
    feature: [{
      type: Input
    }],
    lngLat: [{
      type: Input
    }],
    draggable: [{
      type: Input
    }],
    popupShown: [{
      type: Input
    }],
    className: [{
      type: Input
    }],
    pitchAlignment: [{
      type: Input
    }],
    rotationAlignment: [{
      type: Input
    }],
    markerDragStart: [{
      type: Output
    }],
    markerDragEnd: [{
      type: Output
    }],
    markerDrag: [{
      type: Output
    }],
    dragStart: [{
      type: Output
    }],
    dragEnd: [{
      type: Output
    }],
    drag: [{
      type: Output
    }],
    content: [{
      type: ViewChild,
      args: ['content', {
        static: true
      }]
    }]
  });
})();
class PointDirective {
  static {
    this.ɵfac = function PointDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PointDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PointDirective,
      selectors: [["ng-template", "mglPoint", ""]],
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PointDirective, [{
    type: Directive,
    args: [{
      selector: 'ng-template[mglPoint]'
    }]
  }], null, null);
})();
class ClusterPointDirective {
  static {
    this.ɵfac = function ClusterPointDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ClusterPointDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ClusterPointDirective,
      selectors: [["ng-template", "mglClusterPoint", ""]],
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClusterPointDirective, [{
    type: Directive,
    args: [{
      selector: 'ng-template[mglClusterPoint]'
    }]
  }], null, null);
})();
let uniqId = 0;
class MarkersForClustersComponent {
  constructor(mapService, ChangeDetectorRef, zone) {
    this.mapService = mapService;
    this.ChangeDetectorRef = ChangeDetectorRef;
    this.zone = zone;
    this.layerId = `mgl-markers-for-clusters-${uniqId++}`;
    this.sub = new Subscription();
  }
  ngAfterContentInit() {
    const clusterDataUpdate = () => fromEvent(this.mapService.mapInstance, 'data').pipe(filter(e => e.sourceId === this.source && e.sourceDataType !== 'metadata' && this.mapService.mapInstance.isSourceLoaded(this.source)));
    const sub = this.mapService.mapCreated$.pipe(switchMap(clusterDataUpdate), switchMap(() => merge(fromEvent(this.mapService.mapInstance, 'move'), fromEvent(this.mapService.mapInstance, 'moveend')).pipe(startWith(undefined)))).subscribe(() => {
      this.zone.run(() => {
        this.updateCluster();
      });
    });
    this.sub.add(sub);
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  trackByClusterPoint(_index, clusterPoint) {
    return clusterPoint.id;
  }
  updateCluster() {
    // Invalid queryRenderedFeatures typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params = {
      layers: [this.layerId]
    };
    if (!this.pointTpl) {
      params.filter = ['==', 'cluster', true];
    }
    this.clusterPoints = this.mapService.mapInstance.queryRenderedFeatures(params);
    this.ChangeDetectorRef.markForCheck();
  }
  static {
    this.ɵfac = function MarkersForClustersComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MarkersForClustersComponent)(i0.ɵɵdirectiveInject(MapService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MarkersForClustersComponent,
      selectors: [["mgl-markers-for-clusters"]],
      contentQueries: function MarkersForClustersComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, PointDirective, 5, TemplateRef);
          i0.ɵɵcontentQuery(dirIndex, ClusterPointDirective, 5, TemplateRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.pointTpl = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.clusterPointTpl = _t.first);
        }
      },
      inputs: {
        source: "source"
      },
      standalone: false,
      decls: 2,
      vars: 6,
      consts: [["type", "circle", 3, "id", "source", "paint"], [4, "ngFor", "ngForOf", "ngForTrackBy"], [4, "ngIf"], [3, "feature"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"]],
      template: function MarkersForClustersComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "mgl-layer", 0);
          i0.ɵɵtemplate(1, MarkersForClustersComponent_ng_container_1_Template, 3, 2, "ng-container", 1);
        }
        if (rf & 2) {
          i0.ɵɵproperty("id", ctx.layerId)("source", ctx.source)("paint", i0.ɵɵpureFunction0(5, _c3));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.clusterPoints)("ngForTrackBy", ctx.trackByClusterPoint);
        }
      },
      dependencies: [i2.NgForOf, i2.NgIf, i2.NgTemplateOutlet, LayerComponent, MarkerComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkersForClustersComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-markers-for-clusters',
      template: `
    <mgl-layer
      [id]="layerId"
      [source]="source"
      type="circle"
      [paint]="{ 'circle-radius': 0 }"
    ></mgl-layer>
    <ng-container
      *ngFor="let feature of clusterPoints; trackBy: trackByClusterPoint"
    >
      <ng-container *ngIf="feature.properties!['cluster']">
        <mgl-marker [feature]="$any(feature)">
          <ng-container
            *ngTemplateOutlet="clusterPointTpl; context: { $implicit: feature }"
          ></ng-container>
        </mgl-marker>
      </ng-container>
      <ng-container *ngIf="!feature.properties!['cluster']">
        <mgl-marker [feature]="$any(feature)">
          <ng-container
            *ngTemplateOutlet="pointTpl!; context: { $implicit: feature }"
          ></ng-container>
        </mgl-marker>
      </ng-container>
    </ng-container>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      preserveWhitespaces: false
    }]
  }], () => [{
    type: MapService
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i0.NgZone
  }], {
    source: [{
      type: Input
    }],
    pointTpl: [{
      type: ContentChild,
      args: [PointDirective, {
        read: TemplateRef,
        static: false
      }]
    }],
    clusterPointTpl: [{
      type: ContentChild,
      args: [ClusterPointDirective, {
        read: TemplateRef,
        static: false
      }]
    }]
  });
})();
class PopupComponent {
  constructor(mapService) {
    this.mapService = mapService;
    this.popupClose = new EventEmitter();
    this.popupOpen = new EventEmitter();
    /**
     * @deprecated Use popupClose instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.close = new EventEmitter();
    /**
     * @deprecated Use popupOpen instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.open = new EventEmitter();
  }
  ngOnInit() {
    this.warnDeprecatedOutputs();
    if (this.lngLat && this.marker || this.feature && this.lngLat || this.feature && this.marker) {
      throw new Error('marker, lngLat, feature input are mutually exclusive');
    }
  }
  ngOnChanges(changes) {
    if (changes['lngLat'] && !changes['lngLat'].isFirstChange() || changes['feature'] && !changes['feature'].isFirstChange()) {
      const newlngLat = changes['lngLat'] ? this.lngLat : this.feature.geometry.coordinates;
      this.mapService.removePopupFromMap(this.popupInstance, true);
      const popupInstanceTmp = this.createPopup();
      this.mapService.addPopupToMap(popupInstanceTmp, newlngLat, this.popupInstance.isOpen());
      this.popupInstance = popupInstanceTmp;
    }
    if (changes['marker'] && !changes['marker'].isFirstChange()) {
      const previousMarker = changes['marker'].previousValue;
      if (previousMarker.markerInstance) {
        this.mapService.removePopupFromMarker(previousMarker.markerInstance);
      }
      if (this.marker && this.marker.markerInstance && this.popupInstance) {
        this.mapService.addPopupToMarker(this.marker.markerInstance, this.popupInstance);
      }
    }
    if (changes['offset'] && !changes['offset'].isFirstChange() && this.popupInstance) {
      this.popupInstance.setOffset(this.offset);
    }
  }
  ngAfterViewInit() {
    this.popupInstance = this.createPopup();
    this.addPopup(this.popupInstance);
  }
  ngOnDestroy() {
    if (this.popupInstance) {
      if (this.lngLat || this.feature) {
        this.mapService.removePopupFromMap(this.popupInstance);
      } else if (this.marker && this.marker.markerInstance) {
        this.mapService.removePopupFromMarker(this.marker.markerInstance);
      }
    }
    this.popupInstance = undefined;
  }
  createPopup() {
    return this.mapService.createPopup({
      popupOptions: {
        closeButton: this.closeButton,
        closeOnClick: this.closeOnClick,
        closeOnMove: this.closeOnMove,
        focusAfterOpen: this.focusAfterOpen,
        anchor: this.anchor,
        offset: this.offset,
        className: this.className,
        maxWidth: this.maxWidth
      },
      popupEvents: {
        open: this.open,
        close: this.close,
        popupOpen: this.popupOpen,
        popupClose: this.popupClose
      }
    }, this.content.nativeElement);
  }
  addPopup(popup) {
    this.mapService.mapCreated$.subscribe(() => {
      if (this.lngLat || this.feature) {
        this.mapService.addPopupToMap(popup, this.lngLat ? this.lngLat : this.feature.geometry.coordinates);
      } else if (this.marker && this.marker.markerInstance) {
        this.mapService.addPopupToMarker(this.marker.markerInstance, popup);
      } else {
        throw new Error('mgl-popup need either lngLat/marker/feature to be set');
      }
    });
  }
  warnDeprecatedOutputs() {
    const dw = deprecationWarning.bind(undefined, PopupComponent.name);
    if (this.close.observed) {
      dw('close', 'popupClose');
    }
    if (this.open.observed) {
      dw('open', 'popupOpen');
    }
  }
  static {
    this.ɵfac = function PopupComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PopupComponent)(i0.ɵɵdirectiveInject(MapService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PopupComponent,
      selectors: [["mgl-popup"]],
      viewQuery: function PopupComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
        }
      },
      inputs: {
        closeButton: "closeButton",
        closeOnClick: "closeOnClick",
        closeOnMove: "closeOnMove",
        focusAfterOpen: "focusAfterOpen",
        anchor: "anchor",
        className: "className",
        maxWidth: "maxWidth",
        feature: "feature",
        lngLat: "lngLat",
        marker: "marker",
        offset: "offset"
      },
      outputs: {
        popupClose: "popupClose",
        popupOpen: "popupOpen",
        close: "close",
        open: "open"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature],
      ngContentSelectors: _c1,
      decls: 3,
      vars: 0,
      consts: [["content", ""]],
      template: function PopupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", null, 0);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopupComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-popup',
      template: '<div #content><ng-content></ng-content></div>',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: MapService
  }], {
    closeButton: [{
      type: Input
    }],
    closeOnClick: [{
      type: Input
    }],
    closeOnMove: [{
      type: Input
    }],
    focusAfterOpen: [{
      type: Input
    }],
    anchor: [{
      type: Input
    }],
    className: [{
      type: Input
    }],
    maxWidth: [{
      type: Input
    }],
    feature: [{
      type: Input
    }],
    lngLat: [{
      type: Input
    }],
    marker: [{
      type: Input
    }],
    offset: [{
      type: Input
    }],
    popupClose: [{
      type: Output
    }],
    popupOpen: [{
      type: Output
    }],
    close: [{
      type: Output
    }],
    open: [{
      type: Output
    }],
    content: [{
      type: ViewChild,
      args: ['content', {
        static: true
      }]
    }]
  });
})();
class CanvasSourceComponent {
  constructor(mapService) {
    this.mapService = mapService;
    this.sourceAdded = false;
    this.sub = new Subscription();
  }
  ngOnInit() {
    const sub1 = this.mapService.mapLoaded$.subscribe(() => {
      this.init();
      const sub = fromEvent(this.mapService.mapInstance, 'styledata').pipe(filter(() => !this.mapService.mapInstance.getSource(this.id))).subscribe(() => {
        this.init();
      });
      this.sub.add(sub);
    });
    this.sub.add(sub1);
  }
  ngOnChanges(changes) {
    if (!this.sourceAdded) {
      return;
    }
    if (changes['canvas'] && !changes['canvas'].isFirstChange() || changes['animate'] && !changes['animate'].isFirstChange()) {
      this.ngOnDestroy();
      this.ngOnInit();
    } else if (changes['coordinates'] && !changes['coordinates'].isFirstChange()) {
      const source = this.mapService.getSource(this.id);
      if (source === undefined) {
        return;
      }
      source.setCoordinates(this.coordinates);
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.sourceAdded) {
      this.mapService.removeSource(this.id);
      this.sourceAdded = false;
    }
  }
  init() {
    const source = {
      type: 'canvas',
      coordinates: this.coordinates,
      canvas: this.canvas,
      animate: this.animate
    };
    this.mapService.addSource(this.id, source);
    this.sourceAdded = true;
  }
  static {
    this.ɵfac = function CanvasSourceComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CanvasSourceComponent)(i0.ɵɵdirectiveInject(MapService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CanvasSourceComponent,
      selectors: [["mgl-canvas-source"]],
      inputs: {
        id: "id",
        coordinates: "coordinates",
        canvas: "canvas",
        animate: "animate"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function CanvasSourceComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CanvasSourceComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-canvas-source',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: MapService
  }], {
    id: [{
      type: Input
    }],
    coordinates: [{
      type: Input
    }],
    canvas: [{
      type: Input
    }],
    animate: [{
      type: Input
    }]
  });
})();
class ImageSourceComponent {
  constructor(mapService) {
    this.mapService = mapService;
  }
  ngOnInit() {
    this.sub = this.mapService.mapLoaded$.subscribe(() => this.init());
  }
  ngOnChanges(changes) {
    if (this.sourceId === undefined) {
      return;
    }
    const source = this.mapService.getSource(this.sourceId);
    if (source === undefined) {
      return;
    }
    source.updateImage({
      url: changes['url'] === undefined ? undefined : this.url,
      coordinates: changes['coordinates'] === undefined ? undefined : this.coordinates
    });
  }
  ngOnDestroy() {
    if (this.sub !== undefined) {
      this.sub.unsubscribe();
    }
    if (this.sourceId !== undefined) {
      this.mapService.removeSource(this.sourceId);
      this.sourceId = undefined;
    }
  }
  init() {
    const imageSource = {
      type: 'image',
      url: this.url,
      coordinates: this.coordinates
    };
    this.mapService.addSource(this.id, imageSource);
    this.sourceId = this.id;
  }
  static {
    this.ɵfac = function ImageSourceComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ImageSourceComponent)(i0.ɵɵdirectiveInject(MapService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ImageSourceComponent,
      selectors: [["mgl-image-source"]],
      inputs: {
        id: "id",
        url: "url",
        coordinates: "coordinates"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function ImageSourceComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ImageSourceComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-image-source',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: MapService
  }], {
    id: [{
      type: Input
    }],
    url: [{
      type: Input
    }],
    coordinates: [{
      type: Input
    }]
  });
})();
class RasterDemSourceComponent {
  constructor(mapService) {
    this.mapService = mapService;
    this.type = 'raster-dem';
    this.sourceAdded = false;
    this.sub = new Subscription();
  }
  ngOnInit() {
    const sub1 = this.mapService.mapLoaded$.subscribe(() => {
      this.init();
      const sub = fromEvent(this.mapService.mapInstance, 'styledata').pipe(filter(() => !this.mapService.mapInstance.getSource(this.id))).subscribe(() => {
        this.init();
      });
      this.sub.add(sub);
    });
    this.sub.add(sub1);
  }
  ngOnChanges(changes) {
    if (!this.sourceAdded) {
      return;
    }
    if (changes['url'] && !changes['url'].isFirstChange() || changes['tiles'] && !changes['tiles'].isFirstChange() || changes['bounds'] && !changes['bounds'].isFirstChange() || changes['minzoom'] && !changes['minzoom'].isFirstChange() || changes['maxzoom'] && !changes['maxzoom'].isFirstChange() || changes['tileSize'] && !changes['tileSize'].isFirstChange() || changes['attribution'] && !changes['attribution'].isFirstChange() || changes['encoding'] && !changes['encoding'].isFirstChange()) {
      this.ngOnDestroy();
      this.ngOnInit();
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.sourceAdded) {
      this.mapService.removeSource(this.id);
      this.sourceAdded = false;
    }
  }
  init() {
    const source = {
      type: this.type,
      url: this.url,
      tiles: this.tiles,
      bounds: this.bounds,
      minzoom: this.minzoom,
      maxzoom: this.maxzoom,
      tileSize: this.tileSize,
      attribution: this.attribution,
      encoding: this.encoding
    };
    this.mapService.addSource(this.id, source);
    this.sourceAdded = true;
  }
  static {
    this.ɵfac = function RasterDemSourceComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || RasterDemSourceComponent)(i0.ɵɵdirectiveInject(MapService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: RasterDemSourceComponent,
      selectors: [["mgl-raster-dem-source"]],
      inputs: {
        id: "id",
        url: "url",
        tiles: "tiles",
        bounds: "bounds",
        minzoom: "minzoom",
        maxzoom: "maxzoom",
        tileSize: "tileSize",
        attribution: "attribution",
        encoding: "encoding"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function RasterDemSourceComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RasterDemSourceComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-raster-dem-source',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: MapService
  }], {
    id: [{
      type: Input
    }],
    url: [{
      type: Input
    }],
    tiles: [{
      type: Input
    }],
    bounds: [{
      type: Input
    }],
    minzoom: [{
      type: Input
    }],
    maxzoom: [{
      type: Input
    }],
    tileSize: [{
      type: Input
    }],
    attribution: [{
      type: Input
    }],
    encoding: [{
      type: Input
    }]
  });
})();
class RasterSourceComponent {
  constructor(mapService) {
    this.mapService = mapService;
    this.type = 'raster';
    this.sourceAdded = false;
    this.sub = new Subscription();
  }
  ngOnInit() {
    const sub1 = this.mapService.mapLoaded$.subscribe(() => {
      this.init();
      const sub = fromEvent(this.mapService.mapInstance, 'styledata').pipe(filter(() => !this.mapService.mapInstance.getSource(this.id))).subscribe(() => {
        this.init();
      });
      this.sub.add(sub);
    });
    this.sub.add(sub1);
  }
  ngOnChanges(changes) {
    if (!this.sourceAdded) {
      return;
    }
    if (changes['url'] && !changes['url'].isFirstChange() || changes['tiles'] && !changes['tiles'].isFirstChange() || changes['bounds'] && !changes['bounds'].isFirstChange() || changes['minzoom'] && !changes['minzoom'].isFirstChange() || changes['maxzoom'] && !changes['maxzoom'].isFirstChange() || changes['tileSize'] && !changes['tileSize'].isFirstChange() || changes['scheme'] && !changes['scheme'].isFirstChange() || changes['attribution'] && !changes['attribution'].isFirstChange()) {
      this.ngOnDestroy();
      this.ngOnInit();
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.sourceAdded) {
      this.mapService.removeSource(this.id);
      this.sourceAdded = false;
    }
  }
  init() {
    const source = {
      type: this.type,
      url: this.url,
      tiles: this.tiles,
      bounds: this.bounds,
      minzoom: this.minzoom,
      maxzoom: this.maxzoom,
      tileSize: this.tileSize,
      scheme: this.scheme,
      attribution: this.attribution
    };
    this.mapService.addSource(this.id, source);
    this.sourceAdded = true;
  }
  static {
    this.ɵfac = function RasterSourceComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || RasterSourceComponent)(i0.ɵɵdirectiveInject(MapService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: RasterSourceComponent,
      selectors: [["mgl-raster-source"]],
      inputs: {
        id: "id",
        url: "url",
        tiles: "tiles",
        bounds: "bounds",
        minzoom: "minzoom",
        maxzoom: "maxzoom",
        tileSize: "tileSize",
        scheme: "scheme",
        attribution: "attribution"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function RasterSourceComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RasterSourceComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-raster-source',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: MapService
  }], {
    id: [{
      type: Input
    }],
    url: [{
      type: Input
    }],
    tiles: [{
      type: Input
    }],
    bounds: [{
      type: Input
    }],
    minzoom: [{
      type: Input
    }],
    maxzoom: [{
      type: Input
    }],
    tileSize: [{
      type: Input
    }],
    scheme: [{
      type: Input
    }],
    attribution: [{
      type: Input
    }]
  });
})();
class VectorSourceComponent {
  constructor(mapService) {
    this.mapService = mapService;
    this.type = 'vector';
    this.sourceAdded = false;
    this.sub = new Subscription();
  }
  ngOnInit() {
    const sub1 = this.mapService.mapLoaded$.subscribe(() => {
      this.init();
      const sub = fromEvent(this.mapService.mapInstance, 'styledata').pipe(filter(() => !this.mapService.mapInstance.getSource(this.id))).subscribe(() => {
        this.init();
      });
      this.sub.add(sub);
    });
    this.sub.add(sub1);
  }
  ngOnChanges(changes) {
    if (!this.sourceAdded) {
      return;
    }
    if (changes['bounds'] && !changes['bounds'].isFirstChange() || changes['scheme'] && !changes['scheme'].isFirstChange() || changes['minzoom'] && !changes['minzoom'].isFirstChange() || changes['maxzoom'] && !changes['maxzoom'].isFirstChange() || changes['attribution'] && !changes['attribution'].isFirstChange() || changes['promoteId'] && !changes['promoteId'].isFirstChange()) {
      this.ngOnDestroy();
      this.ngOnInit();
    } else if (changes['url'] && !changes['url'].isFirstChange() || changes['tiles'] && !changes['tiles'].isFirstChange()) {
      const source = this.mapService.getSource(this.id);
      if (source === undefined) {
        return;
      }
      if (changes['url'] && this.url) {
        source.setUrl(this.url);
      }
      if (changes['tiles'] && this.tiles) {
        source.setTiles(this.tiles);
      }
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.sourceAdded) {
      this.mapService.removeSource(this.id);
      this.sourceAdded = false;
    }
  }
  init() {
    const source = {
      type: this.type,
      url: this.url,
      tiles: this.tiles,
      bounds: this.bounds,
      scheme: this.scheme,
      minzoom: this.minzoom,
      maxzoom: this.maxzoom,
      attribution: this.attribution,
      promoteId: this.promoteId
    };
    this.mapService.addSource(this.id, source);
    this.sourceAdded = true;
  }
  static {
    this.ɵfac = function VectorSourceComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || VectorSourceComponent)(i0.ɵɵdirectiveInject(MapService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: VectorSourceComponent,
      selectors: [["mgl-vector-source"]],
      inputs: {
        id: "id",
        url: "url",
        tiles: "tiles",
        bounds: "bounds",
        scheme: "scheme",
        minzoom: "minzoom",
        maxzoom: "maxzoom",
        attribution: "attribution",
        promoteId: "promoteId"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function VectorSourceComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VectorSourceComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-vector-source',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: MapService
  }], {
    id: [{
      type: Input
    }],
    url: [{
      type: Input
    }],
    tiles: [{
      type: Input
    }],
    bounds: [{
      type: Input
    }],
    scheme: [{
      type: Input
    }],
    minzoom: [{
      type: Input
    }],
    maxzoom: [{
      type: Input
    }],
    attribution: [{
      type: Input
    }],
    promoteId: [{
      type: Input
    }]
  });
})();
class VideoSourceComponent {
  constructor(mapService) {
    this.mapService = mapService;
    this.sourceAdded = false;
    this.sub = new Subscription();
  }
  ngOnInit() {
    const sub1 = this.mapService.mapLoaded$.subscribe(() => {
      this.init();
      const sub = fromEvent(this.mapService.mapInstance, 'styledata').pipe(filter(() => !this.mapService.mapInstance.getSource(this.id))).subscribe(() => {
        this.init();
      });
      this.sub.add(sub);
    });
    this.sub.add(sub1);
  }
  ngOnChanges(changes) {
    if (!this.sourceAdded) {
      return;
    }
    if (changes['urls'] && !changes['urls'].isFirstChange()) {
      this.ngOnDestroy();
      this.ngOnInit();
    } else if (changes['coordinates'] && !changes['coordinates'].isFirstChange()) {
      const source = this.mapService.getSource(this.id);
      if (source === undefined) {
        return;
      }
      source.setCoordinates(this.coordinates);
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.sourceAdded) {
      this.mapService.removeSource(this.id);
      this.sourceAdded = false;
    }
  }
  init() {
    const source = {
      type: 'video',
      urls: this.urls,
      coordinates: this.coordinates
    };
    this.mapService.addSource(this.id, source);
    this.sourceAdded = true;
  }
  static {
    this.ɵfac = function VideoSourceComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || VideoSourceComponent)(i0.ɵɵdirectiveInject(MapService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: VideoSourceComponent,
      selectors: [["mgl-video-source"]],
      inputs: {
        id: "id",
        urls: "urls",
        coordinates: "coordinates"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function VideoSourceComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VideoSourceComponent, [{
    type: Component,
    args: [{
      selector: 'mgl-video-source',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: MapService
  }], {
    id: [{
      type: Input
    }],
    urls: [{
      type: Input
    }],
    coordinates: [{
      type: Input
    }]
  });
})();
class NgxMapboxGLModule {
  static withConfig(config) {
    return {
      ngModule: NgxMapboxGLModule,
      providers: [{
        provide: MAPBOX_API_KEY,
        useValue: config.accessToken
      }]
    };
  }
  static {
    this.ɵfac = function NgxMapboxGLModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMapboxGLModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxMapboxGLModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMapboxGLModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [MapComponent, LayerComponent, DraggableDirective, ImageComponent, VectorSourceComponent, GeoJSONSourceComponent, RasterDemSourceComponent, RasterSourceComponent, ImageSourceComponent, VideoSourceComponent, CanvasSourceComponent, FeatureComponent, MarkerComponent, PopupComponent, ControlComponent, FullscreenControlDirective, NavigationControlDirective, GeolocateControlDirective, AttributionControlDirective, ScaleControlDirective, PointDirective, ClusterPointDirective, MarkersForClustersComponent],
      exports: [MapComponent, LayerComponent, DraggableDirective, ImageComponent, VectorSourceComponent, GeoJSONSourceComponent, RasterDemSourceComponent, RasterSourceComponent, ImageSourceComponent, VideoSourceComponent, CanvasSourceComponent, FeatureComponent, MarkerComponent, PopupComponent, ControlComponent, FullscreenControlDirective, NavigationControlDirective, GeolocateControlDirective, AttributionControlDirective, ScaleControlDirective, PointDirective, ClusterPointDirective, MarkersForClustersComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-mapbox-gl
 */
// Expose NgxMapboxGlModule and GeocoderControlDirective provided injection tokens

/**
 * Generated bundle index. Do not edit.
 */

export { AttributionControlDirective, CanvasSourceComponent, ClusterPointDirective, ControlComponent, CustomControl, DraggableDirective, FeatureComponent, FullscreenControlDirective, GeoJSONSourceComponent, GeolocateControlDirective, ImageComponent, ImageSourceComponent, LayerComponent, MAPBOX_API_KEY, MapComponent, MapService, MarkerComponent, MarkersForClustersComponent, NavigationControlDirective, NgxMapboxGLModule, PointDirective, PopupComponent, RasterDemSourceComponent, RasterSourceComponent, ScaleControlDirective, VectorSourceComponent, VideoSourceComponent, deprecationWarning };
