<p-table [scrollable]="true" #dt [value]="data" dataKey="uniqueId" [rowHover]="true" [lazy]="false"
         [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
         [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [filterDelay]="0" [tableStyle]="{'min-width': '5550px'}">
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
      {{header}}
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="lon" style="width:100px">
        <div class="flex justify-content-between align-items-center">
          Longitude
        </div>
        <p-sortIcon field="lon"></p-sortIcon>
        <p-columnFilter type="numeric" field="lon" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="lat" style="width:100px">
        <div class="flex justify-content-between align-items-center">
          Latitude
        </div>
        <p-sortIcon field="lat"></p-sortIcon>
        <p-columnFilter type="numeric" field="lat" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="market" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          Market
        </div>
        <p-sortIcon field="market"></p-sortIcon>
        <p-columnFilter type="text" field="market" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="mapId" style="width:150px">
        <div class="flex justify-content-between align-items-center">
          Map ID
        </div>
        <p-sortIcon field="mapId"></p-sortIcon>
        <p-columnFilter type="text" field="mapId" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="country" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          Country
        </div>
        <p-sortIcon field="country"></p-sortIcon>
        <p-columnFilter type="text" field="country" display="menu"></p-columnFilter>
      </th>

      <th pSortableColumn="provider" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          Provider
        </div>
        <p-sortIcon field="provider"></p-sortIcon>
        <p-columnFilter type="text" field="provider" display="menu"></p-columnFilter>
      </th>

      <th pSortableColumn="buildingTenant" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          Building Tenant
        </div>
        <p-sortIcon field="buildingTenant"></p-sortIcon>
        <p-columnFilter type="text" field="buildingTenant" display="menu"></p-columnFilter>
      </th>

      <th pSortableColumn="area" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          Area
        </div>
        <p-sortIcon field="area"></p-sortIcon>
        <p-columnFilter type="text" field="area" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="building" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          Building
        </div>
        <p-sortIcon field="building"></p-sortIcon>
        <p-columnFilter type="text" field="building" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="mwLive" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          MW Live
        </div>
        <p-sortIcon field="mwLive"></p-sortIcon>
        <p-columnFilter type="numeric" field="mwLive" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="mwUC" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          MW U/C
        </div>
        <p-sortIcon field="mwUC"></p-sortIcon>
        <p-columnFilter type="numeric" field="mwUC" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="mwPlanned" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          MW Planned
        </div>
        <p-sortIcon field="mwPlanned"></p-sortIcon>
        <p-columnFilter type="numeric" field="mwPlanned" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="mwTotal" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          MW Total
        </div>
        <p-sortIcon field="mwTotal"></p-sortIcon>
        <p-columnFilter type="numeric" field="mwTotal" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="dataHallLive" style="width:300px">
        <div class="flex justify-content-between align-items-center">
          Data Hall Live
        </div>
        <p-sortIcon field="dataHallLive"></p-sortIcon>
        <p-columnFilter type="numeric" field="dataHallLive" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="dataHallUC" style="width:300px">
        <div class="flex justify-content-between align-items-center">
          Data Hall U/C
        </div>
        <p-sortIcon field="dataHallUC"></p-sortIcon>
        <p-columnFilter type="numeric" field="dataHallUC" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="dataHallPlanned" style="width:300px">
        <div class="flex justify-content-between align-items-center">
          Data Hall Planned
        </div>
        <p-sortIcon field="dataHallPlanned"></p-sortIcon>
        <p-columnFilter type="numeric" field="dataHallPlanned" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="dataHallTotal" style="width:300px">
        <div class="flex justify-content-between align-items-center">
          Data Hall Total
        </div>
        <p-sortIcon field="dataHallTotal"></p-sortIcon>
        <p-columnFilter type="numeric" field="dataHallTotal" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="buildingAreaLive" style="width:300px">
        <div class="flex justify-content-between align-items-center">
          Building Area Live
        </div>
        <p-sortIcon field="buildingAreaLive"></p-sortIcon>
        <p-columnFilter type="numeric" field="buildingAreaLive" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="buildingAreaUC" style="width:300px">
        <div class="flex justify-content-between align-items-center">
          Building Area U/C
        </div>
        <p-sortIcon field="buildingAreaUC"></p-sortIcon>
        <p-columnFilter type="numeric" field="buildingAreaUC" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="buildingAreaPlanned" style="width:300px">
        <div class="flex justify-content-between align-items-center">
          Building Area Planned
        </div>
        <p-sortIcon field="buildingAreaPlanned"></p-sortIcon>
        <p-columnFilter type="numeric" field="buildingAreaPlanned" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="buildingAreaTotal" style="width:300px">
        <div class="flex justify-content-between align-items-center">
          Building Area Total
        </div>
        <p-sortIcon field="buildingAreaTotal"></p-sortIcon>
        <p-columnFilter type="numeric" field="buildingAreaTotal" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="firstYearOp" style="width:200px">
        <div class="flex justify-content-between align-items-center">
          First Year Op.
        </div>
        <p-sortIcon field="firstYearOp"></p-sortIcon>
        <p-columnFilter type="text" field="firstYearOp" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="address" style="width:600px">
        <div class="flex justify-content-between align-items-center">
          Address
        </div>
        <p-sortIcon field="address"></p-sortIcon>
        <p-columnFilter type="text" field="address" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="campus" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          Campus
        </div>
        <p-sortIcon field="campus"></p-sortIcon>
        <p-columnFilter type="text" field="campus" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="productType" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          Product Type
        </div>
        <p-sortIcon field="productType"></p-sortIcon>
        <p-columnFilter type="text" field="productType" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="siteType" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          Site Type
        </div>
        <p-sortIcon field="siteType"></p-sortIcon>
        <p-columnFilter type="text" field="siteType" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="dataSource" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          Data Source
        </div>
        <p-sortIcon field="dataSource"></p-sortIcon>
        <p-columnFilter type="text" field="dataSource" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="sellableITLoad" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          Sellable IT Load
        </div>
        <p-sortIcon field="sellableITLoad"></p-sortIcon>
        <p-columnFilter type="numeric" field="sellableITLoad" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="availabilityZoneId" style="width:250px">
        <div class="flex justify-content-between align-items-center">
          Availability Zone ID
        </div>
        <p-sortIcon field="availabilityZoneId"></p-sortIcon>
        <p-columnFilter type="text" field="availabilityZoneId" display="menu"></p-columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-data>
    <tr>
      <td>
        {{ data.lon }}
      </td>
      <td>
        {{ data.lat }}
      </td>
      <td>
        {{ data.market }}
      </td>
      <td [pEditableColumn]="data.mapId" pEditableColumnField="mapId">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="mapId" pInputText type="text" [(ngModel)]="data.mapId"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.mapId }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.country" pEditableColumnField="country">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="country" pInputText type="text" [(ngModel)]="data.country"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.country }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.provider" pEditableColumnField="provider">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="provider" pInputText type="text" [(ngModel)]="data.provider"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.provider }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.buildingTenant" pEditableColumnField="buildingTenant">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="buildingTenant" pInputText type="text" [(ngModel)]="data.buildingTenant"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.buildingTenant }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.area" pEditableColumnField="area">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="area" pInputText type="text" [(ngModel)]="data.area"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.area }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.building" pEditableColumnField="building">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="building" pInputText type="text" [(ngModel)]="data.building"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.building }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.mwLive" pEditableColumnField="mwLive">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="mwLive" pInputText type="number" [(ngModel)]="data.mwLive"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.mwLive }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.mwUC" pEditableColumnField="mwUC">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="mwUC" pInputText type="number" [(ngModel)]="data.mwUC"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.mwUC }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.mwPlanned" pEditableColumnField="mwPlanned">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="mwPlanned" pInputText type="number" [(ngModel)]="data.mwPlanned"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.mwPlanned }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.mwTotal" pEditableColumnField="mwTotal">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="mwTotal" pInputText type="number" [(ngModel)]="data.mwTotal"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.mwTotal }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.dataHallLive" pEditableColumnField="dataHallLive">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="dataHallLive" pInputText type="number" [(ngModel)]="data.dataHallLive"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.dataHallLive }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.dataHallUC" pEditableColumnField="dataHallUC">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="dataHallUC" pInputText type="number" [(ngModel)]="data.dataHallUC"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.dataHallUC }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.dataHallPlanned" pEditableColumnField="dataHallPlanned">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="dataHallPlanned" pInputText type="number" [(ngModel)]="data.dataHallPlanned"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.dataHallPlanned }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.dataHallTotal" pEditableColumnField="dataHallTotal">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="dataHallTotal" pInputText type="number" [(ngModel)]="data.dataHallTotal"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.dataHallTotal }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.buildingAreaLive" pEditableColumnField="buildingAreaLive">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="buildingAreaLive" pInputText type="number" [(ngModel)]="data.buildingAreaLive"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.buildingAreaLive }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.buildingAreaUC" pEditableColumnField="buildingAreaUC">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="buildingAreaUC" pInputText type="number" [(ngModel)]="data.buildingAreaUC"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.buildingAreaUC }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.buildingAreaPlanned" pEditableColumnField="buildingAreaPlanned">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="buildingAreaPlanned" pInputText type="number"
                   [(ngModel)]="data.buildingAreaPlanned"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.buildingAreaPlanned }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.buildingAreaTotal" pEditableColumnField="buildingAreaTotal">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="buildingAreaTotal" pInputText type="number" [(ngModel)]="data.buildingAreaTotal"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.buildingAreaTotal }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.firstYearOp" pEditableColumnField="firstYearOp">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="firstYearOp" pInputText type="number" [(ngModel)]="data.firstYearOp"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.firstYearOp }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.address" pEditableColumnField="address">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input style="min-width:100%" name="address" pInputText type="text" [(ngModel)]="data.address"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.address }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.campus" pEditableColumnField="campus">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="campus" pInputText type="text" [(ngModel)]="data.campus"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.campus }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.productType" pEditableColumnField="productType">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="productType" pInputText type="text" [(ngModel)]="data.productType"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ (data.productType || []).join(', ') }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.siteType" pEditableColumnField="siteType">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="siteType" pInputText type="text" [(ngModel)]="data.siteType"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ (data.siteType || []).join(', ') }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.dataSource" pEditableColumnField="dataSource">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="dataSource" pInputText type="text" [(ngModel)]="data.dataSource"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ (data.dataSource || []).join(', ') }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.sellableITLoad" pEditableColumnField="sellableITLoad">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="sellableITLoad" pInputText type="number" [(ngModel)]="data.sellableITLoad"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.sellableITLoad }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="data.availabilityZoneId" pEditableColumnField="availabilityZoneId">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input name="availabilityZoneId" pInputText type="text" [(ngModel)]="data.availabilityZoneId"/>
          </ng-template>
          <ng-template pTemplate="output">
            {{ data.availabilityZoneId }}
          </ng-template>
        </p-cellEditor>
      </td>
    </tr>
  </ng-template>
</p-table>
