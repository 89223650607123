import {HttpClient} from "@angular/common/http";
import {AppStateService} from "./app-state.service";
import {catchError, map, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {Injectable} from "@angular/core";
import {GeoInfo} from "../model/geo-info";
import {GeoSelector} from "../ngrx/selectors/geo.selector";
import * as countryNames from "world-countries";

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  private byISO = {};

  constructor(private http: HttpClient, private appStateService: AppStateService, private geoSelectors: GeoSelector) {
    countryNames.default.forEach((country) => {
      this.byISO[country.cca3] = country;
    });
  }

  public queryGeoInfo(query: String): Observable<GeoInfo[]> {
    return this.http.get(`${environment.apiUrl}/geo/${query}`, {responseType: 'json'}).pipe(
      map((data: any) => data.map(i => {
        const geoInfo: GeoInfo = {
          ...i
        }
        return geoInfo;
      }))
    );
  }

  public getLocation(id: String): Observable<GeoInfo> {
    return this.http.get(`${environment.apiUrl}/geo/location/${id}`, {responseType: 'json'}).pipe(
      map((data: any) => data)
    );
  }


  public getCountryByLonLat(lon, lat): string {

    return "";
  }

}
