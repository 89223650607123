<div class="sidebar-inner-content">
  <p-table #dt1 id="filesList" [scrollable]="true"
           scrollHeight="flex" [value]="_mapFiles"
           [lazy]="false" dataKey="fileId">
    <ng-template pTemplate="caption">
      <div class="flex search-header">
        <span class="p-input-icon-left col-12">
                <input class="header-search" pInputText type="text" (input)="filter(dt1, $event)"
                       placeholder="Search keyword"/>
        </span>
      </div>
    </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width:60px;"></th>
          <th></th>
          <th style="width:60px;">
            <button class="btn-custom-outlined" (click)="toggleAll()" [style.visibility]="isSearching ? 'hidden' : 'visible'">
              {{ allFilesSelected ? 'Hide' : 'Show' }} all
            </button>
          </th>
        </tr>
      </ng-template>

    <ng-template pTemplate="body" let-mapFile>
      <tr [pSelectableRow]="mapFile" class="p-selectable-row">
        <td style="width:60px;">
          <p-colorPicker [disabled]="!mapFile.selected" [appendTo]="'body'"
                         (onHide)="onHide()" (onChange)="colorChanged($event, mapFile)"
                         [(ngModel)]="mapFile.color"></p-colorPicker>
        </td>
        <td style="width:70%; text-align: left;">
          {{mapFile.fileName}}
        </td>
        <td style="width:30%;">
          <span>
            <a (click)="toggleSelect(mapFile)"
               style="cursor:pointer;text-decoration: none;margin-top: 3px;"
               [class]="mapFile.selected ? 'pi pi-eye' : 'pi pi-eye-slash'">
            </a>
          </span>
          &nbsp;
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
