@use "../../../styles/consts";


.container {
  padding: 0 !important;
}

app-map-display {

  .p-toolbar {
    background-color: consts.$slate-gray !important;
    border: none !important;

    .p-button:focus {
      box-shadow: none !important;
    }

    .action-button {
      margin: 0;
      button {

      }

      &.red {
        button {
          background-color: red !important;
        }
      }
    }
  }

  .p-toolbar-group-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .toolbar-top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
  }

  .left-section, .center-section, .right-section {
    display: flex;
    align-items: center;
  }

  .left-section {
    flex: 1;
    margin-top: 8px;
  }

  .center-section {
    flex: 2;
    justify-content: center;
  }

  .right-section {
    flex: 1;
    justify-content: flex-end;
  }

  .toolbar-bottom-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
  }

  .usermap-name {
    margin: 0 1rem;
    font-size: 1.1em;
    font-size: 20px;
    margin-left: 20px;
    color: white;
  }

  .mat-mdc-card,
  .p-accordionheader,
  .p-toolbar {
    border-radius: 0px !important;
  }

  .p-accordionheader {
    background-color: consts.$clover-green !important;
    border: none !important;

    * {
      color: white !important;
    }
  }

  .bottom-toolbar {
    display: flex;
    min-width: 100%;

    .slider {
      justify-content: center;
      display: flex;
      gap: 10px;
      color: #656565;

      .slider-container {
        margin-top: 10px;
        width: 70%;
      }

      p-slider {
        margin-top: 5px;

        .p-slider-range {
          background-color: consts.$electric-pear !important;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 5px;
    }

    .markers-toolbar-legend {
      color: #656565;
      padding-bottom: 15px;
    }
  }

  .circle {
    border: solid 3px #333333;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    display: inline-block;
    vertical-align: middle;
  }

  .mapbox-gl-draw_ctrl-draw-btn {
    &.active {
      border: 4px solid #2B9FE1 !important;
    }
  }

  .resize-handle-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 10px;
    background-color: transparent;
    cursor: ew-resize;
  }

  .mat-mdc-card-content {
    padding: inherit !important;
  }

  .p-accordion{
    width: 99%;
  }

  app-geo-filter {
    margin-left: auto;
  }

  .button-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .p-toolbar-separator {
    margin: 0 0.75rem;
  }

  /* Media query for small devices */
  @media (max-width: 768px) {
    .toolbar-top-row, .toolbar-bottom-row {
      flex-direction: column;
      align-items: flex-start;
    }

    .toolbar-top-row > *, .toolbar-bottom-row > * {
      margin-bottom: 0.5rem;
    }

    app-geo-filter {
      margin-left: 0;
      width: 100%;
    }

    .button-group {
      flex-wrap: wrap;
    }
  }

  .mapboxgl-ctrl-fullscreen {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3'%3E%3C/path%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
  }

  .mapboxgl-ctrl-shrink {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M4 14h6m0 0v6m0-6l-7 7m17-11h-6m0 0V4m0 6l7-7'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
  }
}

:fullscreen .fullscreen-container {
  height: 100vh;
  width: 100vw;
}

:fullscreen .map-container {
  flex: 1;
  height: calc(100vh - 60px);
}

:fullscreen mgl-map {
  width: 100%;
  height: 100vh;
}

:fullscreen .legend, :fullscreen .bottom-toolbar {
 display: none;
}

.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.map-layer-popup {
  z-index: 1000;

  .mapboxgl-popup-content {
    background: white;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }

  .mapboxgl-popup-close-button {
    font-size: 16px;
    padding: 5px;
    right: 5px;
    top: 5px;
  }
}
