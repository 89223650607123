import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { DatacenterBasicInfo } from '../../core/model/datacenter';
import { geoActions } from '../../core/ngrx/actions';

@Component({
    selector: 'app-availability-zone-popup',
    templateUrl: './availability-zone-popup.component.html',
    styleUrls: ['./availability-zone-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AvailabilityZonePopupComponent implements OnInit {
  @Input() provider: string;
  @Input() availabilityZoneId: string;
  @Input() datacenters: DatacenterBasicInfo[];
  
  totalMWLive: number = 0;
  totalMWUC: number = 0;
  totalMWPlanned: number = 0;
  totalMW: number = 0;
  
  constructor(
    private store: Store,
    private cd: ChangeDetectorRef
  ) {}
  
  ngOnInit() {
    this.calculateTotals();
    this.cd.detectChanges();
  }
  
  private calculateTotals() {
    this.datacenters.forEach(dc => {
      this.totalMWLive += dc.mwLive || 0;
      this.totalMWUC += dc.mwUC || 0;
      this.totalMWPlanned += dc.mwPlanned || 0;
    });
    this.totalMW = this.totalMWLive + this.totalMWUC + this.totalMWPlanned;
  }
  
  zoomToDatacenter(datacenter: DatacenterBasicInfo) {
    this.store.dispatch(geoActions.centerMap({
      info: {
        id: datacenter.locationId?.toString() || '',
        name: datacenter.provider,
        desc: datacenter.availabilityZoneId || '',
        latitude: datacenter.lat,
        longitude: datacenter.lon,
        zoom: 12
      }
    }));
  }
}