import {
  ApplicationRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {filter, Subject, take, tap} from "rxjs";
import {MapFile} from "../../core/model/map-file";
import {Store} from "@ngrx/store";
import {MapFilesSelector} from "../../core/ngrx/selectors/map-files.selector";
import * as _ from "lodash";
import {mapConfigActions, mapFilesActions} from '../../core/ngrx/actions';
import {UserMap} from "../../core/model/user-map";
import {MapConfigSelector} from "../../core/ngrx/selectors/map-config.selector";

@Component({
    selector: 'app-files-table',
    templateUrl: './files-table.component.html',
    styleUrls: ['./files-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FilesTableComponent implements OnInit, OnDestroy {
  public currentFilter: string = "";
  _originalFiles: MapFile[] = [];
  @Input()
  _mapFiles: MapFile[] = [];
  @Output()
  onFileToggle: EventEmitter<MapFile> = new EventEmitter<MapFile>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  changingFile: MapFile = null;
  public isSearching: boolean = false;

  constructor(private readonly store: Store, private mapConfigSelector: MapConfigSelector,
              private appRef: ApplicationRef) {

  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private sortMapFiles(files: MapFile[]): MapFile[] {
    if (!files) return [];
    return _.orderBy(files, [(file) => file.fileName.toLowerCase()], ['asc']);
  }

  @Input() set mapFiles(mapFiles: MapFile[]) {
    this.mapConfigSelector
      .getUserMap()
      .pipe(
        filter((userMap: UserMap) => !!userMap),
        take(1),
        tap((userMap: UserMap) => {
          const sortedFiles = this.sortMapFiles(_.cloneDeep(mapFiles));
          this._mapFiles = sortedFiles;
          this._originalFiles = sortedFiles;
        })
      )
      .subscribe();
  }

  toggleSelect(selectedFile: MapFile) {
    selectedFile.selected = !selectedFile.selected;
    this.store.dispatch(
      mapFilesActions.fileToggle({mapFile: selectedFile})
    );
  }

  onShow() {
    this.appRef.tick();
  }

  onHide() {
    this.store.dispatch(
      mapFilesActions.fileColorChanged({mapFile: this.changingFile})
    );
    this.changingFile = null;
  }

  colorChanged(event, mapFile) {
    mapFile.color = event.value;
    this.changingFile = mapFile;
  }

  get allFilesSelected(): boolean {
    return this._mapFiles.every(file => file.selected);
  }

  toggleAll() {
    const newState = !this.allFilesSelected;
    // Clone the files array to avoid direct mutations
    const updatedFiles = _.cloneDeep(this._mapFiles);
    // Update selected state for all files
    updatedFiles.forEach(file => {
      file.selected = newState;
      // Dispatch toggle action for each file
      this.store.dispatch(
        mapFilesActions.fileToggle({mapFile: file})
      );
    });
    // Update local files list
    this._mapFiles = updatedFiles;
  }

  filter(dt, event) {
    this.currentFilter = event.target.value.toUpperCase();
    this.isSearching = this.currentFilter.length > 0;
    if (this.currentFilter) {
      this._mapFiles = this.sortMapFiles(
        this._originalFiles.filter(mapFile => 
          mapFile.fileName.toUpperCase().indexOf(event.target.value.toUpperCase()) != -1
        )
      );
    } else {
      this._mapFiles = this._originalFiles;
    }
  }

}
