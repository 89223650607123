import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStateService } from './app-state.service';
import { MapFile } from '../model/map-file';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapFileService {
  private get userId(): string {
    return this.appStateService.user?.userId;
  }

  constructor(private http: HttpClient, private appStateService: AppStateService) {}

  getMapFiles(): Observable<MapFile[]> {
    return this.http.get<MapFile[]>(`${environment.apiUrl}/map-files/${this.userId}`);
  }

  addMapFile(mapFile: MapFile, files?: File): Observable<MapFile> {
    const formData = new FormData();
    Object.keys(mapFile).forEach(key => {
      formData.append(key, mapFile[key]);
    });
    if (files) {
      formData.append('files', files);
    }
    return this.http.post<MapFile>(
      `${environment.apiUrl}/map-files/${this.userId}`,
      formData
    );
  }

  deleteMapFile(filedId: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/map-files/${this.userId}/${filedId}`);
  }

  updateMapFile(mapFile: MapFile): Observable<MapFile> {
    return this.http.put<MapFile>(`${environment.apiUrl}/map-files/${this.userId}/${mapFile.fileId}`, mapFile);
  }

  shareMapFile(id: string, users: string[]): Observable<any> {
    return this.http.post(`${environment.apiUrl}/map-files/${this.userId}/${id}/share`, { users });
  }

  getMapFileDetails(filedId: string): Observable<MapFile> {
    return this.http.get<MapFile>(`${environment.apiUrl}/map-files/${this.userId}/${filedId}`);
  }

  getMapFileMetadata(tilesetId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/map-files/${tilesetId}/metadata`);
  }
}
