import {Component, OnInit, ViewEncapsulation, Input} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Datacenter, DatacenterBasicInfo, SiteType} from "../../core/model/datacenter";
import * as _ from "lodash";
import {MapConfigSelector} from "../../core/ngrx/selectors/map-config.selector";
import {tap, take} from "rxjs/operators";
import {Store} from '@ngrx/store';
import * as mapConfigActions from '../../core/ngrx/actions/map-config.actions';
import {AppStateService} from "../../core/service/app-state.service";
import {UsersSelector} from "../../core/ngrx/selectors/users.selector";
import { usersActions } from '../../core/ngrx/actions';

@Component({
    selector: 'app-new-data-center-popup',
    templateUrl: './new-data-center-popup.component.html',
    styleUrls: ['./new-data-center-popup.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class NewDataCenterPopupComponent implements OnInit {
  latRegExp: RegExp = /^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})?$/;
  lonRegExp: RegExp = /^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})?$/;
  data: DatacenterBasicInfo = {
    firstYearOp: "",
    buildingAreaTotal: 0,
    country: "",
    dataHallLive: 0,
    lat: 0,
    locationId: null,
    lon: 0,
    market: "",
    mwLive: 0,
    mwPlanned: 0,
    mwUC: 0,
    provider: "",
    tempId: "",
    mapId: "",
    building: "",
    area: "",
    notes: "",
    address: "",
    productType: [],
    availabilityZoneId: null,
    sellableITLoad: 0,
    campus: "",
    buildingTenant: [],
    siteType: [],
  };
  isModifying = false;
  isAddingProvider = false;
  isAddingMarket = false;
  providers = [];
  markets = [];
  selectedProvider = undefined;
  selectedMarket = undefined;
  productTypes = [
    { label: 'AI/ML', value: 'AI/ML' },
    { label: 'Availability Zone', value: 'Availability Zone' },
    { label: 'Edge', value: 'Edge' },
    { label: 'Enterprise', value: 'Enterprise' },
    { label: 'Ground Station', value: 'Ground Station' },
    { label: 'Internet Exchange', value: 'Internet Exchange' },
    { label: 'Local Zone', value: 'Local Zone' },
    { label: 'Wavelength Zone', value: 'Wavelength Zone' }
  ].sort((a, b) => a.label.localeCompare(b.label));
  availabilityZoneIds = [];
  selectedAZId: string;
  isAddingNewAZ = false;
  siteTypes = [
    { label: 'Competitor', value: 'Competitor' },
    { label: 'Customer Early Stage', value: 'Customer Early Stage' },
    { label: 'Customer self-build', value: 'Customer self-build' },
    { label: 'Early Stage', value: 'Early Stage' },
    { label: 'Edge', value: 'Edge' },
    { label: 'Land Banked', value: 'Land Banked' },
    { label: 'Opportunity', value: 'Opportunity' }
  ].sort((a, b) => a.label.localeCompare(b.label));
  isAddingBuildingTenant = false;
  selectedBuildingTenant = undefined;
  mapCreationDate: Date;
  modifiedByDisplayName: string;
  selectedSites: any = [];
  selectedProductTypes: any = [];
  newBuildingTenant: string = '';
  selectedBuildingTenants: any = [];
  buildingTenants: any[] = [];
  isAddingDataSource = false;
  selectedDataSource = undefined;
  newDataSource: string = '';
  selectedDataSources: any = [];
  dataSources: any[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private mapConfigSelector: MapConfigSelector,
    private store: Store,
    private appStateService: AppStateService,
    private usersSelector: UsersSelector
  ) {}

  ngOnInit(): void {
    this.store.dispatch(usersActions.getUsers());
    this.mapCreationDate = this.config?.data?.mapCreationDate;
    this.data = _.cloneDeep(this.config.data as DatacenterBasicInfo);
    
    this.initializeSelectedValues();

    if (this.config.data?.locationId) {
      this.isModifying = true;
      
      if (this.data.lat) {
        this.data.lat = +this.data.lat.toFixed(6);
      }
      if (this.data.lon) {
        this.data.lon = +this.data.lon.toFixed(6);
      }
    }

    this.mapConfigSelector.getExistingProviders().pipe(
      take(1),
      tap((providers: []) => {
        let sortedProviders: [] = _.cloneDeep(providers);
        sortedProviders.sort((a: string, b: string) => a.localeCompare(b));
        this.providers = sortedProviders;
      })
    ).subscribe();

    this.mapConfigSelector.getExistingMarkets().pipe(
      take(1),
      tap((markets: []) => {
        let sortedMarkets: [] = _.cloneDeep(markets);
        sortedMarkets.sort((a: string, b: string) => a.localeCompare(b));
        this.markets = sortedMarkets;
      })
    ).subscribe();

    this.mapConfigSelector.getExistingBuildingTenants().pipe(
      take(1),
      tap((buildingTenants: string[]) => {
        this.buildingTenants = Array.from(new Set(
          buildingTenants
            .flat()
            .filter(id => id !== null && id !== undefined && id !== '')
        )).sort((a, b) => a.localeCompare(b))
        this.loadBuildingTenants();
      })
    ).subscribe();

    this.mapConfigSelector.getExistingDataSources().pipe(
      take(1),
      tap((dataSources: string[]) => {
        this.dataSources = Array.from(new Set(
          dataSources
            .flat()
            .filter(id => id !== null && id !== undefined && id !== '')
        )).sort((a, b) => a.localeCompare(b))
        this.loadDataSources();
      })
    ).subscribe();

    this.updateModifiedByDisplayName();
    this.loadSiteTypes();
    this.loadProductTypes();
  }

  private initializeSelectedValues(): void {
    this.selectedProvider = this.data.provider;
    this.selectedMarket = this.data.market;
    this.selectedBuildingTenant = this.data.buildingTenant || [];
    this.selectedAZId = this.data.availabilityZoneId;
    
    if (this.data.provider) {
      this.mapConfigSelector.getExistingProviders().pipe(
        take(1),
        tap(() => {
          this.loadAvailabilityZoneIds();
        })
      ).subscribe();
    }
  }

  private loadSiteTypes() {
    if (!Array.isArray(this.data.siteType)) {
      this.data.siteType = [];
    }
    
    if (this.data.siteType.length > 0) {
      this.selectedSites = this.data.siteType.map(site => ({
        label: site,
        value: site
      }));
    }
  }

  private loadProductTypes() {
    if (!Array.isArray(this.data.productType)) {
      this.data.productType = [];
    }
    
    if (this.data.productType.length > 0) {
      this.selectedProductTypes = this.data.productType.map(product => ({
        label: product,
        value: product
      }));
    }
  }

  private loadBuildingTenants() {
    this.buildingTenants = this.buildingTenants.map(tenant => ({
      label: tenant,
      value: tenant
    }));

    this.selectedBuildingTenants = this.data.buildingTenant.map(tenant => ({
      label: tenant,
      value: tenant
    }));
  }

  private loadDataSources() {
    this.dataSources = this.dataSources.map(source => ({
      label: source,
      value: source
    }));

    this.selectedDataSources = (this.data.dataSource).map(source => ({
      label: source,
      value: source
    }));
  }

  updateModifiedByDisplayName() {
    this.usersSelector.getUsers().pipe(
      take(1),
      tap(users => {
        if (this.data?.modifiedBy && users?.length > 0) {
          const user = users.find(u => u.userId === this.data.modifiedBy);
          if (user) {
            this.modifiedByDisplayName = user.fullName || user.email;
          }
        }
      })
    ).subscribe();
  }

  onProductTypeChange() {
    this.data.productType = this.selectedProductTypes.map(product => product.value);
    if (!this.data.productType.includes('Availability Zone')) {
      this.loadAvailabilityZoneIds();
      this.data.availabilityZoneId = null;
      this.selectedAZId = null;
      this.isAddingNewAZ = false;
    }
  }

  addNewTenant() {
    if (this.newBuildingTenant) {
      const newTenant = {
        label: this.newBuildingTenant.trim(),
        value: this.newBuildingTenant.trim()
      };
      
      if (!this.buildingTenants.some(tenant => tenant.value === newTenant.value)) {
        this.buildingTenants = [...this.buildingTenants, newTenant].sort((a, b) => 
          a.label.localeCompare(b.label));
        
        this.selectedBuildingTenants = [...this.selectedBuildingTenants, newTenant];
        
        this.data.buildingTenant = this.selectedBuildingTenants.map(tenant => tenant.value);
      }
      
      this.newBuildingTenant = '';
      this.isAddingBuildingTenant = false;
    }
  }

  saveNewMarker() {
    if (this.isAddingNewAZ && this.data.availabilityZoneId) {
      this.store.dispatch(mapConfigActions.addTemporaryAZId({
        azId: this.data.availabilityZoneId
      }));
    }
    
    if (!this.isAddingProvider) {
      this.data.provider = this.selectedProvider;
    }
    if (!this.isAddingMarket) {
      this.data.market = this.selectedMarket;
    }

    this.data.buildingTenant = this.selectedBuildingTenants.map(tenant => tenant.value);
    this.data.dataSource = this.selectedDataSources.map(source => source.value);

    if (this.data.productType.includes('Availability Zone')) {
      this.data.availabilityZoneId = this.isAddingNewAZ ? this.data.availabilityZoneId : this.selectedAZId;
    }

    if (this.isModifying || this.data?.locationId) {
      this.data.modifiedBy = this.appStateService.user.userId;
      this.data.modificationDate = new Date().toISOString();
      this.updateModifiedByDisplayName();
    } else {
      this.data.creationDate = new Date().toISOString();
      this.data.modifiedBy = this.appStateService.user.userId;
      this.data.modificationDate = null;
      this.updateModifiedByDisplayName();
    }

    this.updateMarkerColor();
    this.ref.close(_.cloneDeep(this.data));
  }

  updateMarkerColor() {
    this.store.dispatch(mapConfigActions.updateMarkerColor({
      datacenter: this.data,
      newProvider: this.data.provider
    }));
  }

  onSiteTypesChange() {
    this.data.siteType = this.selectedSites.map(site => site.value);
  }

  onProducTypesChange() {
    this.data.productType = this.selectedSites.map(product => product.value);
  }

  onBuildingTenantsChange() {
    this.data.buildingTenant = this.selectedBuildingTenants.map(tenant => tenant.value);
  }

  addNewDataSource() {
    if (this.newDataSource) {
      const newSource = {
        label: this.newDataSource.trim(),
        value: this.newDataSource.trim()
      };
      
      if (!this.dataSources.some(source => source.value === newSource.value)) {
        this.dataSources = [...this.dataSources, newSource].sort((a, b) => 
          a.label.localeCompare(b.label));
        
        this.selectedDataSources = [...this.selectedDataSources, newSource];
        
        this.data.dataSource = this.selectedDataSources.map(source => source.value);
      }
      
      this.newDataSource = '';
      this.isAddingDataSource = false;
    }
  }

  onDataSourcesChange() {
    this.data.dataSource = this.selectedDataSources.map(source => source.value);
  }

  cancel() {
    this.ref.close(null);
  }

  loadAvailabilityZoneIds() {
    this.mapConfigSelector.getDatacenters().pipe(
      take(1),
      tap((datacenters) => {
        // Get current provider
        const currentProvider = this.isAddingProvider ? 
          this.data.provider : 
          this.selectedProvider;

        // Clear AZ ID only if provider changes
        if (this.data.provider !== currentProvider) {
          this.selectedAZId = null;
          this.data.availabilityZoneId = null;
          this.isAddingNewAZ = false;
        }
        
        // Filter AZ IDs by current provider
        const filteredAZIds = datacenters
          .filter(dc => 
            dc.provider === currentProvider &&
            dc.productType.includes('Availability Zone') &&
            dc.availabilityZoneId !== null && 
            dc.availabilityZoneId !== undefined
          )
          .map(dc => dc.availabilityZoneId)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((a, b) => a.localeCompare(b));

        // Update AZ IDs list
        this.availabilityZoneIds = filteredAZIds.map(id => ({
          label: id,
          value: id
        }));

        // Keep selected AZ ID if valid
        if (this.data.availabilityZoneId && 
            filteredAZIds.includes(this.data.availabilityZoneId)) {
          this.selectedAZId = this.data.availabilityZoneId;
        }
      })
    ).subscribe();
  }
}
