<div class="popup-container">
  <div class="popup-header">
    <div class="modification-info" *ngIf="data?.modificationDate || data?.creationDate || mapCreationDate">
      <span>
        {{ data?.modificationDate ? 'Updated' : 'Created' }} 
        {{ (data?.modificationDate || data?.creationDate || mapCreationDate) | date:'d MMMM yyyy HH:mm' }}
        <ng-container *ngIf="data?.modifiedBy">
          by {{modifiedByDisplayName || data.modifiedBy}}
        </ng-container>
        <ng-container *ngIf="!data?.modificationDate && !data?.creationDate && mapCreationDate">
          (Map creation date)
        </ng-container>
      </span>
    </div>
  </div>
  <form class="form" #dataForm="ngForm">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="providerTxt">Provider</label>
          <div class="cb-input-container">
            <div class="control-container">
              <div class="row">
                <div class="column col-lg-12 col-xl-9">
                  <input class="form-control" type="text" pInputText id="providerTxt" *ngIf="isAddingProvider"
                         [(ngModel)]="data.provider" name="provider" required/>
                  <p-select *ngIf="!isAddingProvider"
                            placeholder="Select provider"
                            [options]="providers"
                            [(ngModel)]="selectedProvider"
                            [required]="true"
                            (onChange)="loadAvailabilityZoneIds()"
                            name="exProvider"
                            [style]="{'width':'100%'}"
                            [showClear]="true">
                  </p-select>
                </div>
                <div class="column col-lg-12 col-xl-3 add-cb">
                  <input type="checkbox" [(ngModel)]="isAddingProvider" name="addProviderCb" id="addProviderCb"/>
                  <label for="addProviderCb">Add Provider</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="marketTxt">Market</label>
          <div class="cb-input-container">
            <div class="control-container">
              <div class="row">
                <div class="column col-lg-12 col-xl-9">
                  <input class="form-control" type="text" pInputText id="marketTxt" *ngIf="isAddingMarket"
                         [(ngModel)]="data.market" name="market" required/>
                  <p-select *ngIf="!isAddingMarket" placeholder="Select market" [options]="markets"
                              [(ngModel)]="selectedMarket" [required]="true"
                              name="exMarket"
                              [style]="{'width':'100%'}"></p-select>
                </div>
                <div class="column col-lg-12 col-xl-3 add-cb">
                  <input type="checkbox" [(ngModel)]="isAddingMarket" name="addMarketCb" id="addMarketCb"/>
                  <label for="addMarketCb">Add Market</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="buildingTenantTxt">Building Tenant</label>
          <div class="cb-input-container">
            <div class="control-container">
              <div class="row">
                <div class="column col-lg-12 col-xl-9">
                  <p-multiSelect
                    *ngIf="!isAddingBuildingTenant" 
                    [options]="buildingTenants" 
                    [(ngModel)]="selectedBuildingTenants"
                    placeholder="Select Building Tenants"
                    [style]="{ width: '100%' }"
                    display="chip"
                    (onChange)="onBuildingTenantsChange()"
                    name="buildingTenant"
                    optionLabel="label">
                  </p-multiSelect>
                  <div *ngIf="isAddingBuildingTenant" class="new-tenant-input">
                    <input class="form-control" 
                           type="text" 
                           pInputText 
                           id="buildingTenantTxt"
                           [(ngModel)]="newBuildingTenant" 
                           name="newBuildingTenant" 
                           required/>
                    <p-button
                      styleClass="p-button-primary secondary p-button-sm"
                      label="Add" 
                      (onClick)="addNewTenant()"
                      [disabled]="!newBuildingTenant">
                    </p-button>
                  </div>
                </div>
                <div class="column col-lg-12 col-xl-3 add-cb">
                  <input type="checkbox" 
                         [(ngModel)]="isAddingBuildingTenant" 
                         name="addBuildingTenantCb" 
                         id="addBuildingTenantCb"/>
                  <label for="addBuildingTenantCb">Add New Tenant</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="mapIdTxt">Map id</label>
          <input class="form-control" type="text" pInputText id="mapIdTxt"
                 [(ngModel)]="data.mapId" maxlength="3" name="mapId"/>
        </div>
        <div class="form-group" *ngIf="isModifying">
          <label>Lat</label>
          <p-inputNumber class="form-control" inputId="latTxt" [required]="true" mode="decimal"
                         [minFractionDigits]="6"
                         [maxFractionDigits]="6"
                         [min]="-90"
                         [max]="90"
                         [format]="false"
                         [(ngModel)]="data.lat" name="latTxt"></p-inputNumber>
        </div>
        <div class="form-group" *ngIf="isModifying">
          <label>Lon</label>
          <p-inputNumber class="form-control" inputId="lonTxt" [required]="true" mode="decimal"
                         [minFractionDigits]="6"
                         [maxFractionDigits]="6"
                         [min]="-180"
                         [max]="180"
                         [format]="false"
                         [(ngModel)]="data.lon" name="lonTxt"></p-inputNumber>
        </div>
        <div class="form-group">
          <label for="buildingTxt">Building</label>
          <input class="form-control" type="text" pInputText id="buildingTxt"
                 [(ngModel)]="data.building" name="building"/>
        </div>
        <div class="form-group">
          <label for="buildingTxt">Area</label>
          <input class="form-control" type="text" pInputText id="areaTxt"
                 [(ngModel)]="data.area" name="area"/>
        </div>
        <div class="form-group">
          <label for="mwLiveTxt">MW Live</label>
          <input class="form-control" type="number" pInputText id="mwLiveTxt"
                 [(ngModel)]="data.mwLive" name="mwLive"/>
        </div>
        <div class="form-group">
          <label for="mwUCTxt">MW U/C</label>
          <input class="form-control" type="number" pInputText id="mwUCTxt"
                 [(ngModel)]="data.mwUC" name="mwUC"/>
        </div>
        <div class="form-group">
          <label for="mwPlannedTxt">MW Planned</label>
          <input class="form-control" type="number" pInputText id="mwPlannedTxt"
                 [(ngModel)]="data.mwPlanned" name="mwPlanned"/>
        </div>
        <div class="form-group">
          <label for="dhlTxt">Data Hall Live</label>
          <input class="form-control" type="number" pInputText id="dhlTxt"
                 [(ngModel)]="data.dataHallLive" name="dhl"/>
        </div>
        <div class="form-group">
          <label for="buildingAreaTxt">Building Area</label>
          <input class="form-control" type="number" pInputText id="buildingAreaTxt"
                 [(ngModel)]="data.buildingAreaTotal" name="buildingArea"/>
        </div>
        <div class="form-group">
          <label for="fyoTxt">First Year Op.</label>
          <input class="form-control" pInputText id="fyoTxt" maxlength="4" onlyNumber
                 [(ngModel)]="data.firstYearOp" name="fop"/>
        </div>
        <div class="form-group">
          <label for="siteType">Site Type</label>
          <p-multiSelect 
            [options]="siteTypes" 
            [(ngModel)]="selectedSites" 
            optionLabel="label"
            placeholder="Select Site Types"
            [style]="{ width: '100%' }"
            display="chip"
            (onChange)="onSiteTypesChange()"
            name="siteTypes">
          </p-multiSelect>
        </div>
        <div class="form-group">
          <label for="productType">Product Type</label>
          <p-multiSelect 
            [options]="productTypes" 
            [(ngModel)]="selectedProductTypes" 
            optionLabel="label"
            placeholder="Select Product Types"
            [style]="{ width: '100%' }"
            display="chip"
            (onChange)="onProductTypeChange()"
            name="productTypes">
          </p-multiSelect>
        </div>
        <div class="form-group" *ngIf="data.productType.includes('Availability Zone')">
          <label for="availabilityZoneId">Availability Zone ID</label>
          <div class="cb-input-container">
            <div class="control-container">
              <div class="row">
                <div class="column col-lg-12 col-xl-9">
                  <p-select *ngIf="!isAddingNewAZ" 
                            placeholder="Select Availability Zone ID" 
                            [options]="availabilityZoneIds"
                            [(ngModel)]="selectedAZId" 
                            [required]="true"
                            name="exAZId" 
                            [style]="{'width':'100%'}">
                  </p-select>
                  <input class="form-control" *ngIf="isAddingNewAZ" 
                         type="text" 
                         pInputText 
                         id="newAZIdTxt"
                         [(ngModel)]="data.availabilityZoneId" 
                         name="newAZId" 
                         required/>
                </div>
                <div class="column col-lg-12 col-xl-3 add-cb">
                  <input type="checkbox" 
                         [(ngModel)]="isAddingNewAZ" 
                         name="addNewAZCb" 
                         id="addNewAZCb"/>
                  <label for="addNewAZCb">Add New AZ</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="sellableITLoad">Sellable IT Load</label>
          <input class="form-control" type="number" pInputText id="sellableITLoad"
                 [(ngModel)]="data.sellableITLoad" name="sellableITLoad"/>
        </div>
        <div class="form-group">
          <label for="buildingTxt">Campus</label>
          <input class="form-control" type="text" pInputText id="campusTxt"
                 [(ngModel)]="data.campus" name="campus"/>
        </div>
        <div class="form-group">
          <label for="addressTxt">Address</label>
          <input class="form-control" type="text" pInputText id="addressTxt"
                 [(ngModel)]="data.address" name="address"/>
        </div>
        <div class="form-group">
          <label for="dataSource">Data Source</label>
          <div class="cb-input-container">
            <div class="control-container">
              <div class="row">
                <div class="column col-lg-12 col-xl-9">
                  <p-multiSelect
                    *ngIf="!isAddingDataSource" 
                    [options]="dataSources" 
                    [(ngModel)]="selectedDataSources"
                    placeholder="Select Data Sources"
                    [style]="{ width: '100%' }"
                    display="chip"
                    (onChange)="onDataSourcesChange()"
                    name="dataSource"
                    optionLabel="label">
                  </p-multiSelect>
                  <div *ngIf="isAddingDataSource" class="new-source-input">
                    <input class="form-control" 
                           type="text" 
                           pInputText 
                           id="dataSourceTxt"
                           [(ngModel)]="newDataSource" 
                           name="newDataSource"
                           required/>
                    <p-button
                      styleClass="p-button-primary secondary p-button-sm"
                      label="Add"
                      (onClick)="addNewDataSource()"
                      [disabled]="!newDataSource">
                    </p-button>
                  </div>
                </div>
                <div class="column col-lg-12 col-xl-3 add-cb">
                  <input type="checkbox" 
                         [(ngModel)]="isAddingDataSource" 
                         name="addDataSourceCb" 
                         id="addDataSourceCb"/>
                  <label for="addDataSourceCb">Add New Source</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Notes</label>
          <textarea class="form-control" name="notes" id="notes" pInputTextarea [(ngModel)]="data.notes">
          </textarea>
        </div>
        <div style="margin-top: 10px; float: right;" class="btn-toolbar">
          <p-button styleClass="p-button-primary primary p-button-sm" [label]="isModifying ? 'Modify' : 'Add Marker'"
                    [disabled]="!dataForm.form.valid"
                    (onClick)="saveNewMarker()"></p-button>
          <p-button styleClass="p-button-primary secondary p-button-sm" [label]="'Cancel'"
                    (onClick)="cancel()"></p-button>
        </div>
        
      </div>
    </div>
  </form>
</div>