import {createFeatureSelector, createSelector, Store} from "@ngrx/store";
import * as formMapConfig from "../reducers/map-config.reducer";
import {Injectable} from "@angular/core";
import * as _ from "lodash";
import {Observable} from "rxjs";
import {Datacenter} from '../../model/datacenter';

@Injectable({
  providedIn: 'root'
})
export class MapConfigSelector {
  constructor(private readonly store: Store) {
  }

  getMapConfigState = createFeatureSelector<formMapConfig.State>('mapConfig');

  getMapSource() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.mapSource
    )));
  };

  getDrawingFeatures() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.drawingFeatures
    )));
  };

  idDisplayingLines() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.displayLines
    )));
  };

  idDisplayingPolygons() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.displayPolygons
    )));
  };

  getSelectedPolygon() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.selectedPolygon
    )));
  };

  getProviders() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.providers
    )));
  };

  getSelectedProviders() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.selectedProviders
    )));
  };

  getUserMap() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.userMap
    )));
  };

  getMarkerToDisplay() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.markerToDisplay
    )));
  };

  getSelectedDataCenters() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => _.cloneDeep(state.datacenters.filter(dc => dc.selected))
    )));
  };

  getExistingMarkets() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.existingMarkets
    )));
  };

  getMwThreshold() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.mwThreshold
    )));
  };

  getExistingProviders() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.existingProviders
    )));
  };

  areMarkersBlocked() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.areMarkersBlocked
    )));
  }

  getScale() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.scale
    )));
  };

  getDistanceUnits() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.units
    )));
  };

  isReadOnly() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.readOnly
    )));
  };

  getInitPolygonId() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.initPolygonId
    )));
  };

  getExistingAvailabilityZoneIds() {
    return this.store.select((state: { mapConfig: formMapConfig.State }) => {
      const existingIds = state.mapConfig.existingAvailabilityZoneIds || [];
      const temporaryIds = state.mapConfig.temporaryAZIds || [];
      return [...new Set([...existingIds, ...temporaryIds])];
    });
  }

  getExistingBuildingTenants() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.existingBuildingTenants
    )));
  };

  getInfoLayerVisibility() {
    return this.store.select(createSelector(
      this.getMapConfigState,
      (state) => state.infoLayerVisible
    ));
  }

  getDatacenters(): Observable<Datacenter[]> {
    return this.store.select((state: any) => state.mapConfig.datacenters);
  }

  getAZVisibility(): Observable<Record<string, boolean>> {
    return this.store.select((state: any) => state.mapConfig.azVisibility);
  }

  getExistingDataSources() {
    return this.store.select((createSelector(
      this.getMapConfigState,
      (state: formMapConfig.State) => state.existingDataSources
    )));
  }
}
