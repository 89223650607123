import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../core/service/loading.service';

@Component({
    selector: 'app-loading',
    template: `
    <div class="loading-overlay" *ngIf="loading$ | async">
      <div class="loading-spinner">
        <p-progressSpinner strokeWidth="4" 
                          [style]="{'width': '50px', 'height': '50px'}"
                          styleClass="custom-spinner">
        </p-progressSpinner>
      </div>
    </div>
  `,
    styles: [`
    .loading-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ::ng-deep .custom-spinner .p-progress-spinner-circle {
      stroke: #00A651 !important;
      animation: custom-progress-spinner-dash 1.5s ease-in-out infinite;
    }
  `],
    standalone: false
})
export class LoadingComponent implements OnInit {
  loading$ = this.loadingService.getLoading();

  constructor(private loadingService: LoadingService) {}

  ngOnInit() {}
} 