import {
  ApplicationRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  NgZone,
  Renderer2,
  ChangeDetectorRef,
  ViewContainerRef,
  AfterViewInit,
} from '@angular/core';
import {Datacenter, DatacenterBasicInfo} from '../../core/model/datacenter';
import {MapPolygon, Nestable} from '../../core/model/summary';
import * as mapboxgl from 'mapbox-gl';
import {DataCentersService} from '../../core/service/data-centers.service';
import {MapService} from '../../core/service/map.service';
import * as turf from '@turf/turf';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {DistanceUnits, UserMap} from '../../core/model/user-map';
import * as fromConsts from '../../core/consts/map.constants';
import {
  DISTANCE_LABEL_SOURCE_TYPE,
  DISTANCE_LAYER_ID,
  INFO_SQUARE_TEXT_SOURCE_TYPE,
  MARKET_CIRCLE_FILL_LAYER_ID,
  MARKET_CIRCLE_LAYER_ID,
  MARKET_LABEL_LAYER_ID,
  MARKET_LABEL_SOURCE_TYPE, MARKET_LINES_SOURCE_TYPE,
  POLYGON_LABEL_SOURCE_TYPE,
  POLYGON_LAYER_ID,
  SOURCE_ID,
  SQUARE_INFO_FILL_LAYER_ID,
  SQUARE_INFO_TEXT_LAYER_ID,
} from '../../core/consts/map.constants';
import {Store} from '@ngrx/store';
import {filter, firstValueFrom, Observable, skip, Subject, take, takeUntil, tap} from 'rxjs';
import {MapConfigSelector} from '../../core/ngrx/selectors/map-config.selector';
import {DynamicComponentService} from '../../core/service/dynamic-component.service';
import {PolygonPopupComponent} from '../polygon-popup/polygon-popup.component';
import {Actions, ofType} from '@ngrx/effects';
import {geoActions, mapConfigActions, mapFilesActions} from '../../core/ngrx/actions';
import {MapConfigStateService} from '../../core/service/map-config-state.service';
import {DialogService, DynamicDialogRef, DynamicDialogComponent} from 'primeng/dynamicdialog';
import {
  NewDataCenterPopupComponent
} from '../new-data-center-popup/new-data-center-popup.component';
import {GeoInfo} from '../../core/model/geo-info';
import * as _ from 'lodash';
import {GeoService} from '../../core/service/geo.service';
import * as MapboxDrawGeodesic from 'mapbox-gl-draw-geodesic';
import {CustomDrawControl} from '../../core/util/custom-draw-control';
import {ActivatedRoute} from '@angular/router';
import {FileDownloaderService} from '../../core/service/file-downloader.service';
import {ResizeEvent} from 'angular-resizable-element';
import {GeoSelector} from "../../core/ngrx/selectors/geo.selector";
import {MapLayer} from '../../core/model/map-layer.interface';
import {MapLayerPopupComponent} from '../map-layer-popup/map-layer-popup.component';
import {
  AvailabilityZonePopupComponent
} from '../availability-zone-popup/availability-zone-popup.component';
import {MapFilesSelector} from "../../core/ngrx/selectors/map-files.selector";
import {MapFile} from "../../core/model/map-file";
import {FileMetadata} from "../../core/model/file-metadata";
import {NUKE_STORE} from "../../core/ngrx/actions/map-files.actions";

@Component({
  selector: 'app-map-display',
  templateUrl: './map-display.component.html',
  styleUrls: ['./map-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class MapDisplayComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() onBack = new EventEmitter();

  public selectedDataCenters: Observable<DatacenterBasicInfo[]> =
    this.mapConfigSelector.getSelectedDataCenters();

  style: string;
  isMiles: boolean = true;
  layerId = 'light';
  userMap: Observable<UserMap> = this.mapConfigSelector.getUserMap();
  fileMetadata: FileMetadata;
  public map: mapboxgl.Map;

  displayLines: boolean = true;
  displayMarkers: boolean = true;
  displayInfo: boolean = true;
  displayPolygons: boolean = true;
  paintAreas: boolean = true;
  displayControls: boolean = true;
  legendCollapsed = true;
  displayTilesetMetadata = false;
  tilesetId: string;

  navigationControl = new mapboxgl.NavigationControl();
  fullscreenControl = new mapboxgl.FullscreenControl();
  polygonControl = new MapboxDraw({
    displayControlsDefault: false,
    // Select which mapbox-gl-draw control buttons to add to the map.
    controls: {
      polygon: true,
      line_string: true,
      trash: true,
      draw_circle: true,
    },
    modes: {
      ...MapboxDrawGeodesic.enable(MapboxDraw.modes),
    },
    styles: fromConsts.MAP_DRAW_STYLE,
    userProperties: true,
  });

  draw = new CustomDrawControl({
    draw: this.polygonControl,
    buttons: [
      {
        events: [
          {
            on: 'click',
            action: () => {
              if (
                (event.target as any).parentElement.classList.contains('active')
              ) {
                (event.target as any).parentElement.classList.remove('active');
                this.polygonControl.changeMode('simple_select');
              } else {
                (event.target as any).parentElement.classList.add('active');
                this.polygonControl.changeMode('draw_circle');
              }
            },
          },
        ],
        content: '<span class="circle"></span>',
        classes: [],
      },
    ],
  });

  polygonPopup: mapboxgl.Popup;

  destroy$: Subject<boolean> = new Subject<boolean>();

  ref: DynamicDialogRef | undefined;

  mwThreshold: number = 0;
  areMarkersBlocked: boolean = true;
  isReadOnly: boolean = false;
  initialHandled = false;
  datacenters: Datacenter[] = [];

  @ViewChild('resizableContainer') resizableContainer: ElementRef<HTMLElement>;
  private initialWidth: number;
  isResizing = false;
  private resizeStartX: number;
  searchGeoInfoResult: Observable<GeoInfo> = this.geoSelector.getLocation();

  @ViewChild('fullscreenContainer') fullscreenContainer: ElementRef;

  @ViewChild('dialogContainer', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;

  isFullscreen: boolean = false;

  private dialogRef: DynamicDialogRef;

  public lastKnownWidth: string;
  private mapCreationDate: Date;

  private layerPopup: mapboxgl.Popup = null;
  fiberMapFiles: Observable<MapFile[]> = this.mapFilesSelector.getFiberFiles();
  powerMapFiles: Observable<MapFile[]> = this.mapFilesSelector.getPowerFiles();
  landMapFiles: Observable<MapFile[]> = this.mapFilesSelector.getLandFiles();
  railroadMapFiles: Observable<MapFile[]> = this.mapFilesSelector.getRailroadFiles();
  oilGasMapFiles: Observable<MapFile[]> = this.mapFilesSelector.getOilGasFiles();


  private currentPopup: mapboxgl.Popup | null = null;

  constructor(
    private dataCentersService: DataCentersService,
    private dynamicComponentService: DynamicComponentService,
    private mapService: MapService,
    private readonly store: Store,
    private mapConfigSelector: MapConfigSelector,
    private appRef: ApplicationRef,
    private actions$: Actions,
    public mapConfigState: MapConfigStateService,
    public dialogService: DialogService,
    private geoService: GeoService,
    private route: ActivatedRoute,
    private fileDownloader: FileDownloaderService,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private geoSelector: GeoSelector,
    private cdr: ChangeDetectorRef,
    private mapFilesSelector: MapFilesSelector
  ) {
  }

  ngOnInit(): void {
    //create map bounds and center
    this.changeStyle(this.layerId);
    this.mapConfigSelector
      .getMwThreshold()
      .pipe(
        takeUntil(this.destroy$),
        tap((threshold: number) => this.onMwThresholdChange(threshold))
      )
      .subscribe();

    this.mapConfigSelector
      .getInitPolygonId()
      .pipe(
        takeUntil(this.destroy$),
        filter((id?: string) => !!id),
        tap((id: string) => {
          setTimeout(() => {
            this.store.dispatch(
              mapConfigActions.polygonSelected({
                featureId: id,
                isModifying: true,
              })
            );
          }, 100);
        })
      )
      .subscribe();

    this.mapConfigSelector
      .isReadOnly()
      .pipe(
        takeUntil(this.destroy$),
        tap((isReadOnly: boolean) => (this.isReadOnly = isReadOnly))
      )
      .subscribe();

    this.mapFilesSelector
      .getFileMetadata()
      .pipe(
        takeUntil(this.destroy$),
        filter((metadata: FileMetadata) => !!metadata),
        tap((file ) => {
          this.displayTilesetMetadata = true;
          this.fileMetadata = file;
        })
      )
      .subscribe();

    this.mapConfigSelector
      .getDistanceUnits()
      .pipe(
        takeUntil(this.destroy$),
        tap((units: DistanceUnits) => {
          this.isMiles = units == DistanceUnits.MILES;
        })
      )
      .subscribe();

    document.addEventListener('fullscreenchange', this.handleFullscreenChange);

    this.mapConfigSelector
      .getInfoLayerVisibility()
      .pipe(
        takeUntil(this.destroy$),
        tap((visible: boolean) => {
          this.displayInfo = visible;
          if (this.map) {
            this.map.setLayoutProperty(
              SQUARE_INFO_TEXT_LAYER_ID,
              'visibility',
              visible ? 'visible' : 'none'
            );
          }
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    if (!this.legendCollapsed) {
      this.initialWidth = this.resizableContainer.nativeElement.offsetWidth;
    }
    this.dialogService.dialogComponentRefMap.forEach((value, key) => {
      if (value.instance instanceof DynamicDialogComponent ||
        value.componentType?.name === 'DynamicDialogComponent') {
        const dialogInstance = value.instance as any;
        if (typeof dialogInstance.appendTo === 'undefined') {
          dialogInstance.appendTo = this.dialogContainer.element.nativeElement;
        }
      }
    });
  }

  onUserMapLoaded(userMap: UserMap) {
    this.store.dispatch(
      mapFilesActions.loadFiles()
    );
    if (this.isReadOnly) {
      this.map.removeControl(this.draw);
    }
    this.datacenters = this.mapService.getSelectedDatacenters(
      _.cloneDeep(userMap.datacenters)
    );
    const datacentersConfig = _.cloneDeep(userMap.datacenters);
    if (datacentersConfig.length) {
      const boundCon = [];
      for (let a of this.datacenters) {
        boundCon.push([a.lon, a.lat]);
      }
      let markers = turf.points(boundCon);
      let center = turf.center(markers);
      let bbox = turf.bbox(markers);
      let bounds = [bbox[0], bbox[1], bbox[2], bbox[3]];
      let mapCenter = [
        center.geometry.coordinates[0],
        center.geometry.coordinates[1],
      ];
      this.map.setCenter(mapCenter as any);
      this.map.fitBounds(bounds as any);
    }

    const markets: Nestable[] =
      this.dataCentersService.getGroupedBuildingsByMarket(
        this.datacenters,
        true
      );
    this.mapService.addMarketAreaData(markets);

    this.store.dispatch(
      mapConfigActions.loadMapSource({
        markets: markets,
        mapLines: userMap.mapLines,
        mapPolygons: userMap.mapPolygons,
      })
    );
    this.mapCreationDate = userMap?.creationDate;
    this.mapFilesSelector
      .getMapFiles()
      .pipe(
        filter((mapFiles: MapFile[]) => !!mapFiles?.length),
        takeUntil(this.destroy$),
        tap((mapFiles: MapFile[]) => this.onMapFilesSelectionChanged(mapFiles))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.store.dispatch(mapFilesActions.nukeStore());
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
    if (this.isFullscreen) {
      this.exitFullscreen();
    }
  }

  onMwThresholdChange(mwThreshold: number) {
    this.mwThreshold = mwThreshold;
  }

  onMwThresholdValueChange(event) {
    this.store.dispatch(
      mapConfigActions.mwThresholdChange({
        value: event.value,
      })
    );
  }

  onloadmap(map) {
    this.map = map.target;
    this.map.addControl(this.navigationControl);
    this.map.addControl(new CustomFullscreenControl(this.toggleFullscreen.bind(this)));
    this.map.addControl(this.draw);
    this.mapConfigSelector
      .getUserMap()
      .pipe(
        filter((userMap: UserMap) => !!userMap),
        take(1),
        tap((userMap: UserMap) => this.onUserMapLoaded(userMap))
      )
      .subscribe();

    let _this = this;

    this.actions$
      .pipe(
        ofType(mapConfigActions.POPUP_SAVED),
        takeUntil(this.destroy$),
        tap((data) => this.popupSaved(data))
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(mapConfigActions.POPUP_CANCELLED),
        takeUntil(this.destroy$),
        tap((data) => this.popupSaved(data))
      )
      .subscribe();

    this.mapConfigSelector
      .getMapSource()
      .pipe(
        takeUntil(this.destroy$),
        filter((source) => !!source.data),
        take(1),
        tap((data) => this.addMapData(data))
      )
      .subscribe();

    this.mapConfigSelector
      .getSelectedPolygon()
      .pipe(
        takeUntil(this.destroy$),
        filter((selected) => !!selected),
        tap((selected) => this.addPopup(selected))
      )
      .subscribe();

    this.mapConfigSelector
      .getDrawingFeatures()
      .pipe(
        takeUntil(this.destroy$),
        tap((data) => this.addDrawingToolData(data))
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(geoActions.CENTER_MAP),
        takeUntil(this.destroy$),
        tap((data) => this.centerMap((data as any).info))
      )
      .subscribe();

    this.map.on('draw.selectionchange', (event) => {
      if (event.features.length) {
        this.mapConfigSelector
          .areMarkersBlocked()
          .pipe(
            take(1),
            tap((areMArkersBlocked: boolean) => {
              if (areMArkersBlocked) {
                this.polygonControl.changeMode('simple_select');
              }
            })
          )
          .subscribe();
      }
    });
    const me = this;

    this.map.on('draw.create', (event) => {
      this.mapService.onDrawCreate(event, _this, this.polygonControl);
    });

    this.map.on('draw.update', (event) => {
      this.mapService.onDrawUpdate(event, _this, this.polygonControl);
    });

    this.map.on('draw.delete', (event) => {
      this.mapService.onDrawDelete(event, _this, this.polygonControl);
    });

    this.map.on('contextmenu', 'gl-draw-polygon-fill.cold', (event) => {
      this.mapService.onDrawRightClick(event, _this, this.polygonControl);
    });

    this.map.on('contextmenu', 'marketCircleFillLayerId', (event) => {
      this.mapService.onMarketRightClick(event, _this, this.polygonControl);
    });
    this.map.on('contextmenu', 'marketCircleLayerId', (event) => {
      this.mapService.onMarketRightClick(event, _this, this.polygonControl);
    });

    this.map.on('contextmenu', (event) => {
      if (this.currentPopup) {
        this.currentPopup.remove();
      }

      const features = this.map.queryRenderedFeatures(event.point);
      const azFeature = features.find(f => (f.layer.id.startsWith('az')));

      if (azFeature) {
        const popupElement = document.querySelector('.polygon-popup') as HTMLElement;
        if (popupElement) {
          popupElement.style.display = 'none';
        }

        const azId = azFeature.properties['azId'];
        const provider = azFeature.properties['provider'];

        const relatedDatacenters = this.datacenters.filter(dc =>
          dc.availabilityZoneId === azId
        );

        const popupContent = this.dynamicComponentService.injectComponent(
          AvailabilityZonePopupComponent,
          (component) => {
            component.provider = provider;
            component.availabilityZoneId = azId;
            component.datacenters = relatedDatacenters;
          }
        );

        this.currentPopup = new mapboxgl.Popup({
          closeButton: true,
          closeOnClick: true,
          className: 'az-popup',
          maxWidth: '300px',
          focusAfterOpen: true
        })
          .setLngLat(event.lngLat)
          .setDOMContent(popupContent)
          .addTo(this.map);
      }
      this.appRef.tick();
    });

    this.map.on('style.load', (event) => {
      this.mapConfigSelector
        .getMapSource()
        .pipe(
          take(1),
          filter((source) => !!source.data),
          take(1),
          tap((data) => this.addMapData(data))
        )
        .subscribe();
    });

    this.map.on('click', (event) => {
      if (this.polygonControl.getMode() == 'draw_line_string') {
        this.polygonControl.getAll().features.forEach((f) => {
          if (f.geometry.type == 'LineString') {
            if (f.geometry.coordinates.length == 3) {
              this.polygonControl.changeMode('simple_select');
            }
          }
        });
      }
    });

    this.map.on('zoomend', () => {
    });

    this.map.on('moveend', () => {
      this.mapConfigSelector
        .getSelectedDataCenters()
        .pipe(
          take(1),
          tap((datacenters: Datacenter[]) =>
            this.getProvidersInsideView(
              this.map.getBounds() as any,
              datacenters
            )
          )
        )
        .subscribe();
    });

    this.map.on('idle', () => {
    });
    this.mapConfigSelector
      .areMarkersBlocked()
      .pipe(
        takeUntil(this.destroy$),
        tap((areBlocked: boolean) => {
          this.areMarkersBlocked = areBlocked;
          if (this.areMarkersBlocked) {
            (
              document.getElementsByClassName('mapboxgl-ctrl-group')[2] as any
            ).style.display = 'none';
          } else {
            (
              document.getElementsByClassName('mapboxgl-ctrl-group')[2] as any
            ).style.display = '';
          }
        })
      )
      .subscribe();
    this.legendCollapsed = false;
  }

  getProvidersInsideView(coord: any, datacenters: Datacenter[]) {
    const bbox = [coord._sw.lng, coord._sw.lat, coord._ne.lng, coord._ne.lat];
    let poly = turf.bboxPolygon(bbox as any);
    //poly = turf.simplify(turf.buffer(poly, -900, {units: 'kilometers'}))
    const dcInside: Datacenter[] = [];
    for (const dc of datacenters) {
      const point = turf.point([dc.lon, dc.lat]);
      const isInside = turf.booleanPointInPolygon(point, poly);
      dc.isProviderHidden = !isInside;
      dcInside.push(dc);
    }
    this.store.dispatch(mapConfigActions.zoomEnd({datacenters: dcInside}));
  }

  addPopup(selectedPolygon: MapPolygon) {
    if (this.polygonPopup) {
      this.polygonPopup.remove();
    }

    const polygonModel: MapPolygon = {
      ...selectedPolygon
    };

    const popupContent = this.createPopupContent(polygonModel);

    this.polygonPopup = new mapboxgl.Popup({closeButton: false, className: 'polygon-popup'})
      .setLngLat(polygonModel.center as any)
      .setDOMContent(popupContent)
      .addTo(this.map);

    this.appRef.tick();
  }

  private createPopupContent(polygonModel: MapPolygon): HTMLElement {
    return this.dynamicComponentService.injectComponent(
      PolygonPopupComponent,
      (component) => component.polygon = polygonModel
    );
  }


  popupSaved(data) {
    if (data.polygonData) {
      this.polygonControl.setFeatureProperty(
        data.polygonData.auxId,
        'color',
        data.polygonData.color
      );

      this.polygonControl.setFeatureProperty(
        data.polygonData.auxId,
        'opacity',
        data.polygonData.opacity
      );

      this.polygonControl.setFeatureProperty(
        data.polygonData.auxId,
        'user_opacity',
        data.polygonData.opacity
      );

      this.polygonControl.add(this.polygonControl.get(data.polygonData.auxId));
    }
    this.polygonPopup.remove();
    this.appRef.tick();
  }

  toggleAreaCircles() {
    this.paintAreas = !this.paintAreas;
    this.map.setLayoutProperty(
      MARKET_LABEL_LAYER_ID,
      'visibility',
      this.paintAreas ? 'visible' : 'none'
    );
    this.map.setLayoutProperty(
      MARKET_CIRCLE_LAYER_ID,
      'visibility',
      this.paintAreas ? 'visible' : 'none'
    );
  }

  toggleLines(isDisplaying) {
    this.displayLines = isDisplaying;
    this.map.setLayoutProperty(
      DISTANCE_LAYER_ID,
      'visibility',
      isDisplaying ? 'visible' : 'none'
    );
  }

  togglePolygons(isDisplaying) {
    this.displayPolygons = isDisplaying;
    this.map.setLayoutProperty(
      POLYGON_LAYER_ID,
      'visibility',
      isDisplaying ? 'visible' : 'none'
    );
  }

  toggleInfoSquare() {
    this.displayInfo = !this.displayInfo;
    if (this.map) {
      this.map.setLayoutProperty(
        SQUARE_INFO_TEXT_LAYER_ID,
        'visibility',
        this.displayInfo ? 'visible' : 'none'
      );
      this.store.dispatch(mapConfigActions.toggleInfoLayer({visible: this.displayInfo}));
    }
  }

  toggleMapControls() {
    this.displayControls = !this.displayControls;
    if (this.displayControls) {
      this.map.addControl(this.navigationControl);
      this.map.addControl(this.fullscreenControl);
      this.map.addControl(this.polygonControl);
    } else {
      this.map.removeControl(this.navigationControl);
      this.map.removeControl(this.fullscreenControl);
      this.map.removeControl(this.polygonControl);
    }
  }

  updateMapData(source) {
    const gjSource = this.map.getSource(SOURCE_ID) as any;
    gjSource.setData(source.data);
  }

  addMapData(source) {
    this.map.addSource(SOURCE_ID, source);
    //add layer for circle lines
    this.map.addLayer(
      this.mapService.createLineLayer(
        MARKET_CIRCLE_LAYER_ID,
        SOURCE_ID,
        '#007cbf',
        2,
        [2, 2]
      )
    );
    this.map.addLayer(
      this.mapService.createFillLayer(
        MARKET_CIRCLE_FILL_LAYER_ID,
        SOURCE_ID,
        '#007cbf',
        2,
        [2, 2]
      )
    );
    //this.map.addLayer(this.mapService.createInfoSquareLayer(SQUARE_INFO_FILL_LAYER_ID, SOURCE_ID, "#007cbf", 2, [2, 2]));
    //add layer for circle label
    this.map.addLayer(
      this.mapService.createLabelLayer(
        MARKET_LABEL_LAYER_ID,
        SOURCE_ID,
        {
          'text-color': '#007cbf',
          'text-opacity': 0.5,
          'text-halo-blur': 50,
          'text-halo-color': '#FFFFFF',
          'text-halo-width': 10,
        },
        {
          'text-field': ['get', 'label'],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-anchor': 'center',
        },
        MARKET_LABEL_SOURCE_TYPE
      )
    );

    //add layer for info square text
    this.map.addLayer(
      this.mapService.createLabelLayer(
        SQUARE_INFO_TEXT_LAYER_ID,
        SOURCE_ID,
        {
          'text-color': '#000000',
          'text-opacity': 1,
          'text-halo-color': '#FFFFFF',
          'text-halo-width': 100,
        },
        {
          'symbol-placement': 'point',
          'text-max-width': 10,
          'text-field': ['get', 'label'],
          'text-justify': 'left',
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-anchor': 'center',
        },
        INFO_SQUARE_TEXT_SOURCE_TYPE
      )
    );

    //add layer for distance label
    this.map.addLayer(
      this.mapService.createLabelLayer(
        DISTANCE_LAYER_ID,
        SOURCE_ID,
        {
          'text-color': '#038c33',
          'text-halo-color': '#FFFFFF',
          'text-halo-width': 3,
        },
        {
          'text-field': ['get', 'label'],
          'text-size': 16,
          'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
          'symbol-placement': 'line-center',
          'text-anchor': 'center',
          'text-offset': [0, 1.5],
          'text-max-width': 40,
          'text-allow-overlap': true,
          'text-ignore-placement': true
        },
        DISTANCE_LABEL_SOURCE_TYPE
      )
    );

    this.map.addLayer(
      this.mapService.createLabelLayer(
        POLYGON_LAYER_ID,
        SOURCE_ID,
        {
          'text-color': '#007cbf',
        },
        {
          'text-field': ['get', 'label'],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-anchor': 'center',
        },
        POLYGON_LABEL_SOURCE_TYPE
      )
    );

    this.mapConfigSelector
      .idDisplayingPolygons()
      .pipe(
        takeUntil(this.destroy$),
        tap((data) => this.togglePolygons(data))
      )
      .subscribe();
    this.mapConfigSelector
      .idDisplayingLines()
      .pipe(
        takeUntil(this.destroy$),
        tap((data) => this.toggleLines(data))
      )
      .subscribe();

    this.mapConfigSelector
      .getMapSource()
      .pipe(
        takeUntil(this.destroy$),
        filter((source) => !!source.data),
        skip(1),
        tap((data) => this.updateMapData(data))
      )
      .subscribe();

    this.mapConfigSelector
      .getInfoLayerVisibility()
      .pipe(take(1))
      .subscribe(visible => {
        this.displayInfo = visible;
        this.map.setLayoutProperty(
          SQUARE_INFO_TEXT_LAYER_ID,
          'visibility',
          visible ? 'visible' : 'none'
        );
      });
  }

  onMapFilesSelectionChanged(mapFiles: MapFile[]) {
    mapFiles.forEach((mapFile: MapFile) => {
      const sourceId = `${mapFile.tilesetId}`;
      const layerIdPoint = `${mapFile.fileId}_LAYER_ID_Point`;
      const layerIdLine = `${mapFile.fileId}_LAYER_ID_Line`;
      const layerIdPolygon = `${mapFile.fileId}_LAYER_ID_Polygon`;
      const layerIdFill = `${mapFile.fileId}_LAYER_ID_Fill`;
      const layerIdFillLine = `${mapFile.fileId}_LAYER_ID_Fill_Line`;
      const existingSource = this.map.getSource(sourceId);
      const existingPointLayer = this.map.getLayer(layerIdPoint);
      const existingLineLayer = this.map.getLayer(layerIdLine);
      const existingPolygonLayer = this.map.getLayer(layerIdPolygon);
      const existingFillLayer = this.map.getLayer(layerIdFill);
      const existingFillLine = this.map.getLayer(layerIdFillLine);
      if (existingPointLayer) {
        this.map.off('click', layerIdPoint, this.onClickTileset);
        this.map.removeLayer(layerIdPoint);
      }
      if (existingFillLine) {
        this.map.off('click', layerIdFillLine, this.onClickTileset);
        this.map.removeLayer(layerIdFillLine);
      }
      if (existingFillLayer) {
        this.map.off('click', layerIdFill, this.onClickTileset);
        this.map.removeLayer(layerIdFill);
      }
      if (existingLineLayer) {
        this.map.off('click', layerIdLine, this.onClickTileset);
        this.map.removeLayer(layerIdLine);
      }
      if (existingPolygonLayer) {
        this.map.off('click', layerIdPolygon, this.onClickTileset);
        this.map.removeLayer(layerIdPolygon);
      }
      if (existingSource) {
        this.map.removeSource(sourceId);
      }
      if (mapFile.selected) {
        this.map.addSource(sourceId, {
          "url": `mapbox://${mapFile.tilesetId}`,
          "type": "vector"
        });
        this.map.addLayer(
          {
            'id': layerIdFillLine,
            'type': 'line',
            'source': sourceId,
            'filter': ["==", ["geometry-type"], "Polygon"],
            'source-layer': 'layer1',
            'layout': {
              'line-join': 'round',
              'line-cap': 'round'
            },
            'paint': {
              'line-color': mapFile.color,
              'line-width': 1
            }
          }
        );
        this.map.addLayer(
          {
            'id': layerIdLine,
            'type': 'line',
            'source': sourceId,
            'filter': ["==", ["geometry-type"], "LineString"],
            'source-layer': 'layer1',
            'layout': {
              'line-join': 'round',
              'line-cap': 'round'
            },
            'paint': {
              'line-color': mapFile.color,
              'line-width': 1
            }
          }
        );
        this.map.addLayer(
          {
            'id': layerIdPoint,
            'type': 'circle',
            'source': sourceId,
            'filter': ["==", ["geometry-type"], "Point"],
            'source-layer': 'layer1',
            'layout': {},
            'paint': {
              'circle-color': mapFile.color
            }
          }
        );
        this.map.addLayer(
          {
            'id': layerIdFill,
            'type': 'fill',
            'source': sourceId,
            'filter': ["==", ["geometry-type"], "Polygon"],
            'source-layer': 'layer1',
            'layout': {},
            'paint': {
              'fill-color': "#c3ff69",
              'fill-opacity': 0.2
            }
          }
        );

        this.map.on('click', layerIdLine, this.onClickTileset);
        this.map.on('click', layerIdPoint, this.onClickTileset);
        this.map.on('click', layerIdPolygon, this.onClickTileset);
        this.map.on('click', layerIdFill, this.onClickTileset);
        this.map.on('click', layerIdFillLine, this.onClickTileset);
      }
    });
  }

  onClickTileset = (e) => {
    if (!this.displayTilesetMetadata && (!this.tilesetId || this.tilesetId != e.features[0].layer.id)) {
      this.tilesetId = e.features[0].layer.source;
      this.store.dispatch(mapFilesActions.getFileMetadata({tilesetId: this.tilesetId}));
    }
  }

  updateAreaData(datacenters: Datacenter[]) {
    const existingDatacenters = _.cloneDeep(datacenters);
    const areas: Nestable[] =
      this.dataCentersService.getGroupedBuildingsByMarket(
        existingDatacenters,
        true
      );
    this.mapService.addMarketAreaData(areas);
    this.store.dispatch(mapConfigActions.updateAreaData({areas}));
  }

  addDrawingToolData(featureCollection) {
    this.polygonControl.set(featureCollection);
  }

  changeStyle(layerId: string) {
    this.style = `mapbox://styles/mapbox/${layerId}-v9`;
    this.store.dispatch(mapConfigActions.mapStyleChanged({layerId: layerId}));
    setTimeout(() => {
      this.store.dispatch(mapFilesActions.reloadFiles());
    }, 1000);

  }

  back() {
    this.onBack.emit();
  }

  toggleCollapse() {
    if (!this.legendCollapsed) {
      this.lastKnownWidth = this.resizableContainer.nativeElement.style.width;
    }
    this.legendCollapsed = !this.legendCollapsed;
    if (!this.legendCollapsed && this.lastKnownWidth) {
      setTimeout(() => {
        this.renderer.setStyle(this.resizableContainer.nativeElement, 'width', this.lastKnownWidth);
      });
    }
  }

  toggleMapLines() {
    this.displayLines = !this.displayLines;
    if (this.displayLines) {
      this.store.dispatch(mapConfigActions.showLineFeatures());
    } else {
      this.store.dispatch(
        mapConfigActions.hideLineFeatures({
          drawControlFeatureCollection: this.polygonControl.getAll(),
        })
      );
    }
  }

  toggleMarkers() {
    this.displayMarkers = !this.displayMarkers;
  }

  toggleMapPolygons() {
    this.displayPolygons = !this.displayPolygons;
    if (this.displayPolygons) {
      this.store.dispatch(mapConfigActions.showPolygonFeatures());
    } else {
      this.store.dispatch(
        mapConfigActions.hidePolygonFeatures({
          drawControlFeatureCollection: this.polygonControl.getAll(),
        })
      );
    }
  }

  saveMapData() {
    this.store.dispatch(mapConfigActions.saveMap());
  }

  openPopup(data: DatacenterBasicInfo): DynamicDialogRef {
    return this.dialogService.open(NewDataCenterPopupComponent, {
      header: 'Data Center',
      width: '40%',
      data: {
        ...data,
        mapCreationDate: this.mapCreationDate,
      },
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      maximizable: false,
    });
  }

  addDataCenter() {
    this.openPopup(null).onClose.subscribe(
      (newMarkerData: DatacenterBasicInfo) => {
        if (newMarkerData) {
          newMarkerData.country = this.geoService.getCountryByLonLat(
            this.map.getCenter().lng,
            this.map.getCenter().lat
          );
          newMarkerData.lat = this.map.getCenter().lat;
          newMarkerData.lon = this.map.getCenter().lng;
          newMarkerData.mapId = newMarkerData.mapId ? newMarkerData.mapId : '';
          this.store.dispatch(
            mapConfigActions.addedDataCenter({
              datacenterBasicInfo: newMarkerData,
            })
          );
          this.subscribeToDatacentersChange();
        }
      }
    );
  }

  removeDatacenter(dataCenter: DatacenterBasicInfo) {
    this.store.dispatch(
      mapConfigActions.removedDataCenter({
        datacenterBasicInfo: dataCenter,
      })
    );
    this.subscribeToDatacentersChange();
  }

  modifyDatacenter(dataCenter: DatacenterBasicInfo) {
    this.openPopup(dataCenter).onClose.subscribe(
      (modifiedData: DatacenterBasicInfo) => {
        if (modifiedData) {
          modifiedData.mapId = modifiedData.mapId ? modifiedData.mapId : '';
          this.store.dispatch(
            mapConfigActions.modifiedDataCenter({
              datacenterBasicInfo: modifiedData,
            })
          );
          this.subscribeToDatacentersChange();
        }
      }
    );
    this.appRef.tick();
  }

  centerMap(info: GeoInfo) {
    if (info.zoom) {
      this.map.flyTo({
        center: [info.longitude, info.latitude],
        zoom: info.zoom,
        essential: true,
      });
    } else {
      this.map.flyTo({
        center: [info.longitude, info.latitude],
        essential: true,
      });
    }
  }

  onResizing(event: ResizeEvent): void {
    this.isResizing = true;
    if (event.rectangle.width && Math.abs(event.rectangle.width - this.resizeStartX) > 5) {
      this.ngZone.runOutsideAngular(() => {
        this.renderer.setStyle(this.resizableContainer.nativeElement, 'width', `${event.rectangle.width}px`);
      });
    }
  }

  onResizeEnd(event: ResizeEvent): void {
    if (event.rectangle.width && Math.abs(event.rectangle.width - this.resizeStartX) > 5) {
      this.lastKnownWidth = `${event.rectangle.width}px`;
      this.renderer.setStyle(this.resizableContainer.nativeElement, 'width', this.lastKnownWidth);
    }
    setTimeout(() => {
      this.isResizing = false;
    }, 0);
  }

  onResizeStart(event: ResizeEvent): void {
    this.isResizing = true;
    this.resizeStartX = event.rectangle.width;
  }

  subscribeToDatacentersChange() {
    this.mapConfigSelector
      .getSelectedDataCenters()
      .pipe(
        take(1),
        tap((datacenters: Datacenter[]) => this.updateAreaData(datacenters))
      )
      .subscribe();
  }

  increaseMarkersSize() {
    this.store.dispatch(mapConfigActions.increaseMarkersSize());
  }

  reduceMarkersSize() {
    this.store.dispatch(mapConfigActions.reduceMarkersSize());
  }

  resetMarkersSize() {
    let zoom = this.map.getZoom();
    this.store.dispatch(
      mapConfigActions.resetMarkersSize({
        zoom: zoom,
      })
    );
    this.map.triggerRepaint();
  }

  toggleMarkersDrag() {
    this.store.dispatch(mapConfigActions.toggleMarkersDrag());
  }

  unitsChanged(event) {
    this.store.dispatch(
      mapConfigActions.distanceUnitsChanged({
        units: event.checked ? DistanceUnits.MILES : DistanceUnits.KMS,
      })
    );
  }

  async triggerCsvDownload() {
    const {mapName} = await firstValueFrom(this.userMap);
    this.fileDownloader.downloadCsv(this.datacenters, mapName);
  }

  toggleFullscreen = (): void => {
    const body = document.body;
    const mainToolbar = document.getElementById('main-toolbar');

    this.isFullscreen = !this.isFullscreen;

    if (mainToolbar) {
      mainToolbar.style.display = this.isFullscreen ? 'none' : 'block';
    }

    if (this.isFullscreen) {
      this.enterFullscreen(body);
    } else {
      this.exitFullscreen();
    }

    this.resizeMapAfterDelay();
  }

  private enterFullscreen(element: HTMLElement): void {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if ((element as any).mozRequestFullScreen) {
      (element as any).mozRequestFullScreen();
    } else if ((element as any).webkitRequestFullscreen) {
      (element as any).webkitRequestFullscreen();
    } else if ((element as any).msRequestFullscreen) {
      (element as any).msRequestFullscreen();
    }
  }

  private exitFullscreen(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) {
      (document as any).msExitFullscreen();
    }
  }

  private resizeMapAfterDelay(): void {
    setTimeout(() => {
      if (this.map) {
        this.map.resize();
      }
      this.cdr.detectChanges();
    }, 200);
  }

  handleFullscreenChange = () => {
    this.isFullscreen = !!document.fullscreenElement;
    setTimeout(() => {
      if (this.map) {
        this.map.resize();
      }
      this.cdr.detectChanges();
    }, 200);
  }

  showLayerPopup(layer: MapLayer) {
    if (this.layerPopup) {
      this.layerPopup.remove();
    }

    const popupContent = this.dynamicComponentService.injectComponent(
      MapLayerPopupComponent,
      (component) => {
        component.layer = layer;
      }
    );

    this.layerPopup = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: false,
      className: 'map-layer-popup'
    })
      .setLngLat(this.map.getCenter())
      .setDOMContent(popupContent)
      .addTo(this.map);
  }

  onMetadataClose() {
    this.displayTilesetMetadata = false;
    this.tilesetId = "";
  }

  get metadataFields(): String [] {
    const fields = this.fileMetadata?.fields || [];
    const labels: String[] = [];
    for(const key in fields) {
      labels.push(key);
    }
    return labels;
  }
}

class CustomFullscreenControl implements mapboxgl.IControl {
  private container: HTMLElement;
  private fullscreenButton: HTMLButtonElement;
  private map: mapboxgl.Map;
  private toggleFullscreen: () => void;
  private isFullscreen: boolean = false;

  constructor(toggleFullscreen: () => void) {
    this.toggleFullscreen = toggleFullscreen;
  }

  onAdd(map: mapboxgl.Map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this.fullscreenButton = document.createElement('button');
    this.fullscreenButton.className = 'mapboxgl-ctrl-fullscreen';
    this.fullscreenButton.type = 'button';
    this.fullscreenButton.setAttribute('aria-label', 'Toggle fullscreen');
    this.fullscreenButton.onclick = () => {
      this.isFullscreen = !this.isFullscreen;
      this.updateButtonState();
      this.toggleFullscreen();
    };
    this.container.appendChild(this.fullscreenButton);
    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }

  private updateButtonState() {
    if (this.isFullscreen) {
      this.fullscreenButton.className = 'mapboxgl-ctrl-shrink';
      this.fullscreenButton.setAttribute('aria-label', 'Exit fullscreen');
    } else {
      this.fullscreenButton.className = 'mapboxgl-ctrl-fullscreen';
      this.fullscreenButton.setAttribute('aria-label', 'Enter fullscreen');
    }
  }
}
