import {
  ApplicationRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {filter, interval, Observable, Subject, Subscription, takeUntil, tap, take} from "rxjs";
import {Datacenter, DatacenterBasicInfo} from "../../core/model/datacenter";
import {MapConfigSelector} from "../../core/ngrx/selectors/map-config.selector";
import {Actions, ofType} from "@ngrx/effects";
import {Marker} from "mapbox-gl";

import {DialogService} from "primeng/dynamicdialog";
import {GeoService} from "../../core/service/geo.service";
import {geoActions, mapConfigActions, usersActions} from 'src/app/core/ngrx/actions';
import {Store} from "@ngrx/store";
import {GeoInfo} from "../../core/model/geo-info";
import {Clipboard} from "@angular/cdk/clipboard";
import {environment} from "../../../environments/environment";
import {AppStateService} from "../../core/service/app-state.service";
import {ActivatedRoute} from "@angular/router";
import {MarkerComponent} from "ngx-mapbox-gl";
import {getFromDataList} from "../../core/util/transform.util";
import * as _ from "lodash";
import {UsersSelector} from "../../core/ngrx/selectors/users.selector";
import {MapboxUser} from "../../core/model/mapbox-user";

@Component({
    selector: 'app-map-markers',
    templateUrl: './map-markers.component.html',
    styleUrls: ['./map-markers.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MapMarkersComponent implements OnInit, OnDestroy {

  datacentersToDisplay: DatacenterBasicInfo[] = [];
  subscription: Subscription;
  @Output()
  onModify: EventEmitter<DatacenterBasicInfo> = new EventEmitter<DatacenterBasicInfo>();

  @Output()
  onRemove: EventEmitter<DatacenterBasicInfo> = new EventEmitter<DatacenterBasicInfo>();
  @ViewChildren('myMarker') components: QueryList<MarkerComponent>;
  scale: number = 1;
  alreadyCentered: boolean = false;

  areMarkersBlocked: Observable<boolean> = this.mapConfigSelector.areMarkersBlocked();

  constructor(private mapConfigSelector: MapConfigSelector, private actions$: Actions, private route: ActivatedRoute,
              private appRef: ApplicationRef, public dialogService: DialogService, private appStateService: AppStateService,
              private geoService: GeoService, private readonly store: Store, private clipboard: Clipboard,
              private usersSelector: UsersSelector) {
  }

  destroy$: Subject<boolean> = new Subject<boolean>();
  mapCreationDate: Date;
  modifiedByDisplayName: string;

  private users: MapboxUser[] = [];

  ngOnInit(): void {
    this.mapConfigSelector.getScale().pipe(
      takeUntil(this.destroy$),
      filter(scale => !!scale),
      tap(scale => this.handleScale(scale))
    ).subscribe();
    this.actions$.pipe(
      ofType(geoActions.CENTER_MAP),
      takeUntil(this.destroy$),
      tap(data => this.onCenterMap((data as any).info))
    ).subscribe();

    // Dispatch action to load users
    this.store.dispatch(usersActions.getUsers());

    // Subscribe to users
    this.usersSelector.getUsers().pipe(
      takeUntil(this.destroy$),
      tap(users => {
        this.users = users || [];
        if (this.datacentersToDisplay?.length > 0) {
          this.datacentersToDisplay.forEach(item => {
            this.updateModifiedByDisplayName(item);
          });
        }
      })
    ).subscribe();

    this.mapConfigSelector.getUserMap().pipe(
      takeUntil(this.destroy$),
      tap(userMap => this.mapCreationDate = userMap?.creationDate ? new Date(userMap.creationDate) : null)
    ).subscribe();
  }

  handleScale(scale) {
    this.scale = scale;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  @Input()
  set datacenters(datacenters: DatacenterBasicInfo[]) {
    const incomingElements = datacenters.filter(dc => !dc.isHidden).length;
    const existingElements = this.datacentersToDisplay.length;
    if ((incomingElements != existingElements || this.hasDatacenterChanged(datacenters.filter(dc => !dc.isHidden)))) {
      this.datacentersToDisplay = datacenters.filter(dc => !dc.isHidden);
      this.datacentersToDisplay.forEach(item => {
        this.updateModifiedByDisplayName(item);
      });
      
      let lat = this.route.snapshot.queryParams["lat"];
      let lon = this.route.snapshot.queryParams["lon"];
      if (lat && lon && !this.alreadyCentered) {
        this.alreadyCentered = true;
        setTimeout(() => {
          const geoInfo: GeoInfo = {
            desc: "", id: "",
            name: "",
            latitude: lat,
            longitude: lon,
            zoom: 12
          };
          this.store.dispatch(geoActions.centerMap({info: geoInfo}));
        }, 1000);
      }
    }
  }

  hasDatacenterChanged(datacenters: DatacenterBasicInfo[]) {

    for (let dc of datacenters) {
      let toCompare = getFromDataList(this.datacentersToDisplay as Datacenter[], dc);
      if (toCompare == null) {
        return true;
      } else {
        const areEquals = _.isEqual(dc, toCompare);
        if (!areEquals) {
          return true;
        }
      }
    }
    return false;
  }

  modifyDatacenter(dataCenter: DatacenterBasicInfo) {
    this.onModify.emit(dataCenter);
  }

  removeDatacenter(dataCenter: DatacenterBasicInfo) {
    //this.components.toArray()[0].togglePopup();
    this.onRemove.emit(dataCenter);
  }

  copyMarkerInfo(dataCenter: DatacenterBasicInfo) {
    let userId = this.route.snapshot.params["userId"];
    let mapId = this.route.snapshot.params["mapId"];
    const toShare = `${environment.envUrl}/map-config/${userId}/${mapId}?lat=${dataCenter.lat}&lon=${dataCenter.lon}`;
    this.clipboard.copy(toShare);
  }

  onDragEnd(marker: Marker, data: DatacenterBasicInfo) {
    let datcenterInfo = _.cloneDeep(data);
    datcenterInfo.lat = marker.getLngLat().lat;
    datcenterInfo.lon = marker.getLngLat().lng;
    datcenterInfo.country = this.geoService.getCountryByLonLat(data.lon, data.lat);
    this.store.dispatch(mapConfigActions.modifiedDataCenter({
      datacenterBasicInfo: datcenterInfo
    }));
  }

  onCenterMap(info: GeoInfo) {
    if (info.zoom) {
      const executions = interval(500);
      this.subscription = executions.subscribe(val => {
          if (this.components.toArray().length) {
            this.components.toArray().forEach(m => {
              const casted = m as any;
              if (casted.lngLat[1] == info.latitude && casted.lngLat[0] == info.longitude) {
                if (!m.markerInstance.getPopup().isOpen()) {
                  m.togglePopup();
                }
              } else if (m.markerInstance.getPopup().isOpen()) {
                m.togglePopup();
              }
            });
            this.subscription.unsubscribe();
          }
        }
      );

    }
  }

  getUserDisplayName(userId: string): string {
    const user = this.users.find(u => u.userId === userId);
    if (!user) {
      return userId;
    }
    return user.fullName || user.email || userId;
  }

  updateModifiedByDisplayName(item: DatacenterBasicInfo) {
    if (item?.modifiedBy && this.users?.length > 0) {
      const user = this.users.find(u => u.userId === item.modifiedBy);
      if (user) {
        this.modifiedByDisplayName = user.fullName || user.email || user.userId;
      }
    }
  }
}
