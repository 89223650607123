<div class="az-sidebar sidebar-inner-content">
  <div class="sidebar-inner-content">
    <p-table #dt1 id="azHeader" [scrollable]="true" [lazy]="false" dataKey="azKey">
      <ng-template pTemplate="caption">
        <div class="flex search-header">
          <span class="p-input-icon-left col-12">
            <input class="header-search" pInputText type="text" (input)="filter(dt1, $event)"
                   placeholder="Search keyword"/>
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th colspan="3" style="width:100%;height: 20px;"></th>
          <th style="width:60px;">
            <button class="btn-custom-outlined" (click)="toggleAllVisibility()" [style.visibility]="isSearching ? 'hidden' : 'visible'">
              {{ allLayersVisible ? 'Hide' : 'Show' }} all
            </button>
          </th>
        </tr>
      </ng-template>
    </p-table>
    <div class="az-layers">
      <ng-container *ngFor="let group of groupedLayers$ | async">
        <div class="az-group">
          <div class="group-header">
            <span class="group-title">{{group.azId}}</span>
          </div>
          <div class="group-content">
            <div *ngFor="let layer of group.layers" class="az-layer">
              <div class="layer-header">
                <div class="layer-info">
                  <p-colorPicker
                    [appendTo]="'body'"
                    [(ngModel)]="layer.color"
                    (onHide)="onHide()"
                    (onChange)="colorChanged($event, layer)">
                  </p-colorPicker>
                  <span class="layer-name">{{layer.provider}}</span>
                </div>
                <a (click)="toggleAZVisibility(layer)"
                   style="cursor: pointer; text-decoration: none; margin-top: 3px"
                   [class]="(layer.isVisible && allLayersVisible) ? 'pi pi-eye' : 'pi pi-eye-slash'">
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
