import {ApplicationRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Datacenter} from "../../core/model/datacenter";
import {Nestable} from "../../core/model/summary";
import {MapConfigSelector} from "../../core/ngrx/selectors/map-config.selector";
import {Store} from "@ngrx/store";
import {Observable, Subject, takeUntil, tap} from 'rxjs';
import {geoActions, mapConfigActions} from "../../core/ngrx/actions";
import {Actions, ofType} from "@ngrx/effects";
import {GeoInfo} from "../../core/model/geo-info";
import * as _ from "lodash";
import {CHANGED_DATACENTER_COLOR} from "../../core/ngrx/actions/map-config.actions";

@Component({
    selector: 'app-providers-datacenters-details',
    templateUrl: './providers-datacenters-details.component.html',
    styleUrls: ['./providers-datacenters-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ProvidersDatacentersDetailsComponent implements OnInit, OnDestroy {

  public selectedProviders: Nestable[] = [];
  public selectedProvidersBack: Nestable[] = [];
  public currentFilter: string[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private mapConfigSelector: MapConfigSelector, private readonly store: Store, private actions$: Actions,
              private appRef: ApplicationRef) {

  }

  ngOnInit(): void {
    this.mapConfigSelector.getSelectedProviders().pipe(
      tap((selectedProviders: Nestable[]) => {
        this.selectedProviders = _.cloneDeep(selectedProviders);
        this.selectedProviders.forEach(sp => {
          sp.children.forEach(dc => {
            dc.color = sp.color;
          })
        });
        this.selectedProvidersBack = this.selectedProviders;
      })
    ).subscribe();

    this.actions$.pipe(
      ofType(mapConfigActions.CHANGED_DATACENTER_COLOR),
      takeUntil(this.destroy$),
      tap(data => this.colorChanged(data))
    ).subscribe();

    this.actions$.pipe(
      ofType(mapConfigActions.ZOOM_END),
      takeUntil(this.destroy$),
      tap(event => this.filterDatacentersOnZoomEnd(event))
    ).subscribe();

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  overBuilding(building: Datacenter) {
    this.store.dispatch(mapConfigActions.datacenterHoverChange({
      hover: true,
      value: building.locationId,
      isProvider: false
    }));
  }

  leaveBuilding(building: Datacenter) {
    this.store.dispatch(mapConfigActions.datacenterHoverChange({
      hover: false,
      value: building.locationId,
      isProvider: false
    }));
  }

  sumTotals(provider: Nestable, field: string) {
    let tot = 0;
    provider.children.forEach(p => {
      tot += p[field];
    });
    return tot;
  }

  goToLocation(building: Datacenter) {
    const geoInfo: GeoInfo = {
      desc: "", id: "",
      name: "",
      latitude: building.lat,
      longitude: building.lon,
      zoom: 12
    };
    this.store.dispatch(geoActions.centerMap({info: geoInfo}));
  }

  colorChanged(data) {
    const changed = this.selectedProviders.find(p => p.label == data.provider);
    if (changed) {
      changed.children.forEach(dc => {
        dc.color = data.color;
      })
    }
  }

  filterDatacentersOnZoomEnd(event) {
    let selectedProviders: Nestable[] = []
    const datacentersInView: Datacenter[] = (event as any).datacenters;
    this.selectedProvidersBack.forEach(sp => {
      if (datacentersInView.find(dc => dc.provider == sp.label)) {
        const customSelectedProvider = _.cloneDeep(sp);
        customSelectedProvider.children = _.cloneDeep(
          datacentersInView
            .filter(d => d.provider == sp.label)
            .sort((a, b) => a.building.toLowerCase().localeCompare(b.building.toLowerCase()))
        );
        selectedProviders.push(customSelectedProvider);
      }
    });
    this.selectedProviders = selectedProviders.sort((a, b) => 
      a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );
    this.appRef.tick();
  }

}
