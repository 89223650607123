import {createAction, props} from "@ngrx/store";
import {MapFile} from "../../model/map-file";
import {FileMetadata} from "../../model/file-metadata";

export const LOAD_FILES = '[MAP_FILES] Load';
export const LOAD_FILES_SUCCESS = '[MAP_FILES] Load Success';
export const LOAD_FILES_FAILURE = '[MAP_FILES] Load Failure';

export const RELOAD_FILES = '[MAP_FILES] Reload';

export const FILE_TOGGLE = '[MAP_FILES] File Toggle';

export const FILE_COLOR_CHANGED = '[MAP_FILES] File color changed';
export const GET_FILE_METADATA = '[MAP_FILES] Get file metadata';
export const GET_FILE_METADATA_SUCCESS = '[MAP_FILES] Get file metadata success';
export const NUKE_STORE = '[MAP_FILES] Nuke store';

export const loadFiles = createAction(
  LOAD_FILES
);

export const reloadFiles = createAction(
  RELOAD_FILES
);

export const loadFilesSuccess = createAction(
  LOAD_FILES_SUCCESS,
  props<{ mapFiles: MapFile[] }>()
);

export const loadFilesFailure = createAction(
  LOAD_FILES_FAILURE,
  props<{ msg: string }>()
);

export const fileToggle = createAction(
  FILE_TOGGLE,
  props<{ mapFile: MapFile }>()
);

export const fileColorChanged = createAction(
  FILE_COLOR_CHANGED,
  props<{ mapFile: MapFile }>()
);

export const shareMapFile = createAction(
  '[Map Files] Share Map File',
  props<{ fileId: string, userIds: string[] }>()
);

export const shareMapFileSuccess = createAction(
  '[Map Files] Share Map File Success'
);

export const shareMapFileFailure = createAction(
  '[Map Files] Share Map File Failure',
  props<{ error: string }>()
);

export const getFileMetadata = createAction(
  GET_FILE_METADATA,
  props<{ tilesetId: string }>()
);

export const getFileMetadataSuccess = createAction(
  GET_FILE_METADATA_SUCCESS,
  props<{ metadata: FileMetadata }>()
);

export const nukeStore = createAction(
  NUKE_STORE
);
