<main #fullscreenContainer class="fullscreen-container">
  <section>
    <div #dialogContainer></div>
    <p-toolbar>
      <div class="p-toolbar-group-start">
        <div class="toolbar-top-row">
          <div class="left-section">
            <p-button
              [class.red]="legendCollapsed === true"
              class="action-button"
              (click)="toggleCollapse()"
              icon="pi pi-bars"
            ></p-button>
            <span class="usermap-name">
              Map: <b>{{ !!(userMap | async) ? (userMap | async).mapName : "" }}</b>
            </span>
            <div class="toolbar-bottom-row">
              <div class="button-group">
                <p-button
                  *ngIf="!isReadOnly"
                  [disabled]="areMarkersBlocked"
                  matTooltip="Add marker"
                  class="action-button"
                  icon="pi pi-map-marker"
                  (onClick)="addDataCenter()"
                ></p-button>
                <p-button
                  *ngIf="!isReadOnly"
                  [matTooltip]="areMarkersBlocked ? 'Unlock markers' : 'Lock markers'"
                  [class.red]="areMarkersBlocked"
                  class="action-button"
                  icon="pi pi-lock"
                  (onClick)="toggleMarkersDrag()"
                ></p-button>
                <p-button
                  *ngIf="!isReadOnly"
                  [matTooltip]="displayControls ? 'Hide map controls' : 'Show map controls'"
                  icon="pi pi-map"
                  [class.red]="!displayControls"
                  class="action-button"
                  (onClick)="toggleMapControls()"
                ></p-button>
                <p-button
                  *ngIf="!isReadOnly"
                  [matTooltip]="paintAreas ? 'Hide area circles' : 'Show area circles'"
                  icon="pi pi-circle"
                  [class.red]="!paintAreas"
                  class="action-button"
                  (onClick)="toggleAreaCircles()"
                ></p-button>
                <p-button
                  *ngIf="!isReadOnly"
                  [matTooltip]="displayLines ? 'Hide lines' : 'Show lines'"
                  icon="pi pi-arrows-h"
                  [class.red]="!displayLines"
                  class="action-button"
                  (onClick)="toggleMapLines()"
                ></p-button>
                <p-button
                  *ngIf="!isReadOnly"
                  [matTooltip]="displayPolygons ? 'Hide polygons' : 'Show polygons'"
                  icon="pi pi-share-alt"
                  [class.red]="!displayPolygons"
                  class="action-button"
                  (onClick)="toggleMapPolygons()"
                ></p-button>
                <p-button
                  *ngIf="!isReadOnly"
                  [matTooltip]="displayMarkers ? 'Hide markers' : 'Show markers'"
                  icon="pi pi-map-marker"
                  [class.red]="!displayMarkers"
                  class="action-button"
                  (onClick)="toggleMarkers()"
                ></p-button>
                <p-button
                  *ngIf="!isReadOnly"
                  [matTooltip]="displayInfo ? 'Hide info' : 'Show info'"
                  icon="pi pi-info-circle"
                  [class.red]="!displayInfo"
                  class="action-button"
                  (onClick)="toggleInfoSquare()"
                ></p-button>
                <p-button
                  class="action-button"
                  matTooltip="Back"
                  icon="pi pi-caret-left"
                  (onClick)="back()"
                ></p-button>
                <p-button
                  *ngIf="!isReadOnly"
                  class="action-button"
                  matTooltip="Save map"
                  icon="pi pi-save"
                  (onClick)="saveMapData()"
                ></p-button>
                <p-button
                  *ngIf="!isReadOnly"
                  class="action-button"
                  matTooltip="Download CSV"
                  icon="pi pi-download"
                  (onClick)="triggerCsvDownload()"
                ></p-button>
              </div>
              <div class="button-group">
                <app-geo-filter></app-geo-filter>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-toolbar>
  </section>
  <div class="card-content">
    <div
      #resizableContainer
      class="legend"
      mwlResizable
      [enableGhostResize]="true"
      (resizing)="onResizing($event)"
      (resizeEnd)="onResizeEnd($event)"
      (resizeStart)="onResizeStart($event)"
      *ngIf="!legendCollapsed"
      [class.resizing]="isResizing"
      [style.width]="lastKnownWidth"
    >
      <mat-card class="summary">
        <div class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
        <mat-card-content>
          <p-accordion value="5" id="accordionProviders">
            <p-accordion-panel value="0">
              <p-accordion-header>Availability Zones</p-accordion-header>
              <p-accordion-content>
                <app-availability-zones-sidebar></app-availability-zones-sidebar>
              </p-accordion-content>
            </p-accordion-panel>

            <p-accordion-panel value="1">
              <p-accordion-header>Land / Parcel</p-accordion-header>
              <p-accordion-content>
                <app-files-table [mapFiles]="landMapFiles | async"></app-files-table>
              </p-accordion-content>
            </p-accordion-panel>

            <p-accordion-panel value="2">
              <p-accordion-header>Networks</p-accordion-header>
              <p-accordion-content>
                <app-files-table [mapFiles]="fiberMapFiles | async"></app-files-table>
              </p-accordion-content>
            </p-accordion-panel>

            <p-accordion-panel value="3">
              <p-accordion-header>Oil / Gas</p-accordion-header>
              <p-accordion-content>
                <app-files-table [mapFiles]="oilGasMapFiles | async"></app-files-table>
              </p-accordion-content>
            </p-accordion-panel>

            <p-accordion-panel value="4">
              <p-accordion-header>Power</p-accordion-header>
              <p-accordion-content>
                <app-files-table [mapFiles]="powerMapFiles | async"></app-files-table>
              </p-accordion-content>
            </p-accordion-panel>

            <p-accordion-panel value="5">
              <p-accordion-header>Providers</p-accordion-header>
              <p-accordion-content>
                <app-providers-table></app-providers-table>
              </p-accordion-content>
            </p-accordion-panel>

            <p-accordion-panel value="6">
              <p-accordion-header>Railroad</p-accordion-header>
              <p-accordion-content>
                <app-files-table [mapFiles]="railroadMapFiles | async"></app-files-table>
              </p-accordion-content>
            </p-accordion-panel>
          </p-accordion>
        </mat-card-content>
      </mat-card>
    </div>
    <div
      class="map-container"
      [ngClass]="{
        'map-container-exp': legendCollapsed,
        'map-container-col': !legendCollapsed
      }"
    >
      <mgl-map
        [style]="style"
        [zoom]="[2]"
        [doubleClickZoom]="false"
        (mapLoad)="onloadmap($event)"
      >
        <div *ngIf="displayControls">
          <mgl-control position="top-left">
            <mat-card style="padding-right: 10px">
              <mat-radio-group
                [ngModel]="layerId"
                (ngModelChange)="changeStyle($event)"
              >
                <mat-radio-button value="basic">basic</mat-radio-button>
                <mat-radio-button value="streets">streets</mat-radio-button>
                <mat-radio-button value="bright">bright</mat-radio-button>
                <mat-radio-button value="light">light</mat-radio-button>
                <mat-radio-button value="dark">dark</mat-radio-button>
                <mat-radio-button value="satellite">satellite</mat-radio-button>
              </mat-radio-group>
            </mat-card>
          </mgl-control>
        </div>

        <mgl-marker
          *ngIf="!!(searchGeoInfoResult | async)"
          [lngLat]="[(searchGeoInfoResult | async).longitude,
          (searchGeoInfoResult | async).latitude]"
        >
        </mgl-marker>

        <app-availability-zone-circle>
        </app-availability-zone-circle>

        <app-map-markers
          *ngIf="displayMarkers"
          (onRemove)="removeDatacenter($event)"
          (onModify)="modifyDatacenter($event)"
          [datacenters]="selectedDataCenters | async"
        ></app-map-markers>
      </mgl-map>
    </div>
  </div>
  <p-toolbar>
    <div class="row" class="bottom-toolbar">
      <div class="col-4"></div>
      <div class="col-4 slider">
        <div class="slider-container">
          <p-slider
            (onSlideEnd)="onMwThresholdValueChange($event)"
            [(ngModel)]="mwThreshold"
            [step]="1"
            [max]="500"
          ></p-slider>
        </div>
        <span style="color: white">MW threshold {{ mwThreshold }}</span>
      </div>
      <div class="col-4 buttons">
        <p-toggleButton
          (onChange)="unitsChanged($event)"
          [(ngModel)]="isMiles"
          offLabel="kms"
          onLabel="miles"
        ></p-toggleButton>
        <p-button
          class="action-button"
          matTooltip="reduce markers size"
          icon="pi pi-minus-circle"
          (onClick)="reduceMarkersSize()"
        ></p-button>
        <p-button
          class="action-button"
          matTooltip="increase markers size"
          icon="pi pi-plus-circle"
          (onClick)="increaseMarkersSize()"
        ></p-button>
        <p-button
          class="action-button"
          matTooltip="reset markers size"
          icon="pi pi-times-circle"
          (onClick)="resetMarkersSize()"
        ></p-button>
      </div>
    </div>
  </p-toolbar>
</main>
<app-providers-datacenters-details></app-providers-datacenters-details>
<p-dialog header="File metadata" [closable]="false" [modal]="true"
          [(visible)]="displayTilesetMetadata"
          [style]="{ width: '25rem' }">
  @if (fileMetadata) {
    <div class="flex flex-col">
      <div>
        <span class="p-text-secondary block mb-8"><b>Description:</b> {{ fileMetadata.description }}</span>
      </div>
      <div>
        <span
          class="p-text-secondary block mb-8"><b>Creation data:</b> {{ fileMetadata.creationDate | date: 'MM/dd/yyyy' }}</span>
      </div>
      <div>
        <span class="p-text-secondary block mb-8"><b>Fields:</b></span>
      </div>
      <div style="height: 500px; overflow: auto; margin-bottom: 20px;">
      @for (field of metadataFields; track field) {
        <div style="display: flex;justify-content: center;align-items: center;">
          <span class="p-text-secondary block mb-8">{{ field }}</span>
        </div>
      }
      </div>
    </div>
  }
  <div style="float: right;" class="flex  gap-2">
    <p-button label="Close" (click)="onMetadataClose()"></p-button>
  </div>
</p-dialog>

