import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[appLocation]',
    standalone: false
})
export class LocationDirective {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,6}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  private check(value: string) {
    var regExpString =
      "^\\s*((\\d+(\\.\\d{0,6})?)|((\\d*(\\.\\d{1,6}))))\\s*$";
    return String(value).match(new RegExp(regExpString));

  }

  private run(oldValue) {
    setTimeout(() => {
      let currentValue: string = this.el.nativeElement.value;
      if (currentValue !== '' && !this.check(currentValue)) {
        this.el.nativeElement.value = oldValue;
      }
    });
  }

  constructor(private el: ElementRef) {
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) === -1) {
      this.run(this.el.nativeElement.value);
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    this.run(this.el.nativeElement.value);
  }
}
