<div class="sidebar-inner-content">
  <p-table id="providersList" #dt1 selectionMode="multiple" [(selection)]="selectedProviders" [scrollable]="true"
           scrollHeight="flex" [value]="providers" [rowHover]="true"
           (onRowSelect)="selectedProvidersChanged()" (onRowUnselect)="selectedProvidersChanged()"
           [lazy]="false" dataKey="label">
    <ng-template pTemplate="caption">
      <div class="flex search-header">
        <span class="p-input-icon-left col-12">
                <input class="header-search" pInputText type="text" (input)="filter(dt1, $event)"
                       placeholder="Search keyword"/>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width:60px;"></th>
        <th></th>
        <th style="width:60px;">
          <button class="btn-custom-outlined" (click)="toggleAll()" [style.visibility]="isSearching ? 'hidden' : 'visible'">
            {{ allProvidersHidden ? 'Show' : 'Hide' }} all
          </button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-provider>
      <tr [pSelectableRow]="provider" class="p-selectable-row">
        <td style="width:60px;">
          <p-colorPicker [appendTo]="'body'" (click)="onShow()" 
                        (onHide)="onHide()" 
                        (onChange)="colorChanged($event, provider)"
                        [(ngModel)]="provider.color">
          </p-colorPicker>
        </td>
        <td>{{provider.label}}</td>
        <td style="width:60px;">
          <a (click)="hideProvider([provider], provider.isProviderHidden)"
             style="cursor:pointer;text-decoration: none;margin-top: 3px;"
             [class]="provider.isProviderHidden ? 'pi pi-eye-slash' : 'pi pi-eye'">
          </a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
