import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Datacenter } from '../model/datacenter';

@Injectable({
  providedIn: 'root',
})
export class FileDownloaderService {
  constructor() {}

  downloadCsv(datacenters: Datacenter[]|any[], mapName: string = 'newMap') {
    const header =
      'Country,Market,Map ID,Provider,Area,Building,MW Live,MW U/C,MW Planned,MW Total,' +
      'Data Hall Live,Data Hall U/C,Data Hall Planned,Data Hall Total,' +
      'Building Area Live,Building Area U/C,Building Area Planned,Building Area Total,' +
      'Address,First Year of Ops,Latitude,Longitude,Building Tenant,Campus,Sellable IT Load,' +
      'Availability Zone ID,Product Type,Site Type,Data Source';
    const csvRows = datacenters.map((dc) => this.formatDatacenterAsCsvRow(dc));
    const csvString = [header, ...csvRows].join('\r\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const filename = this.generateFileName(mapName);
    saveAs(blob, filename);
  }

  private formatDatacenterAsCsvRow(datacenter: Datacenter): string {
    const fields = [
      datacenter.country,
      datacenter.market,
      datacenter.mapId,
      datacenter.provider,
      datacenter.area,
      datacenter.building,
      datacenter.mwLive,
      datacenter.mwUC,
      datacenter.mwPlanned,
      datacenter.mwTotal,
      datacenter.dataHallLive,
      datacenter.dataHallUC,
      datacenter.dataHallPlanned,
      datacenter.dataHallTotal,
      datacenter.buildingAreaLive,
      datacenter.buildingAreaUC,
      datacenter.buildingAreaPlanned,
      datacenter.buildingAreaTotal,
      datacenter.address,
      datacenter.firstYearOp,
      datacenter.lat,
      datacenter.lon,
      (datacenter.buildingTenant || []).join('|'),
      datacenter.campus,
      datacenter.sellableITLoad,
      datacenter.availabilityZoneId,
      (datacenter.productType || []).join('|'),
      (datacenter.siteType || []).join('|'),
      (datacenter.dataSource || []).join('|')
    ].map((field) => this.escapeField(field));
    return fields.join(',');
  }

  private escapeField(field) {
    let toConvert = '';
    if (field != null && field != undefined) {
      toConvert = field.toString();
      if (toConvert.indexOf(',') != -1) {
        toConvert = '"' + toConvert.replaceAll('"','') + '"';
      }
    }
    return toConvert;
  }

  private generateFileName(baseName: string, extension = 'csv'): string {
    const date = new Date();
    const dateString = `${date.getDate().toString().padStart(2, '0')}${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}${date.getFullYear()}`;
    return `${baseName}-${dateString}.${extension}`;
  }
}
