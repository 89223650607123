import {ApplicationRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MapPolygon} from "../../core/model/summary";
import {Store} from "@ngrx/store";
import {mapConfigActions} from "../../core/ngrx/actions";
import {DEFAULT_POLYGON_COLOR, DEFAULT_POLYGON_OPACITY} from "../../core/consts/map.constants";

@Component({
    selector: 'app-polygon-popup',
    templateUrl: './polygon-popup.component.html',
    styleUrls: ['./polygon-popup.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PolygonPopupComponent implements OnInit, OnDestroy {
  totalMW = 0;
  totalUC = 0;
  totalPlanned = 0;
  total = 0;
  public polygon: MapPolygon;
  hideDatacenters: boolean = false;
  isModifying = false;
  isReadOnly: boolean = false;
  selectedColor = DEFAULT_POLYGON_COLOR;
  areaName = '';

  constructor(private readonly store: Store, private appRef: ApplicationRef) {
  }

  ngOnInit(): void {
    this.polygon.datacentersInside.forEach(dc => {
      this.totalMW += dc.mwLive;
      this.totalUC += dc.mwUC;
      this.totalPlanned += dc.mwPlanned;
      this.areaName = dc.area;
    });
    this.total = this.totalMW + this.totalUC + this.totalPlanned;
    this.isModifying = this.polygon.isModifying;
    this.isReadOnly = this.polygon.isReadOnly;
    this.polygon.opacity = !this.polygon.opacity ? DEFAULT_POLYGON_OPACITY : this.polygon.opacity;
  }

  ngOnDestroy(): void {
  }

  tabChanged() {
    this.appRef.tick();
  }

  save() {
    this.store.dispatch(mapConfigActions.popupSaved({
      polygonData: this.polygon
    }));
  }

  close() {
    this.store.dispatch(mapConfigActions.popupCancelled());
  }

  toggleDataCentersVisibility() {
    this.store.dispatch(mapConfigActions.toggleDatacenters({
      hideDatacenters: this.polygon.hideDatacenters,
      auxId: this.polygon.auxId
    }));
    this.appRef.tick();
  }

  toggleModifying() {
    this.isModifying = !this.isModifying;
    this.appRef.tick();
  }
}
