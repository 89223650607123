import hat from 'hat';
import arc from 'arc';
import { distance as distance$1, destinationPoint as destinationPoint$1, midpoint, initialBearing } from 'geodesy-fn';
const cursors = {
  ADD: 'add',
  MOVE: 'move',
  DRAG: 'drag',
  POINTER: 'pointer',
  NONE: 'none'
};
const geojsonTypes = {
  FEATURE: 'Feature',
  POLYGON: 'Polygon',
  LINE_STRING: 'LineString',
  POINT: 'Point',
  FEATURE_COLLECTION: 'FeatureCollection',
  MULTI_PREFIX: 'Multi',
  MULTI_POINT: 'MultiPoint',
  MULTI_LINE_STRING: 'MultiLineString',
  MULTI_POLYGON: 'MultiPolygon'
};
const modes$1 = {
  DRAW_LINE_STRING: 'draw_line_string',
  DRAW_POLYGON: 'draw_polygon',
  DRAW_POINT: 'draw_point',
  SIMPLE_SELECT: 'simple_select',
  DIRECT_SELECT: 'direct_select',
  STATIC: 'static'
};
const events = {
  CREATE: 'draw.create',
  DELETE: 'draw.delete',
  UPDATE: 'draw.update',
  SELECTION_CHANGE: 'draw.selectionchange',
  MODE_CHANGE: 'draw.modechange',
  ACTIONABLE: 'draw.actionable',
  RENDER: 'draw.render',
  COMBINE_FEATURES: 'draw.combine',
  UNCOMBINE_FEATURES: 'draw.uncombine'
};
const meta = {
  FEATURE: 'feature',
  MIDPOINT: 'midpoint',
  VERTEX: 'vertex'
};
const activeStates = {
  ACTIVE: 'true',
  INACTIVE: 'false'
};
const modes = {
  ...modes$1,
  DRAW_CIRCLE: 'draw_circle'
};
const properties = {
  CIRCLE_RADIUS: 'circleRadius',
  CIRCLE_HANDLE_BEARING: 'circleHandleBearing'
};

/**
 * Returns GeoJSON for a Point representing the
 * vertex of another feature.
 *
 * @param {string} parentId
 * @param {Array<number>} coordinates
 * @param {string} path - Dot-separated numbers indicating exactly
 *   where the point exists within its parent feature's coordinates.
 * @param {boolean} selected
 * @return {GeoJSON} Point
 */
function createVertex(parentId, coordinates, path, selected) {
  return {
    type: geojsonTypes.FEATURE,
    properties: {
      meta: meta.VERTEX,
      parent: parentId,
      coord_path: path,
      active: selected ? activeStates.ACTIVE : activeStates.INACTIVE
    },
    geometry: {
      type: geojsonTypes.POINT,
      coordinates
    }
  };
}
function createCircle(center, radius) {
  let properties$1 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  if (!(radius > 0)) {
    throw new Error('Radius has to be greater then 0');
  }
  return {
    id: hat(),
    type: geojsonTypes.FEATURE,
    properties: {
      [properties.CIRCLE_RADIUS]: radius,
      // km
      ...properties$1
    },
    geometry: {
      type: geojsonTypes.POLYGON,
      coordinates: [[center, center, center, center]] // valid polygon needs 3 vertices
    }
  };
}
function isCircleByTypeAndProperties(type, properties$1) {
  return type === geojsonTypes.POLYGON && typeof properties$1[properties.CIRCLE_RADIUS] === 'number' && properties$1[properties.CIRCLE_RADIUS] > 0;
}
function isCircle(geojson) {
  return isCircleByTypeAndProperties(geojson.geometry.type, geojson.properties);
}
function getCircleCenter(geojson) {
  if (!isCircle(geojson)) {
    throw new Error('GeoJSON is not a circle');
  }
  return geojson.geometry.coordinates[0][0];
}
function setCircleCenter(geojson, center) {
  if (!isCircle(geojson)) {
    throw new Error('GeoJSON is not a circle');
  }
  geojson.geometry.coordinates = [[center, center, center, center]]; // valid polygon needs 3 vertices
}
function getCircleRadius(geojson) {
  if (!isCircle(geojson)) {
    throw new Error('GeoJSON is not a circle');
  }
  return geojson.properties[properties.CIRCLE_RADIUS];
}
function setCircleRadius(geojson, radius) {
  if (!isCircle(geojson)) {
    throw new Error('GeoJSON is not a circle');
  }
  geojson.properties[properties.CIRCLE_RADIUS] = radius;
}
function coordinatesEqual(x, y) {
  return x[0] === y[0] && x[1] === y[1];
}
function coordinatePairs(array) {
  return array.slice(0, -1).map((value, index) => [value, array[index + 1]]).filter(pair => !coordinatesEqual(pair[0], pair[1]));
}
function createGeodesicLine(coordinates) {
  let steps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 32;
  const segments = coordinatePairs(coordinates);
  const geodesicSegments = segments.map(segment => {
    const greatCircle = new arc.GreatCircle({
      x: segment[0][0],
      y: segment[0][1]
    }, {
      x: segment[1][0],
      y: segment[1][1]
    });
    return greatCircle.Arc(steps, {
      offset: 90
    }).json();
  });

  // arc.js returns the line crossing antimeridian split into two MultiLineString segments
  // (the first going towards to antimeridian, the second going away from antimeridian, both in range -180..180 longitude)
  // fix Mapbox rendering by merging them together, adding 360 to longitudes on the right side
  let worldOffset = 0;
  const geodesicCoordinates = geodesicSegments.map(geodesicSegment => {
    if (geodesicSegment.geometry.type === geojsonTypes.MULTI_LINE_STRING) {
      const prevWorldOffset = worldOffset;
      const nextWorldOffset = worldOffset + (geodesicSegment.geometry.coordinates[0][0][0] > geodesicSegment.geometry.coordinates[1][0][0] ? 1 : -1);
      const geodesicCoordinates = [...geodesicSegment.geometry.coordinates[0].map(x => [x[0] + prevWorldOffset * 360, x[1]]), ...geodesicSegment.geometry.coordinates[1].map(x => [x[0] + nextWorldOffset * 360, x[1]])];
      worldOffset = nextWorldOffset;
      return geodesicCoordinates;
    } else {
      const geodesicCoordinates = geodesicSegment.geometry.coordinates.map(x => [x[0] + worldOffset * 360, x[1]]);
      return geodesicCoordinates;
    }
  }).flat();
  return geodesicCoordinates.filter((coord, index) => index === geodesicCoordinates.length - 1 || !coordinatesEqual(coord, geodesicCoordinates[index + 1]));
}

// radius used by mapbox-gl, see https://github.com/mapbox/mapbox-gl-js/blob/main/src/geo/lng_lat.js#L11
const DEFAULT_RADIUS = 6371.0088;
function distance(start, destination) {
  return distance$1(start, destination, DEFAULT_RADIUS);
}
function destinationPoint(start, distance, bearing) {
  return destinationPoint$1(start, distance, bearing, DEFAULT_RADIUS);
}
function createGeodesicCircle(center, radius, bearing, steps) {
  const coordinates = [];
  for (let i = 0; i < steps; ++i) {
    coordinates.push(destinationPoint(center, radius, bearing + 360 * -i / steps));
  }
  coordinates.push(coordinates[0]);
  return coordinates;
}
const STEPS = 32;
const HANDLE_BEARING = 45;
function isCircleFeature(feature) {
  return isCircleByTypeAndProperties(feature.type, feature.properties);
}

// returns path with the last coord id subtracted by 1
function getMidpointStartCoordPath(path) {
  return path.split('.').map((x, i, array) => i === array.length - 1 ? (parseInt(x, 10) - 1).toString() : x).join('.');
}

// returns path with the last coord id of a polygon overridden to 0
// see https://github.com/mapbox/mapbox-gl-draw/pull/998
function getMidpointEndCoordPath(feature, path) {
  if (feature.type === geojsonTypes.POLYGON || feature.type === geojsonTypes.MULTI_POLYGON) {
    try {
      feature.getCoordinate(path);
      return path;
    } catch (e) {
      return path.split('.').map((x, i, array) => i === array.length - 1 ? '0' : x).join('.');
    }
  } else {
    return path;
  }
}
function createGeodesicGeojson(geojson, options) {
  options = {
    steps: STEPS,
    ...options
  };
  const properties$1 = geojson.properties;
  const type = geojson.geometry.type;
  const coordinates = geojson.geometry.coordinates;
  const featureId = properties$1.parent || properties$1.id;
  const feature = options.ctx.store.get(featureId);
  if (type === geojsonTypes.POINT) {
    if (isCircleFeature(feature)) {
      return []; // hide circle points, they are displayed in processCircle instead
    } else if (properties$1.meta === meta.MIDPOINT) {
      return processMidpoint(); // calculate geodesic midpoint
    } else {
      return [geojson]; // pass point as is
    }
  } else if (type === geojsonTypes.LINE_STRING) {
    return processLine(); // calculate geodesic line
  } else if (type === geojsonTypes.POLYGON) {
    if (isCircleFeature(feature)) {
      return processCircle(); // calculate geodesic circle
    } else {
      return processPolygon(); // calculate geodesic polygon
    }
  } else /* istanbul ignore else */if (type.indexOf(geojsonTypes.MULTI_PREFIX) === 0) {
      return processMultiGeometry();
    }
  function isSelectedPath(path) {
    if (!options.selectedPaths) {
      return false;
    }
    return options.selectedPaths.indexOf(path) !== -1;
  }
  function processMidpoint() {
    const coordPath = properties$1.coord_path;
    const startCoordPath = getMidpointStartCoordPath(coordPath);
    const endCoordPath = getMidpointEndCoordPath(feature, coordPath);
    const startCoord = feature.getCoordinate(startCoordPath);
    const endCoord = feature.getCoordinate(endCoordPath);
    const midCoord = midpoint(startCoord, endCoord);
    const geodesicGeojson = {
      ...geojson,
      properties: {
        ...properties$1,
        lng: midCoord[0],
        lat: midCoord[1]
      },
      geometry: {
        ...geojson.geometry,
        coordinates: midCoord
      }
    };
    return [geodesicGeojson];
  }
  function processLine() {
    const geodesicCoordinates = createGeodesicLine(coordinates, options.steps);
    const geodesicGeojson = {
      ...geojson,
      geometry: {
        ...geojson.geometry,
        coordinates: geodesicCoordinates
      }
    };
    return [geodesicGeojson];
  }
  function processPolygon() {
    const geodesicCoordinates = coordinates.map(subCoordinates => {
      return createGeodesicLine(subCoordinates);
    });
    const geodesicGeojson = {
      ...geojson,
      geometry: {
        ...geojson.geometry,
        coordinates: geodesicCoordinates
      }
    };
    return [geodesicGeojson];
  }
  function processCircle() {
    const featureGeojson = feature.toGeoJSON();
    const center = getCircleCenter(featureGeojson);
    const radius = getCircleRadius(featureGeojson);
    const handleBearing = feature[properties.CIRCLE_HANDLE_BEARING] || HANDLE_BEARING;
    const geodesicCoordinates = createGeodesicCircle(center, radius, handleBearing, options.steps * 4);
    const geodesicGeojson = {
      ...geojson,
      geometry: {
        ...geojson.geometry,
        coordinates: [geodesicCoordinates]
      }
    };

    // circle handles
    if (properties$1.active === activeStates.ACTIVE) {
      const handle = destinationPoint(center, radius, handleBearing);
      const points = [center, handle];
      const vertices = points.map((point, i) => {
        return createVertex(properties$1.id, point, `0.${i}`, isSelectedPath(`0.${i}`));
      });
      return [geodesicGeojson, ...vertices];
    } else {
      return [geodesicGeojson];
    }
  }
  function processMultiGeometry() {
    const subType = type.replace(geojsonTypes.MULTI_PREFIX, '');
    const geodesicFeatures = coordinates.map(subCoordinates => {
      const subFeature = {
        type: geojsonTypes.FEATURE,
        properties: properties$1,
        geometry: {
          type: subType,
          coordinates: subCoordinates
        }
      };
      return createGeodesicGeojson(subFeature, options);
    }).flat();
    const geodesicCoordinates = geodesicFeatures.map(subFeature => {
      return subFeature.geometry.coordinates;
    });
    const geodesicGeojson = {
      ...geojson,
      geometry: {
        ...geojson.geometry,
        coordinates: geodesicCoordinates
      }
    };
    return [geodesicGeojson];
  }
}
function patchDrawLineString(DrawLineString) {
  const DrawLineStringPatched = {
    ...DrawLineString
  };
  DrawLineStringPatched.toDisplayFeatures = function (state, geojson, display) {
    const displayGeodesic = geojson => {
      const geodesicGeojson = createGeodesicGeojson(geojson, {
        ctx: this._ctx
      });
      geodesicGeojson.forEach(display);
    };
    DrawLineString.toDisplayFeatures.call(this, state, geojson, displayGeodesic);
  };
  return DrawLineStringPatched;
}
function patchDrawPolygon(DrawPolygon) {
  const DrawPolygonPatched = {
    ...DrawPolygon
  };
  DrawPolygonPatched.toDisplayFeatures = function (state, geojson, display) {
    const displayGeodesic = geojson => {
      const geodesicGeojson = createGeodesicGeojson(geojson, {
        ctx: this._ctx
      });
      geodesicGeojson.forEach(display);
    };
    DrawPolygon.toDisplayFeatures.call(this, state, geojson, displayGeodesic);
  };
  return DrawPolygonPatched;
}
function isEscapeKey(e) {
  return e.keyCode === 27;
}
function isEnterKey(e) {
  return e.keyCode === 13;
}
var doubleClickZoom = {
  enable(ctx) {
    setTimeout(() => {
      // First check we've got a map and some context.
      if (!ctx.map || !ctx.map.doubleClickZoom || !ctx._ctx || !ctx._ctx.store || !ctx._ctx.store.getInitialConfigValue) return;
      // Now check initial state wasn't false (we leave it disabled if so)
      if (!ctx._ctx.store.getInitialConfigValue('doubleClickZoom')) return;
      ctx.map.doubleClickZoom.enable();
    }, 0);
  },
  disable(ctx) {
    setTimeout(() => {
      if (!ctx.map || !ctx.map.doubleClickZoom) return;
      // Always disable here, as it's necessary in some cases.
      ctx.map.doubleClickZoom.disable();
    }, 0);
  }
};
const dragPan = {
  enable(ctx) {
    setTimeout(() => {
      // First check we've got a map and some context.
      if (!ctx.map || !ctx.map.dragPan || !ctx._ctx || !ctx._ctx.store || !ctx._ctx.store.getInitialConfigValue) return;
      // Now check initial state wasn't false (we leave it disabled if so)
      if (!ctx._ctx.store.getInitialConfigValue('dragPan')) return;
      ctx.map.dragPan.enable();
    }, 0);
  },
  disable(ctx) {
    setTimeout(() => {
      if (!ctx.map || !ctx.map.doubleClickZoom) return;
      // Always disable here, as it's necessary in some cases.
      ctx.map.dragPan.disable();
    }, 0);
  }
};
const DrawCircleGeodesic = {};
DrawCircleGeodesic.onSetup = function (opts) {
  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);
  dragPan.disable(this);
  this.updateUIClasses({
    mouse: cursors.ADD
  });
  this.setActionableState(); // default actionable state is false for all actions
  return {};
};
DrawCircleGeodesic.onMouseDown = DrawCircleGeodesic.onTouchStart = function (state, e) {
  const center = [e.lngLat.lng, e.lngLat.lat];
  const circle = this.newFeature(createCircle(center, Number.EPSILON));
  this.addFeature(circle);
  state.circle = circle;
};
DrawCircleGeodesic.onDrag = DrawCircleGeodesic.onTouchMove = function (state, e) {
  if (state.circle) {
    const geojson = state.circle.toGeoJSON();
    const center = getCircleCenter(geojson);
    const handle = [e.lngLat.lng, e.lngLat.lat];
    const radius = distance(center, handle);
    const handleBearing = initialBearing(center, handle);
    state.circle.properties[properties.CIRCLE_RADIUS] = radius;
    state.circle[properties.CIRCLE_HANDLE_BEARING] = handleBearing;
    state.circle.changed();
  }
};
DrawCircleGeodesic.onMouseUp = DrawCircleGeodesic.onTouchEnd = function (state, e) {
  this.map.fire(events.CREATE, {
    features: [state.circle.toGeoJSON()]
  });
  return this.changeMode(modes.SIMPLE_SELECT, {
    featureIds: [state.circle.id]
  });
};
DrawCircleGeodesic.onKeyUp = function (state, e) {
  if (isEscapeKey(e)) {
    if (state.circle) {
      this.deleteFeature([state.circle.id], {
        silent: true
      });
    }
    this.changeMode(modes.SIMPLE_SELECT);
  } else if (isEnterKey(e)) {
    this.changeMode(modes.SIMPLE_SELECT, {
      featureIds: [state.circle.id]
    });
  }
};
DrawCircleGeodesic.onStop = function () {
  this.updateUIClasses({
    mouse: cursors.NONE
  });
  doubleClickZoom.enable(this);
  dragPan.enable(this);
  this.activateUIButton();
};
DrawCircleGeodesic.toDisplayFeatures = function (state, geojson, display) {
  if (state.circle) {
    const isActivePolygon = geojson.properties.id === state.circle.id;
    geojson.properties.active = isActivePolygon ? activeStates.ACTIVE : activeStates.INACTIVE;
  }
  const displayGeodesic = geojson => {
    const geodesicGeojson = createGeodesicGeojson(geojson, {
      ctx: this._ctx
    });
    geodesicGeojson.forEach(display);
  };
  displayGeodesic(geojson);
};
function patchDrawPoint(DrawPoint) {
  const DrawPointPatched = {
    ...DrawPoint
  };
  DrawPointPatched.toDisplayFeatures = function (state, geojson, display) {
    const displayGeodesic = geojson => {
      const geodesicGeojson = createGeodesicGeojson(geojson, {
        ctx: this._ctx
      });
      geodesicGeojson.forEach(display);
    };
    DrawPoint.toDisplayFeatures.call(this, state, geojson, displayGeodesic);
  };
  return DrawPointPatched;
}
function patchSimpleSelect(SimpleSelect) {
  const SimpleSelectPatched = {
    ...SimpleSelect
  };
  SimpleSelectPatched.toDisplayFeatures = function (state, geojson, display) {
    const displayGeodesic = geojson => {
      const geodesicGeojson = createGeodesicGeojson(geojson, {
        ctx: this._ctx
      });
      geodesicGeojson.forEach(display);
    };
    SimpleSelect.toDisplayFeatures.call(this, state, geojson, displayGeodesic);
  };
  return SimpleSelectPatched;
}
function patchDirectSelect(DirectSelect) {
  const DirectSelectPatched = {
    ...DirectSelect
  };
  DirectSelectPatched.dragVertex = function (state, e, delta) {
    const geojson = state.feature.toGeoJSON();
    if (isCircle(geojson)) {
      if (state.selectedCoordPaths[0] === '0.1') {
        const center = getCircleCenter(geojson);
        const handle = [e.lngLat.lng, e.lngLat.lat];
        const radius = distance(center, handle);
        const handleBearing = initialBearing(center, handle);
        state.feature.properties[properties.CIRCLE_RADIUS] = radius;
        state.feature[properties.CIRCLE_HANDLE_BEARING] = handleBearing;
        state.feature.changed();
      } else {
        DirectSelect.dragFeature.call(this, state, e, delta);
      }
    } else {
      DirectSelect.dragVertex.call(this, state, e, delta);
    }
  };
  DirectSelectPatched.toDisplayFeatures = function (state, geojson, display) {
    const displayGeodesic = geojson => {
      const geodesicGeojson = createGeodesicGeojson(geojson, {
        ctx: this._ctx,
        selectedPaths: state.selectedCoordPaths
      });
      geodesicGeojson.forEach(display);
    };
    DirectSelect.toDisplayFeatures.call(this, state, geojson, displayGeodesic);
  };
  return DirectSelectPatched;
}

// copied from https://github.com/mapbox/mapbox-gl-draw-static-mode
const StaticGeodesic = {};
StaticGeodesic.onSetup = function () {
  this.setActionableState(); // default actionable state is false for all actions
  return {};
};
StaticGeodesic.toDisplayFeatures = function (state, geojson, display) {
  const displayGeodesic = geojson => {
    const geodesicGeojson = createGeodesicGeojson(geojson, {
      ctx: this._ctx
    });
    geodesicGeojson.forEach(display);
  };
  displayGeodesic(geojson);
};
function enable(modes$1) {
  return {
    ...modes$1,
    [modes.DRAW_LINE_STRING]: patchDrawLineString(modes$1[modes.DRAW_LINE_STRING]),
    [modes.DRAW_POLYGON]: patchDrawPolygon(modes$1[modes.DRAW_POLYGON]),
    [modes.DRAW_CIRCLE]: DrawCircleGeodesic,
    [modes.DRAW_POINT]: patchDrawPoint(modes$1[modes.DRAW_POINT]),
    [modes.SIMPLE_SELECT]: patchSimpleSelect(modes$1[modes.SIMPLE_SELECT]),
    [modes.DIRECT_SELECT]: patchDirectSelect(modes$1[modes.DIRECT_SELECT]),
    [modes.STATIC]: StaticGeodesic
  };
}
export { createCircle, enable, getCircleCenter, getCircleRadius, isCircle, isCircleByTypeAndProperties, setCircleCenter, setCircleRadius };
