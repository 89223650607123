import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Actions} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {geoActions} from "../../core/ngrx/actions";
import {GeoSelector} from "../../core/ngrx/selectors/geo.selector";
import {filter, Observable, take, tap} from "rxjs";
import {GeoInfo} from "../../core/model/geo-info";
import {AutoCompleteSelectEvent} from "primeng/autocomplete";

@Component({
    selector: 'app-geo-filter',
    templateUrl: './geo-filter.component.html',
    styleUrls: ['./geo-filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class GeoFilterComponent implements OnInit {

  selectedCity: any;
  countries: Observable<GeoInfo[]> = this.geoSelector.getCountries();
  states: Observable<GeoInfo[]> = this.geoSelector.getStates();
  cities: Observable<GeoInfo[]> = this.geoSelector.getCities();
  selectedGeoInfo: Observable<GeoInfo[]> = this.geoSelector.getSelectedGeo();
  geoInfo: Observable<GeoInfo[]> = this.geoSelector.getGeoInfo();
  searchBy: string = 'byName';
  lat: number;
  lon: number;

  constructor(private readonly store: Store, private actions$: Actions, private geoSelector: GeoSelector) {

  }

  ngOnInit(): void {
    this.geoSelector.getLocation().pipe(
      tap(location => this.onLocationSuccess(location))
    ).subscribe();
  }

  onLocationSuccess(location: GeoInfo) {
    let selectedGeoInfo: GeoInfo = {
      ...location,
      zoom: 12
    }

    this.store.dispatch(geoActions.centerMap({info: selectedGeoInfo}));
  }

  countryChanged(event) {
    this.store.dispatch(geoActions.countrySelected({country: event.value}));
  }

  stateChanged(event) {
    this.store.dispatch(geoActions.stateSelected({state: event.value}));
  }

  cityChanged(event) {
    this.store.dispatch(geoActions.citySelected({city: event.value}));
  }

  queryGeo(event) {
    this.store.dispatch(geoActions.queryGeo({query: event.query}));
  }

  getGeoTypeDisplay(geoType: string): string {
    return "state"
  }

  onGeoInfoSelected(geoInfo: AutoCompleteSelectEvent) {
    this.store.dispatch(geoActions.getLocation({id: geoInfo.value.id}));
  }


  centerMap() {
    this.geoSelector.getSelectedGeo().pipe(
      take(1),
      tap(v => {
        let selectedGeoInfo = v[v.length - 1];
        selectedGeoInfo.zoom = 12;
        this.store.dispatch(geoActions.centerMap({info: selectedGeoInfo}));
      })
    ).subscribe();
  }

  goToLocation() {
    let geoInfo: GeoInfo = {
      desc: "", id: "",
      latitude: this.lat, longitude: this.lon, name: ""

    }
    this.store.dispatch(geoActions.centerMap({info: geoInfo}));
  }

  get isLocationValid() {
    if (this.lat != null && this.lon != null) {
      return this.lat >= -90 && this.lat <= 90 && this.lon >= -180 && this.lon <= 180;
    }
    return false;
  }

}
