<div class="polygon-popup-container">
  <div class="popup-data-container">
    <ng-container
      [ngTemplateOutlet]="isModifying ? modifying : readOnly">
    </ng-container>
    <ng-template #readOnly>
      <div class="row">
        <div class="col-12 name-container">
          <div class="data-value">{{polygon.label}}</div>
          <p-button *ngIf="!isReadOnly" styleClass="p-button-primary secondary p-button-sm" icon="pi pi-pencil"
                    (click)="toggleModifying();"></p-button>
        </div>
      </div>
      <!-- <span class="popup-title">{{areaName}}</span> -->
      <div class="subtitle">Total Capacity (MW):</div>
      <div class="row">
        <div class="col-12 totals-container">
          <div class="data-label">
            Live
          </div>
          <div class="data-value">
            {{totalMW}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 totals-container">
          <div class="data-label">
            U/C
          </div>
          <div class="data-value">
            {{totalUC}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 totals-container">
          <div class="data-label">
            Planned
          </div>
          <div class="data-value">
            {{totalPlanned}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 totals-container">
          <div class="data-label">
            Total
          </div>
          <div class="data-value">
            {{total}}
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #modifying>
      <div class="row">
        <div class="col-12 name-container">
          <div class="data-value">
            <input class="form-control popup-input" type="text" pInputText
                   [(ngModel)]="polygon.label" name="polygonLabel"/>
          </div>
        </div>
      </div>
      <div class="datacenter-check-row">
        <input (change)="toggleDataCentersVisibility()" [(ngModel)]="polygon.hideDatacenters" type="checkbox"
               name="hideDatacenters" id="hideDatacenters"/>
        <label for="hideDatacenters">hide datacenters</label>
      </div>
      <div class="row" style="margin-top: 20px;">
        <p-colorPicker [(ngModel)]="polygon.color" [inline]="true" [appendTo]="'body'"></p-colorPicker>
      </div>
      <div class="row opacity-slider">
        <label>Opacity: {{polygon.opacity}}</label>
        <p-slider [(ngModel)]="polygon.opacity" [min]="0.1" [max]="1" [step]="0.1"></p-slider>
      </div>
      <div class="row" style="margin-top: 30px;text-align: center;">
        <div class="polygon-popup-data">
          <p-button (onClick)="save()" styleClass="primary"
                    icon="pi pi-check"></p-button>
          <p-button (onClick)="close()" styleClass="secondary"
                    icon="pi pi-times-circle"></p-button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
